import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import HeaderRight from '../components/HeaderRight';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { listStyle, paperStyle } from '../theme/styles';
import { cryptoPrimary, cryptoSecondary } from '../theme/colors';
import { BluePlus } from '../components/icons/blueplus';
import api from '../api/api';
import { Asset } from '../models/paymentData';
import { Address } from '../models/address';
import { UserContext } from '../context/User';
import { useNavigate } from 'react-router-dom';
import AddressItem from '../components/AddressItem';
import { EventMessage } from '../models/eventMessage';
import EventDialog from '../components/EventDialog';

interface Props {
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  walletId: string;
  invoice: Asset;
}

const itemStyle = {
  width: '100%',
  height: '100%',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: cryptoPrimary[50],
  color: 'black'
} as const;

export default function ChooseAddress({
  setActive,
  setUpdated,
  walletId,
  invoice
}: Props) {
  const userCtx = useContext(UserContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [address, setAddress] = useState<Address | null>(null);
  const [eventMessage, setEventMessage] = useState<EventMessage | null>(null);
  const [added, setAdded] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('blockchain', invoice.blockchain);
    api.address
      .list(walletId, params)
      .then((response) => {
        //console.log(response);
        if (response) {
          setAddresses(response.addresses);
        }
      })
      .catch((er) => {
        //console.log(er);
        if (er.response.status === 401) {
          userCtx.setUser(null);
          navigate('../../login');
        }
      });
  }, []);

  useEffect(() => {
    if (address) {
      api.address
        .createForAddress(walletId, address.address, {
          contract_address: invoice.contract_address
        })
        .then((response) => {
          //console.log(response);
          if (response.data) {
            userCtx.setUpdated(false);
            setEventMessage({
              status: response.status,
              message: language.translation.windowText.assetAdded,
              positive: true
            });
            setAdded(true);
            setUpdated(false);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          } else if (er.response.data) {
            setEventMessage({
              status: er.response.status,
              message: er.response.data.message
                ? er.response.data.message
                : er.message,
              positive: false
            });
          } else {
            setEventMessage({
              status: er.response.status ? er.response.status : 0,
              message: er.message,
              positive: false
            });
          }
        });
    }
  }, [address]);

  useEffect(() => {
    if (added && !eventMessage) {
      setActive(false);
    }
  }, [added, eventMessage]);

  const handleAddAddressClick = () => {
    api.address
      .createWithContract(walletId, {
        blockchain: invoice.blockchain,
        standard: invoice.hdPathStandards[0],
        contract_address: invoice.contract_address
      })
      .then((response) => {
        //console.log(response);
        if (response.data) {
          userCtx.setUpdated(false);
          setEventMessage({
            status: response.status,
            message: language.translation.windowText.assetAdded,
            positive: true
          });
          setAdded(true);
          setUpdated(false);
        }
      })
      .catch((er) => {
        //console.log(er);
        if (er.response.status === 401) {
          userCtx.setUser(null);
          navigate('../../login');
        } else if (er.response.data) {
          setEventMessage({
            status: er.response.status,
            message: er.response.data.message
              ? er.response.data.message
              : er.message,
            positive: false
          });
        } else {
          setEventMessage({
            status: er.response.status ? er.response.status : 0,
            message: er.message,
            positive: false
          });
        }
      });
  };

  return (
    <Paper
      elevation={0}
      square
      sx={[paperStyle, { marginLeft: { xs: 'auto', sm: 0 }, height: '100%' }]}
    >
      <HeaderRight
        title={language.translation.topWindow.chooseAddress}
        setActive={setActive}
      />
      <Box
        component="div"
        sx={[
          listStyle,
          { backgroundColor: cryptoSecondary[50], height: '100%' }
        ]}
      >
        <Box
          component={'div'}
          sx={{
            height: '60px',
            minHeight: '60px',
            padding: '1px',
            paddingTop: 0,
            backgroundColor: cryptoSecondary[50]
          }}
        >
          <Stack direction={'row'} sx={itemStyle}>
            <Typography sx={{ minWidth: '100px' }}>
              {language.translation.buttons.addAddress}
            </Typography>
            <Tooltip title={language.translation.buttons.addAddress}>
              <IconButton
                disableRipple
                onClick={handleAddAddressClick}
                sx={{ p: '15px', borderRadius: 0 }}
              >
                <BluePlus sx={{ width: '18px', height: '18px' }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
        {addresses.map((a) => (
          <AddressItem key={a.address} address={a} setAddress={setAddress} />
        ))}
      </Box>
      {eventMessage && (
        <EventDialog
          isOpen={Boolean(eventMessage)}
          setOpen={setEventMessage}
          positive={eventMessage.positive}
          message={eventMessage.message}
        />
      )}
    </Paper>
  );
}
