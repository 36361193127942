import { AppBar, Box, IconButton, Stack, Typography } from '@mui/material';
import lockIcon from '../assets/lock.svg';
import lockDisabledIcon from '../assets/lock_disabled.svg';
import invoicesIcon from '../assets/invoices.svg';
import invoicesDisabledIcon from '../assets/invoices_disabled.svg';
import settingsIcon from '../assets/settings.svg';
import settingsDisabledIcon from '../assets/settings_disabled.svg';
import { useContext } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../context/User';

interface Props {
  invoices?: boolean;
  invoicesDisabled?: boolean;
  wallet?: boolean;
  settingsDisabled?: boolean;
}

const footerStyle = {
  top: 'auto',
  bottom: 0,
  flexDirection: 'row',
  justifyContent: 'space-around',
  background: 'linear-gradient(332deg, #14386E 17.24%, #329DFF 109.2%)',
  width: '100%',
  minWidth: '100px',
  height: '60px'
} as const;

const footerButtonStyle = {
  px: '1rem',
  cursor: 'pointer',
  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
} as const;

const footerIconStyle = {
  p: '8px',
  pt: '10px',
  borderRadius: 0,
  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0)' }
} as const;

const textStyle = {
  fontSize: '0.875rem',
  lineHeight: '1rem',
  userSelect: 'none'
} as const;

export default function Footer({
  invoices,
  invoicesDisabled,
  wallet,
  settingsDisabled
}: Props) {
  const userCtx = useContext(UserContext);
  const user = userCtx.user;
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  let navigate = useNavigate();

  const { id } = useParams();

  const handleWalletClick = () => {
    if (user?.defaultWalletId) {
      navigate(`../wallet/${user.defaultWalletId}`);
    }
  };

  const handleInvoiceClick = () => {
    if (id) {
      navigate(`../invoices/${id}`);
    } else {
      const defaultId = userCtx.user?.defaultWalletId;
      if (defaultId) {
        navigate(`../invoices/${defaultId}`);
      }
    }
  };

  const handleSettingsClick = () => {
    navigate('../settings');
  };

  return (
    <>
      <Box component={'div'} sx={{ height: '60px', minHeight: '60px' }} />
      <AppBar position="absolute" sx={footerStyle}>
        <Stack
          onClick={handleWalletClick}
          sx={[
            footerButtonStyle,
            wallet
              ? {}
              : { cursor: 'inherit', '&:hover': { backgroundColor: 'inherit' } }
          ]}
        >
          <IconButton
            disabled={!wallet}
            disableRipple
            sx={[footerIconStyle, wallet ? {} : { cursor: 'inherit' }]}
          >
            <img
              style={{
                height: '22px'
                //filter: 'drop-shadow(0px 1px)'
              }}
              src={wallet ? lockIcon : lockDisabledIcon}
              alt={language.translation.footerMenu.wallet}
            />
          </IconButton>
          <Typography sx={textStyle}>
            {language.translation.footerMenu.wallet}
          </Typography>
        </Stack>
        {invoices && (
          <Stack
            onClick={invoicesDisabled ? () => {} : handleInvoiceClick}
            sx={[
              footerButtonStyle,
              invoicesDisabled
                ? {
                    cursor: 'inherit',
                    '&:hover': { backgroundColor: 'inherit' }
                  }
                : {}
            ]}
          >
            <IconButton
              disableRipple
              sx={[
                footerIconStyle,
                invoicesDisabled ? { cursor: 'inherit' } : {}
              ]}
            >
              <img
                style={{
                  height: 22
                  //filter: 'drop-shadow(0px 1px)'
                }}
                src={invoicesDisabled ? invoicesDisabledIcon : invoicesIcon}
                alt={language.translation.footerMenu.invoices}
              />
            </IconButton>
            <Typography sx={textStyle}>
              {language.translation.footerMenu.invoices}
            </Typography>
          </Stack>
        )}
        <Stack
          sx={[
            footerButtonStyle,
            settingsDisabled
              ? {
                  cursor: 'inherit',
                  '&:hover': { backgroundColor: 'inherit' }
                }
              : {}
          ]}
          onClick={settingsDisabled ? () => {} : handleSettingsClick}
        >
          <IconButton
            disableRipple
            sx={[
              footerIconStyle,
              settingsDisabled ? { cursor: 'inherit' } : {}
            ]}
          >
            <img
              style={{
                height: 22
                //filter: 'drop-shadow(0px 1px)'
              }}
              src={settingsDisabled ? settingsDisabledIcon : settingsIcon}
              alt={language.translation.footerMenu.settings}
            />
          </IconButton>
          <Typography sx={textStyle}>
            {language.translation.footerMenu.settings}
          </Typography>
        </Stack>
      </AppBar>
    </>
  );
}
