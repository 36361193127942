import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  errorStyle,
  listStyle,
  paperStyle,
  positiveButtonStyle
} from '../theme/styles';
import HeaderRight from '../components/HeaderRight';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import api from '../api/api';
import { Asset } from '../models/paymentData';
import { UserContext } from '../context/User';
import { BlueSelect } from '../components/icons/blueselect';
import {
  cryptoAlpha,
  cryptoGrey,
  cryptoPositive,
  cryptoPrimary,
  cryptoSecondary
} from '../theme/colors';
import { useNavigate } from 'react-router-dom';
import AddressText from '../components/AddressText';
import qrapi from '../api/qrapi';
import { DeleteMessage } from '../models/deleteMessage';
import DeleteDialog from '../components/DeleteDialog';
import EventDialog from '../components/EventDialog';
import { EventMessage } from '../models/eventMessage';
import { SelectTarifResponse, Tarif } from '../models/tarif';
import RedirectDialog from '../components/RedirectDialog';

interface Props {
  setActive: () => void;
}

const labelTextStyle = {
  color: 'black',
  fontWeight: 500,
  lineHeight: '1.25rem',
  pr: '1rem',
  whiteSpace: 'nowrap'
} as const;

const valueTextStyle = {
  color: cryptoPrimary[900],
  lineHeight: '1.25rem'
} as const;

const PayoutInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  height: '40px',
  backgroundColor: cryptoSecondary[50],
  boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.25)',
  border: 'none',
  '& .MuiFilledInput-input': {
    width: '100%',
    color: 'black'
  },
  '&:after, &:before': {
    display: 'none'
  },
  '&:hover, & .Mui-focused': {
    backgroundColor: cryptoGrey[100]
  }
}));

const menuItemStyle = {
  height: '30px',
  px: '10px',
  py: 0,
  backgroundColor: cryptoPrimary[50],
  justifyContent: 'space-between'
} as const;

const SelectInputStyle = {
  width: '100%',
  backgroundColor: cryptoSecondary[50],
  border: 'none',
  borderRadius: '10px',
  height: '40px',
  boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.25)',
  '&:after, &:before': {
    display: 'none'
  },
  '&:hover, & .Mui-focused': {
    backgroundColor: cryptoGrey[100]
  },
  '& .MuiFilledInput-input': {
    py: 0,
    width: '100%',
    color: 'black',
    fontSize: '1rem',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  '& .MuiIconButton-root': {
    borderRadius: 0
  }
} as const;

const validationSchema = yup.object({
  amount: yup.number().nonNullable().required('Enter a valid amount')
});

export default function CreateInvoice({ setActive }: Props) {
  const userCtx = useContext(UserContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [symbol, setSymbol] = useState('');
  const [asset, setAsset] = useState<Asset | null>(null);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [tarif, setTarif] = useState<Tarif | null>(null);
  const [orderId, setOrderId] = useState<string>('');
  const [credits, setCredits] = useState<string>('');
  const [tariffs, setTariffs] = useState<Tarif[]>([]);
  const [invoice, setInvoice] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState<number | null>(null);
  const [time, setTime] = useState<number>(0);
  const [qrcode, setQrcode] = useState<string>('');
  const [address, setAddress] = useState<string>(
    '0X2f347482325fkwfdsdfdsadasdsdfsfsdfsdffsdsffde4'
  );
  const [confirm, setConfirm] = useState<DeleteMessage | null>(null);
  const [eventMessage, setEventMessage] = useState<EventMessage | null>(null);
  const [redirect, setRedirect] = useState<string | null>(null);
  let navigate = useNavigate();

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  useEffect(() => {
    api.general
      .paymentData()
      .then((response) => {
        //console.log(response);
        if (response?.walletList) {
          setAssets(response.walletList);
        }
      })
      .catch((er) => {
        //console.log(er);
        if (er.response.status === 401) {
          userCtx.setUser(null);
          navigate('../login');
        }
      });
  }, []);

  useEffect(() => {
    api.general
      .tariffs()
      .then((response) => {
        //console.log(response);
        if (response) {
          setTariffs(response);
        }
      })
      .catch((er) => {
        //console.log(er);
        if (er.response.status === 401) {
          userCtx.setUser(null);
          navigate('../login');
        }
      });
  }, []);

  useEffect(() => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    } else {
      setInvoice(false);
    }
  }, [time]);

  const formik = useFormik({
    initialValues: {
      amount: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { amount } = values;
      console.log(amount);
      setEnteredAmount(parseFloat(amount));
      if (asset) {
        setInvoice(true);
        setTime(1800);
        const payString = `${asset.blockchain}:address?amount=${amount}`;
        const params = new URLSearchParams();
        params.append('cht', 'qr');
        params.append('chs', '250x250');
        params.append('chl', payString);
        qrapi.generate(params).then((url) => {
          if (url) {
            setQrcode(url);
          }
        });
      }
    }
  });

  const handleCreditsChange = (event: SelectChangeEvent) => {
    setCredits(event.target.value);
    const newTarif = tariffs.find((t) => t.credits.toString() === event.target.value);
    if (newTarif) {
      setTarif(newTarif);
    } else {
      setTarif(null);
    }
  };

  const handleAssetChange = (event: SelectChangeEvent) => {
    setSymbol(event.target.value);
    const newAsset = assets.find((a) => a.symbol === event.target.value);
    if (newAsset) {
      setAsset(newAsset);
    } else {
      setAsset(null);
    }
  };

  const handleCreateInvoiceClick = () => {
    if (tarif && asset) {
      const request = {
        blockchain: asset.blockchain,
        symbol: asset.symbol
      };
    api.account
      .selectTarif(tarif.id, request)
      .then((response) => {
        //console.log(response);
        if (response.data) {
          const selectTarifResponse = response.data as SelectTarifResponse;
          setOrderId(selectTarifResponse.id);
          setRedirect(language.translation.historyWindow.toOrder);
        }
      })
      .catch((er) => {
        console.log(er);
        if (er.response.status === 401) {
          userCtx.setUser(null);
          navigate('../login');
        }else if (er.response.data) {
          setEventMessage({
            status: er.response.status,
            message: er.response.data.message
              ? er.response.data.message 
              : er.response.data !== '' ? er.response.data : er.message,
            positive: false
          });
        } else {
          setEventMessage({
            status: er.response.status ? er.response.status : 0,
            message: er.message,
            positive: false
          });
        }
      });}
  };

  const handleRedirect = () => {
    if (orderId) {
      window.open(
        `https://order.apiforcrypto.com/?id=${orderId}`,
        '_blank',
        'noreferrer'
      );
    }
  };

  const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseFloat(event.target.value);
    if (!Number.isNaN(val)) {
      formik.handleChange(event);
    } else if (event.target.value === '') {
      formik.handleChange(event);
    }
  };

  const handleCancelClick = () => {
    setConfirm({
      title: language.translation.cancelInvoice.cancel,
      text: language.translation.cancelInvoice.confirm,
      button: language.translation.mainButton.cancel
    });
  };

  const handleCancelInvoice = () => {
    setInvoice(false);
    setConfirm(null);
  };

  const handleShareClick = async () => {
    if (asset) {
      const shareData = {
        title: 'My Address',
        text: `my address for receiving funds: ${address}\n Blockchain: ${asset.blockchain}\n Currency: ${asset.symbol}`
      };

      // Test compatibility
      if (navigator.share === undefined) {
        console.log('Unsupported share feature');
        return;
      }

      try {
        await navigator
          .share(shareData)
          .then(() => console.log('Addreess shared successfully'));
      } catch (error) {
        console.log(`Error sharing: ${error}`);
      }
    }
  };

  const handlePayClick = () => {
    if (asset && enteredAmount) {
      const payString = `${asset.blockchain}:address?amount=${enteredAmount}`;
      setEventMessage({
        status: 0,
        message: language.translation.createWallet.selectAccount,
        positive: true,
        payMessage: {
          payString: payString,
          dataString: language.translation.createWallet.dataIncludes
        }
      });
    }
  };

  return (
    <Paper
      elevation={0}
      square
      sx={[
        paperStyle,
        { marginLeft: { xs: 'auto', sm: 0 }, minWidth: '300px' }
      ]}
    >
      <HeaderRight
        title={language.translation.topWindow.createInvoice}
        setActive={
          invoice
            ? () => {
                setInvoice(false);
              }
            : setActive
        }
      />

      {/* Part 1 begin: */}
      {!invoice && (
        <Box
          component={'form'}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          sx={[listStyle, { padding: '30px' }]}
        >
          <Stack direction={'row'} alignItems={'center'} sx={{ pb: '30px' }}>
            <Typography sx={labelTextStyle}>
              {language.translation.bill.buyCredits}
            </Typography>
           {/*  <PayoutInput
              sx={{
                '& .MuiFilledInput-input': {
                  fontSize: '1rem'
                }
              }}
              autoFocus
              fullWidth
              variant="filled"
              id="amount"
              name="amount"
              placeholder={language.translation.transferWindow.amount}
              value={formik.values.amount}
              onChange={handleChangeAmount}
              onBlur={formik.handleBlur}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              InputProps={{
                disableUnderline: true
              }}
            /> */}
            <Select
              id="credits-select-filled"
              value={credits}
              onChange={handleCreditsChange}
              variant="filled"
              IconComponent={BlueSelect}
              sx={SelectInputStyle}
              MenuProps={{
                sx: {
                  '& .MuiMenu-paper': {
                    backgroundColor: cryptoSecondary[50]
                  },
                  '& .MuiMenu-list': {
                    display: 'flex',
                    flexDirection: 'column',
                  }
                }
              }}
            >
              {tariffs.map((t) => (
                <MenuItem
                  key={t.id}
                  id={t.id}
                  selected={t.credits.toString() === credits}
                  value={t.credits.toString()}
                  sx={menuItemStyle}
                >
                  <Stack direction={'row'}>
                  <Typography component={'h5'} sx={{minWidth: '106px'}}>{t.credits}</Typography>
                  <Typography component={'h5'} sx={{color: cryptoAlpha[600]}}>{USDollar.format(t.value)}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography sx={labelTextStyle}>
              {language.translation.bill.payBill}
            </Typography>
            <Select
              id="asset-select-filled"
              value={symbol}
              onChange={handleAssetChange}
              variant="filled"
              IconComponent={BlueSelect}
              sx={SelectInputStyle}
              MenuProps={{
                sx: {
                  '& .MuiMenu-paper': {
                    backgroundColor: cryptoSecondary[50]
                  },
                  '& .MuiMenu-list': {
                    p: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px'
                  }
                }
              }}
            >
              {assets.map((a) => (
                <MenuItem
                  key={a.symbol}
                  id={a.symbol}
                  selected={a.symbol === symbol}
                  value={a.symbol}
                  sx={menuItemStyle}
                >
                  <Typography component={'h5'}>{a.symbol}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Box component={'div'} sx={[errorStyle, { m: '6px' }]}>
            {formik.touched.amount && Boolean(formik.errors.amount) ? (
              <p style={{ margin: 0 }}>{formik.errors.amount}</p>
            ) : (
              ' '
            )}
          </Box>
          <Button
            variant="contained"
            disableRipple
            sx={[positiveButtonStyle, { width: '100%' }]}
            size="large"
            onClick={handleCreateInvoiceClick}
          >
            {language.translation.mainButton.createInvoice}
          </Button>
        </Box>
      )}
      {/* Part 1 end: */}
      {/* Part 2 begin: */}
      {invoice && (
        <Box
          component={'div'}
          sx={[listStyle, { padding: '30px', gap: '10px' }]}
        >
          <Stack direction={'row'} gap={'15px'} sx={{ width: '100%' }}>
            <Typography sx={labelTextStyle}>
              {language.translation.bill.invoice}
            </Typography>
            <Stack
              direction={'row'}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                overflow: 'hidden'
              }}
            >
              <AddressText
                address="ncwllefj43l4rfasddasasddsfdsfsdfdsfsfnm3l4fn"
                fontHeight="1rem"
                lineHeight="1.25rem"
                stringLen={340}
                color={cryptoPrimary[900]}
              />
            </Stack>
          </Stack>
          <Stack direction={'row'} gap={'15px'} sx={{ width: '100%' }}>
            <Typography sx={labelTextStyle}>
              {language.translation.bill.paymentFor}
            </Typography>
            <Typography sx={valueTextStyle}>100 Credits</Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} gap={'15px'}>
              <Typography sx={labelTextStyle}>
                {language.translation.bill.valid}
              </Typography>
              <Typography sx={[valueTextStyle, { color: cryptoPrimary[200] }]}>
                {`${Math.floor(time / 60)}`.padStart(2, '0')}:
                {`${time % 60}`.padStart(2, '0')}
              </Typography>
            </Stack>
            <Typography
              onClick={handleCancelClick}
              sx={[
                valueTextStyle,
                {
                  color: cryptoPositive[50],
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }
              ]}
            >
              Cancel Invoice
            </Typography>
          </Stack>
          <Stack direction={'row'} gap={'15px'}>
            <Typography sx={labelTextStyle}>
              {language.translation.bill.asset}
            </Typography>
            <Typography sx={labelTextStyle}>{asset?.symbol}</Typography>
            <Typography sx={[valueTextStyle, { color: cryptoGrey[50] }]}>
              {`(${asset?.name})`}
            </Typography>
          </Stack>
          <Stack direction={'row'} gap={'15px'}>
            <Typography sx={labelTextStyle}>
              {language.translation.bill.blockchain}
            </Typography>
            <Typography sx={valueTextStyle}>{asset?.blockchain}</Typography>
          </Stack>
          <Stack direction={'row'} gap={'15px'}>
            <Typography sx={labelTextStyle}>
              {language.translation.bill.amount}
            </Typography>
            <Typography sx={valueTextStyle}>{enteredAmount}</Typography>
          </Stack>
          <Typography sx={labelTextStyle}>
            {language.translation.bill.payToAddress}
          </Typography>
          <AddressText
            address="0X2f347482325fkwfdsdfdsadasdsdfsfsdfsdffsdsffde4"
            fontHeight="1rem"
            lineHeight="1.25rem"
            stringLen={320}
            color={cryptoPrimary[200]}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: '10px'
            }}
          >
            <img
              style={{
                height: 250
              }}
              src={qrcode}
              alt="QR code"
            />
          </Box>
          <Button
            variant="contained"
            disableRipple
            sx={[positiveButtonStyle, { alignSelf: 'center', mb: '10px' }]}
            size="large"
            onClick={handleShareClick}
          >
            {language.translation.mainButton.share}
          </Button>
          <Button
            variant="contained"
            disableRipple
            sx={[positiveButtonStyle, { alignSelf: 'center', mb: '10px' }]}
            size="large"
            onClick={handlePayClick}
          >
            {language.translation.mainButton.walletPay}
          </Button>
          <Button
            variant="contained"
            disableRipple
            sx={[positiveButtonStyle, { alignSelf: 'center', mb: '10px' }]}
            size="large"
          >
            {language.translation.mainButton.paid}
          </Button>
        </Box>
      )}
      {/* Part 2 end: */}
      {confirm && (
        <DeleteDialog
          isOpen={Boolean(confirm)}
          setOpen={setConfirm}
          deleteMessage={confirm}
          handleDelete={handleCancelInvoice}
        />
      )}
      {eventMessage && (
        <EventDialog
          isOpen={Boolean(eventMessage)}
          setOpen={setEventMessage}
          positive={eventMessage.positive}
          message={eventMessage.message}
          payMessage={eventMessage.payMessage}
        />
      )}
      {redirect && (
        <RedirectDialog
          isOpen={Boolean(redirect)}
          setOpen={setRedirect}
          message={redirect}
          handleConfirm={handleRedirect}
        />
      )}
    </Paper>
  );
}
