import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { positiveButtonStyle } from '../theme/styles';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { cryptoGrey, cryptoPrimary } from '../theme/colors';
import { Address } from '../models/address';
import CloseIcon from '../assets/icon_cancel.svg';
import qrapi from '../api/qrapi';
import ShareIcon from '../assets/share.svg';
import AddressText from './AddressText';
import QRCode from 'react-qr-code';

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<Address | null>>;
  address: Address;
}

const urlToObject = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], 'qr.png', { type: blob.type });
  return file;
};

export default function QRCodeDialog({ isOpen, setOpen, address }: Props) {
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [copied, setCopied] = useState(false);
  const [qrcode, setQrcode] = useState<string>('');

  let file;

  /* useEffect(() => {
    const params = new URLSearchParams();
    params.append('cht', 'qr');
    params.append('chs', '250x250');
    params.append('chl', address.address);
    qrapi.generate(params).then((url) => {
      if (url) {
        setQrcode(url);
      }
    });
  }, []); */

  const handleClose = () => {
    setOpen(null);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(address.address);
    setCopied(true);
  };

  const handleShareClick = async () => {
    const shareData = {
      title: 'My Address',
      text: `my address for receiving funds: ${address.address}\n Blockchain: ${address.blockchain}\n Currency: ${address.accounts[0].currency}`
    };

    // Test compatibility
    if (navigator.share === undefined) {
      console.log('Unsupported share feature');
      return;
    }

    // //Handle file url
    // const files = [await urlToObject(qrcode)];
    // if (!navigator.canShare || !navigator.canShare({ files: files })) {
    //   console.log('Unsupported share feature');
    //   return;
    // }
    // Share content
    try {
      await navigator
        .share(shareData)
        .then(() => console.log('Addreess shared successfully'));
    } catch (error) {
      console.log(`Error sharing: ${error}`);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="qr-dialog-title"
      aria-describedby="qr-dialog-description"
      PaperProps={{
        style: {
          alignItems: 'center',
          backgroundColor: cryptoPrimary[50],
          borderRadius: '10px',
          minHeight: '230px',
          width: '320px',
          padding: '15px'
        }
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          width: '280px',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          pb: '5px'
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <DialogTitle sx={{ p: 0, fontSize: '1rem' }}>
            {language.translation.receiveWindow.receive}
          </DialogTitle>
        </Box>
        <IconButton
          disableRipple
          onClick={handleClose}
          sx={{
            p: '5px',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: cryptoGrey[100]
            }
          }}
        >
          <img
            style={{
              height: 25
            }}
            src={CloseIcon}
            alt="Close"
          />
        </IconButton>
      </Stack>
      <DialogContent sx={{ p: '5px', width: '100%' }}>
        <Stack
          direction={'row'}
          sx={{ justifyContent: 'flex-start', mb: '5px', width: '100%'  }}
        >
          <Typography>{language.translation.receiveWindow.asset}</Typography>
          <Typography sx={{ px: '5px', color: cryptoPrimary[900] }}>
            {address.accounts[1] ? address.accounts[1].symbol : address.accounts[0].symbol}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          sx={{ justifyContent: 'flex-start', mb: '5px' }}
        >
          <Typography>{language.translation.bill.blockchain}</Typography>
          <Typography sx={{ px: '5px', color: cryptoPrimary[900] }}>
            {address.blockchain}
          </Typography>
        </Stack>
        <Typography sx={{ mb: '5px' }}>
          {language.translation.receiveWindow.receiveAddress}
        </Typography>
        <Tooltip title={copied ? 'Copied' : 'Click to copy'}>
          <Stack
            direction={'row'}
            sx={{ justifyContent: 'flex-start', mb: '5px', cursor: 'pointer' }}
            onClick={handleCopyClick}
          >
            <AddressText address={address.address} 
              fontHeight='1rem' lineHeight='1.5rem' 
              color={cryptoPrimary[200]} 
              stringLen={300}/>
            {/* <Typography
              noWrap
              sx={{ width: '230px', color: cryptoPrimary[200] }}
            >
              {address.address}
            </Typography>
            <Typography sx={{ color: cryptoPrimary[200], ml: '-0.7rem' }}>
              {address.address.slice(-4)}
            </Typography> */}
          </Stack>
        </Tooltip>
        <QRCode value={address.address} size={250} style={{width: '100%'}}/>
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            py: '10px'
          }}
        >
          <img
            style={{
              height: 250
            }}
            src={qrcode}
            alt="QR code"
          />
        </Box> */}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', p: '10px' }}>
        <Button
          disableRipple
          sx={[positiveButtonStyle, { color: 'white' }]}
          onClick={handleShareClick}
          endIcon={
            <img
              src={ShareIcon}
              alt={language.translation.mainButton.share}
              style={{ width: '16px', height: '16px' }}
            />
          }
        >
          {language.translation.mainButton.share}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
