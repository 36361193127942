export interface Invoice {
  id: string;
  blockchain: string;
  symbol: string;
  address: string;
  contract_address: string;
  active: boolean;
  value: number;
  created: string;
  expiry: string;
  status: string;
  confirmations: number;
  fiat_currency: FiatCurrency;
  txs: string[];
  data: InvoiceData | null;
}

export interface FiatCurrency {
  currency: string;
  value: number;
  percent: number;
  rate: number;
}

export interface InvoiceData {
  order_id: string | null;
  title: string;
  description: string | null;
  user_data: InvoiceUserData;
  url_callback?: string;
  url_success?: string;
  url_failed?: string;
}

export interface InvoiceUserData {
  username: string;
  completed: boolean;
  tariff_id: number;
}

export interface InvoiceFilterParams {
  blockchain?: string;
  address?: string;
  contract?: string;
  from?: string;
  to?: string;
  status?: InvoiceStatus;
  order_id?: string;
  active?: boolean;
}

export enum InvoiceStatus {
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  IN_BLOCK = 'IN_BLOCK',
  IN_MEMPOOL = 'IN_MEMPOOL'
}
