import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import blueArrowIcon from '../assets/bluearrow.svg';
import { cryptoGrey, cryptoPrimary, cryptoSecondary } from '../theme/colors';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import api from '../api/api';
import { useNavigate } from 'react-router-dom';
import { BlueArrow } from './icons/bluearrow';
import { UserContext } from '../context/User';

interface Props {
  id: string;
  name: string;
  balance: number;
  fiat_currency: string;
  selected: boolean;
}

const itemStyle = {
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  pl: '15px',
  backgroundColor: cryptoPrimary[50],
  color: 'black',
  '&:hover': {
    backgroundColor: cryptoGrey[100]
  }
} as const;

const circleStyle = {
  display: 'inline-block',
  backgroundColor: cryptoSecondary[100],
  borderRadius: '50%',
  width: '8px',
  height: '8px',
  m: '10px'
} as const;

export default function WalletItem({
  id,
  name,
  balance,
  fiat_currency,
  selected
}: Props) {
  const userCtx = useContext(UserContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [loggedOut, setLoggedOut] = useState(false);
  let navigate = useNavigate();

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: fiat_currency || 'USD'
  });
  const [symbol, ...others] = USDollar.formatToParts(balance);
  const balanceString = `${symbol.value} ${others
    .map((v) => v.value)
    .join('')}`;

  useEffect(() => {
    if (loggedOut) {
      navigate('../login');
    }
  }, [loggedOut, navigate]);

  const handleWalletClick = () => {
    api.account
      .change({ defaultWalletId: id })
      .then((result) => {
        if (result) {
          //console.log(result);
          userCtx.setUpdated(false);
          navigate(`../wallet/${id}`);
        }
      })
      .catch((er) => {
        //console.log(er);
        if (er.response.status === 401) {
          userCtx.setUser(null);
          setLoggedOut(true);
        }
      });
  };

  return (
    <Box
      component={'div'}
      sx={{
        height: '80px',
        minHeight: '80px',
        padding: '1px',
        paddingTop: 0,
        backgroundColor: cryptoSecondary[50]
      }}
    >
      <Stack direction={'row'} sx={itemStyle}>
        <Stack>
          <Typography>{name}</Typography>
          <Typography sx={{ fontSize: '0.875rem' }}>{balanceString}</Typography>
        </Stack>
        <Tooltip title={language.translation.topWindow.wallet}>
          <IconButton
            disableRipple
            onClick={handleWalletClick}
            sx={{ height: '100%', width: '79px', px: '20px', borderRadius: 0 }}
          >
            <Stack
              direction={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              sx={{ height: '100%', width: '100%' }}
            >
              {selected && <Box component={'span'} sx={circleStyle}></Box>}
              <img
                src={blueArrowIcon}
                alt={language.translation.topWindow.wallet}
                style={{ height: '16px' }}
              />
              {/* <BlueArrow sx={{ height: '18px' }} /> */}
            </Stack>
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
}
