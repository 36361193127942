import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { cryptoPrimary, cryptoSecondary } from '../theme/colors';
import blueArrowIcon from '../assets/bluearrow.svg';

interface Props {
  text: string;
  onClickHadler: () => void;
}

const itemStyle = {
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  px: '15px',
  backgroundColor: cryptoPrimary[50],
  color: 'black'
} as const;

export default function OptionItem({ text, onClickHadler }: Props) {
  return (
    <Box
      component={'div'}
      sx={{
        height: '60px',
        minHeight: '60px',
        padding: '1px',
        paddingTop: 0,
        backgroundColor: cryptoSecondary[50]
      }}
    >
      <Stack direction={'row'} sx={itemStyle}>
        <Typography sx={{ minWidth: '100px' }}>{text}</Typography>
        <Tooltip title={text}>
          <IconButton disableRipple
            onClick={onClickHadler}
            sx={{ p: '4px', borderRadius: 0 }}
          >
            <img
              style={{
                height: 16
              }}
              src={blueArrowIcon}
              alt={text}
            />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
}
