import { Box, IconButton, Paper, Stack, Typography } from '@mui/material';
import HeaderRight from '../components/HeaderRight';
import { listStyle, paperStyle } from '../theme/styles';
import {
  cryptoAlpha,
  cryptoGrey,
  cryptoPrimary,
  cryptoSecondary
} from '../theme/colors';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { Account } from '../models/account';
import api from '../api/api';
import { Asset } from '../models/paymentData';
import { UserContext } from '../context/User';
import { useNavigate } from 'react-router-dom';
import dash from '../assets/dash.svg';
import ltc from '../assets/ltc.svg';
import usdt from '../assets/usdt.svg';
import ada from '../assets/ada.svg';
import eth from '../assets/eth.svg';
import btc from '../assets/btc.svg';
import trid from '../assets/trid.svg';
import bnb from '../assets/bnb.svg';
import doge from '../assets/doge.svg';

const images = {
  DASH: dash,
  LTC: ltc,
  USDT: usdt,
  ADA: ada,
  ETH: eth,
  BTC: btc,
  TRID: trid,
  BNB: bnb,
  DOGE: doge
};

const labelStyle = {
  lineHeight: 1.875
};

const valueStyle = {
  px: '10px',
  color: cryptoGrey[800]
};

const sectionStyle = {
  width: '100%',
  alignItems: 'flex-start',
  py: '10px',
  color: 'black',
  borderBottom: `1px solid ${cryptoAlpha[800]}`
};

interface Props {
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  account: Account;
}

export default function CurrencyInfo({ setActive, account }: Props) {
  const userCtx = useContext(UserContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [assets, setAssets] = useState<Asset[]>([]);
  const [asset, setAsset] = useState<Asset | null>(null);
  const [rateString, setRateString] = useState<string>('');
  const [marketCapString, setMarketCapString] = useState<string>('');
  const [volumeString, setVolumeString] = useState<string>('');
  const [circulationSupplyString, setCirculationSupplyString] =
    useState<string>('');
  const [totalSupplyString, setTotalSupplyString] = useState<string>('');
  let navigate = useNavigate();
  let image;
  Object.entries(images).find(([k, v]) => {
    if (k === account.symbol) {
      image = v;
      return true;
    } else {
      return false;
    }
  });

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  useEffect(() => {
    if (asset) {
      setRateString(USDollar.format(asset.exchangeData.rate.USD));
      if (asset.exchangeData.market_cap) {
        setMarketCapString(USDollar.format(asset.exchangeData.market_cap));
      }
      if (asset.exchangeData.volume) {
        setVolumeString(USDollar.format(asset.exchangeData.volume));
      }
      let Coin = new Intl.NumberFormat('en-US', {
        style: 'decimal'
      });
      if (asset.exchangeData.calculation_supply) {
        setCirculationSupplyString(
          `${Coin.format(asset.exchangeData.calculation_supply)} ${
            asset.symbol
          }`
        );
      }
      if (asset.exchangeData.total_supply) {
        setTotalSupplyString(
          `${Coin.format(asset.exchangeData.total_supply)} ${asset.symbol}`
        );
      }
    }
  }, [asset]);

  useEffect(() => {
    if (!asset || asset.symbol !== account.symbol) {
      if (assets.length > 0) {
        const found = assets.find((a) => a.symbol === account.symbol);
        if (found) {
          setAsset(found);
        }
      }
    }
  }, [asset, assets, account]);

  useEffect(() => {
    api.general
      .paymentData()
      .then((response) => {
        //console.log(response);
        if (response?.walletList) {
          setAssets(response.walletList);
        }
      })
      .catch((er) => {
        //console.log(er);
        if (er.response.status === 401) {
          userCtx.setUser(null);
          navigate('../../login');
        }
      });
  }, []);

  return (
    <Paper
      elevation={0}
      square
      sx={[paperStyle, { marginLeft: { xs: 'auto', sm: 0 }, height: '100%' }]}
    >
      <HeaderRight
        title={language.translation.topWindow.currencyInfo}
        setActive={setActive}
      />
      <Box
        component="div"
        sx={[
          listStyle,
          { backgroundColor: cryptoSecondary[50], height: '100%' }
        ]}
      >
        <Box sx={{ width: '100%', px: '30px', py: '20px' }}>
          <Stack direction={'row'} sx={sectionStyle}>
            <IconButton disableRipple
              disabled
              //onClick={handleWalletClick}
              sx={{ p: 0, borderRadius: 0 }}
            >
              <img
                style={{
                  width: 50
                }}
                src={image}
                alt={account.symbol}
              />
            </IconButton>
            <Stack
              sx={{ alignItems: 'flex-start', px: '24px', color: 'black' }}
            >
              <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                <Typography sx={labelStyle}>
                  {language.translation.coinInfo.name}
                </Typography>
                <Typography sx={valueStyle}>{asset?.name}</Typography>
              </Stack>
              <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                <Typography sx={labelStyle}>
                  {language.translation.coinInfo.symbol}
                </Typography>
                <Typography sx={valueStyle}>{asset?.symbol}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={sectionStyle}>
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
              <Typography sx={labelStyle}>
                {language.translation.coinInfo.type}
              </Typography>
              <Typography sx={valueStyle}>{asset?.type_currency}</Typography>
            </Stack>
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
              <Typography sx={labelStyle}>
                {language.translation.coinInfo.blockchain}
              </Typography>
              <Typography sx={valueStyle}>{asset?.blockchain}</Typography>
            </Stack>
            <Typography sx={labelStyle}>
              {language.translation.coinInfo.contractAddress}
            </Typography>
            <Box
              sx={{
                minHeight: '1.875rem',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{ fontSize: '0.75rem', color: cryptoPrimary[200] }}
              >
                {asset?.contract_address}
              </Typography>
            </Box>
          </Stack>
          <Stack sx={sectionStyle}>
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
              <Typography sx={labelStyle}>
                {language.translation.coinInfo.rate}
              </Typography>
              <Typography sx={valueStyle}>{rateString}</Typography>
            </Stack>
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
              <Typography sx={labelStyle}>
                {language.translation.coinInfo.marketCap}
              </Typography>
              <Typography sx={valueStyle}>{marketCapString}</Typography>
            </Stack>
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
              <Typography sx={labelStyle}>
                {language.translation.coinInfo.volume}
              </Typography>
              <Typography sx={valueStyle}>{volumeString}</Typography>
            </Stack>
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
              <Typography sx={labelStyle}>
                {language.translation.coinInfo.circulation}
              </Typography>
              <Typography sx={valueStyle}>{circulationSupplyString}</Typography>
            </Stack>
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
              <Typography sx={labelStyle}>
                {language.translation.coinInfo.total}
              </Typography>
              <Typography sx={valueStyle}>{totalSupplyString}</Typography>
            </Stack>
          </Stack>
          <Box sx={{ width: '100%' }}>
            <Typography sx={valueStyle}>
              {asset?.exchangeData.other_info}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
