import { createSvgIcon } from '@mui/material/utils';

export const BlueSelect = createSvgIcon(
  <svg
    width="19"
    height="12"
    viewBox="0 0 19 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M1.6875 2.55659C1.6875 2.90315 1.81174 3.18751 2.04246 3.41856L8.68916 10.2343C8.99088 10.5364 9.30148 10.6875 9.68306 10.6875C10.0646 10.6875 10.3841 10.5453 10.677 10.2343L17.3325 3.41856C17.5633 3.17863 17.6875 2.89427 17.6875 2.55659C17.6875 1.87235 17.1373 1.31251 16.454 1.31251C16.1168 1.31251 16.454 1.31246 15.6875 1.31257L9.6875 1.3125L3.6875 1.31252C3.6875 1.31252 3.25821 1.31251 2.921 1.31251C2.23769 1.31251 1.6875 1.87235 1.6875 2.55659Z"
        fill="#329DFF"
      />
    </g>
  </svg>,
  'BlueSelect'
);
