import axios, { AxiosResponse } from 'axios';
import {
  ActivationRequest,
  ChangeCurrencyRequest,
  ChangeDefaultWalletRequest,
  ChangeEmailRequest,
  ChangeLanguageRequest,
  LoginRequest,
  RegisterRequest,
  UserProfile
} from '../models/user';
import {
  ChangeWalletNameRequest,
  CreateWalletRequest,
  WalletInfo,
  WalletSeedData
} from '../models/wallet';
import { PaymentData } from '../models/paymentData';
import {
  AddressListResponse,
  CreateAddressRequest,
  CreateAddressWithContractRequest,
  CreateForAddressRequest,
  DeleteAddressRequest
} from '../models/address';
import { Transfer, CreateTXRequest, SendTXRequest } from '../models/transfer';
import { AccountTxs } from '../models/account';
import { Invoice } from '../models/invoice';
import { SelectTarifRequest, Tarif } from '../models/tarif';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = window.localStorage.getItem('apiforcrypto.jwt');
  if (config.url?.includes('login') || config.url?.includes('registration')) {
    return config;
  } else if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
});

const responseBody = <T>(response: AxiosResponse<T>) => {
  if (response.status < 300) {
    return response.data;
  } else {
    return null;
  }
};

const requests = {
  get: <T>(url: string, params?: URLSearchParams) =>
    axios.get<T>(url, { params }).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body),
  del: <T>(url: string, body: {}) => axios.delete<T>(url, { data: body })
};

const account = {
  profile: () => requests.get<UserProfile>('/profile'),
  login: (user: LoginRequest) => requests.post('/login', user),
  register: (user: RegisterRequest) => requests.post('/registration', user),
  activate: (user: ActivationRequest) =>
    requests.post('/registration/activate', user),
  change: (walletId: ChangeDefaultWalletRequest) =>
    requests.put('/profile', walletId),
  changeLang: (langId: ChangeLanguageRequest) =>
    requests.put('/profile', langId),
  changeEmail: (email: ChangeEmailRequest) => requests.put('/profile', email),
  changeCur: (currency: ChangeCurrencyRequest) =>
    requests.put('/profile', currency),
  selectTarif: (id: string, tarif: SelectTarifRequest) =>
    requests.post(`/profile/tariffs/${id}`, tarif)
};

const wallet = {
  create: (wallet: CreateWalletRequest) =>
    requests.post('/profile/wallets', wallet),
  details: (id: string) => requests.get<WalletInfo>(`/wallets/${id}`),
  seed: (id: string) =>
    requests.get<WalletSeedData>(`/profile/wallets/${id}/data`),
  invoices: (id: string, params: URLSearchParams) =>
    requests.get<Invoice[]>(`/profile/wallets/${id}/invoices`, params),
  invoicesTariffPay: (id: string, params: URLSearchParams) =>
    requests.get<Invoice[]>(`/profile/wallets/${id}/invoices/tariff_pay`, params),
  invoice: (id: string) =>
    requests.get<Invoice>(`/wallets/invoices/${id}`),
  changeName: (name: ChangeWalletNameRequest) =>
    requests.put('/profile/wallets', name),
  delete: (id: string) => requests.del(`/profile/wallets/${id}`, {})
};

const general = {
  paymentData: () => requests.get<PaymentData>('/general/payment_data'),
  tariffs: () => requests.get<Tarif[]>('/general/tariffs')
};

const address = {
  create: (id: string, address: CreateAddressRequest) =>
    requests.post(`/wallets/${id}/addresses`, address),
  createWithContract: (id: string, address: CreateAddressWithContractRequest) =>
    requests.post(`/wallets/${id}/addresses`, address),
  createForAddress: (
    id: string,
    addressId: string,
    address: CreateForAddressRequest
  ) => requests.post(`/wallets/${id}/addresses/${addressId}`, address),
  deleteAddress: (
    id: string,
    addressId: string,
    address: DeleteAddressRequest
  ) => requests.del(`/profile/wallets/${id}/addresses/${addressId}`, address),
  list: (id: string, params: URLSearchParams) =>
    requests.get<AddressListResponse>(`/wallets/${id}/addresses`, params)
};

const history = {
  createTX: (id: string, TX: CreateTXRequest) =>
    requests.post(`/wallets/${id}/txs/new`, TX),
  sendTX: (id: string, TX: SendTXRequest) =>
    requests.post(`/wallets/${id}/txs/send`, TX),
  list: (id: string, params: URLSearchParams) =>
    requests.get<Transfer[]>(`/wallets/${id}/txs`, params),
  listForAddress: (id: string, addressId: string, params: URLSearchParams) =>
    requests.get<AccountTxs>(
      `/wallets/${id}/addresses/${addressId}/txs`,
      params
    )
};

const api = { account, wallet, general, address, history };

export default api;
