import { Box, Paper } from '@mui/material';
import HeaderRight from '../components/HeaderRight';
import { listStyle, paperStyle } from '../theme/styles';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { Asset } from '../models/paymentData';
import api from '../api/api';
import { UserContext } from '../context/User';
import { useNavigate } from 'react-router-dom';
import CoinItem from '../components/CoinItem';
import { cryptoSecondary } from '../theme/colors';
import { EventMessage } from '../models/eventMessage';
import EventDialog from '../components/EventDialog';
import { WalletInfo } from '../models/wallet';
import { PaymentDataContext } from '../context/PaymentData';

interface Props {
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  setInvoice: React.Dispatch<React.SetStateAction<Asset | null>>;
  walletId: string;
  wallet: WalletInfo;
}

export default function AddAsset({
  setActive,
  setUpdated,
  setInvoice,
  walletId,
  wallet
}: Props) {
  const userCtx = useContext(UserContext);
  const user = userCtx.user;
  const paymentDataCtx = useContext(PaymentDataContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [assets, setAssets] = useState<Asset[]>([]);
  const [eventMessage, setEventMessage] = useState<EventMessage | null>(null);
  let navigate = useNavigate();

  useEffect(() => {
    if (paymentDataCtx.paymentData?.walletList) {
      const filtered = paymentDataCtx.paymentData.walletList.filter(
        (a) => !wallet.accounts.some((e) => e.symbol === a.symbol)
      );
      setAssets(filtered);
    }
  }, [paymentDataCtx.paymentData]);

  return (
    <Paper
      elevation={0}
      square
      sx={[paperStyle, { marginLeft: { xs: 'auto', sm: 0 }, height: '100%' }]}
    >
      <HeaderRight
        title={language.translation.topWindow.addAsset}
        setActive={setActive}
      />
      <Box
        component="div"
        sx={[
          listStyle,
          { backgroundColor: cryptoSecondary[50], height: '100%' }
        ]}
      >
        {assets.map((a) => (
          <CoinItem
            key={a.symbol}
            asset={a}
            walletId={walletId}
            eventMessage={eventMessage}
            setEventMessage={setEventMessage}
            setActive={setActive}
            setUpdated={setUpdated}
            setInvoice={setInvoice}
          />
        ))}
      </Box>
      {eventMessage && (
        <EventDialog
          isOpen={Boolean(eventMessage)}
          setOpen={setEventMessage}
          positive={eventMessage.positive}
          message={eventMessage.message}
        />
      )}
    </Paper>
  );
}
