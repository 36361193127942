import { Box, Button } from '@mui/material';
import { cryptoPrimary } from '../theme/colors';
import { positiveButtonStyle, secondaryButtonStyle } from '../theme/styles';
import { useContext } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';

interface Props {
  handleNextPage: () => void;
}

export default function Pagination({ handleNextPage }: Props) {
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  return (
    <Box
      component={'div'}
      sx={{
        minHeight: '100px',
        padding: '1px',
        paddingTop: 0,
        backgroundColor: cryptoPrimary[50],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Button
        sx={[positiveButtonStyle, { color: 'white' }]}
        onClick={handleNextPage}
        autoFocus
      >
        {language.translation.historyWindow.next}
      </Button>
    </Box>
  );
}
