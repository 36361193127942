import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Account } from '../models/account';
import { Address } from '../models/address';
import { cryptoGrey, cryptoPrimary, cryptoSecondary } from '../theme/colors';
import findImage from '../content/images';
import blueArrowIcon from '../assets/bluearrow.svg';
import { useContext, useMemo, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import AddressText from './AddressText';
import { PaymentDataContext } from '../context/PaymentData';

interface Props {
  account: Account;
  address: Address;
  setAddress?: React.Dispatch<React.SetStateAction<Address | null>>;
  setToken?: React.Dispatch<React.SetStateAction<Account | null>>;
  setAccount?: React.Dispatch<React.SetStateAction<Account | null>>;
  handleTokenSelect?: () => void;
  handleTokenDeselect: () => void;
}

const textStyle = {
  lineHeight: '1rem'
};

export default function TokenItem({
  account,
  address,
  setAddress,
  setToken,
  setAccount,
  handleTokenSelect,
  handleTokenDeselect
}: Props) {
  const paymentDataCtx = useContext(PaymentDataContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];

  const dataImage = useMemo(() => {
    const icon = paymentDataCtx.paymentData?.walletList.find(
      (asset) => asset.symbol === account.symbol
    )?.icon;
    if (icon) {
      return icon;
    } else {
      return findImage(account.symbol);
    }
  }, [account, paymentDataCtx.paymentData]);

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: account.fiat_currency || 'USD'
  });
  const [symbolRate, ...othersRate] = USDollar.formatToParts(account.rate);
  const rateString = `${symbolRate.value} ${othersRate
    .map((v) => v.value)
    .join('')}`;
  const [symbolBallance, ...othersBallance] = USDollar.formatToParts(
    account.fiat_balance
  );
  const fiatBalanceString = `${symbolBallance.value} ${othersBallance
    .map((v) => v.value)
    .join('')}`;
  let numberFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 9
  });
  const decimalBalance = numberFormat.format(
    Math.trunc(account.decimal_balance * 100000000) / 100000000
  );
  const decimalBalanceCoin = `${numberFormat.format(
    Math.trunc(address.accounts[0].decimal_balance * 100000000) / 100000000
  )} ${address.accounts[0].symbol}`;
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(address.address);
    setCopied(true);
  };

  const handleTokenClick = () => {
    if (setAddress) {
      setAddress(address);
    }
    if (setToken) {
      setToken(account);
    }
    if (handleTokenSelect) {
      handleTokenSelect();
    }
  };

  const handleCoinClick = () => {
    if (setAddress) {
      setAddress(address);
    }
    if (setAccount) {
      setAccount(address.accounts[0]);
    }
    if (handleTokenDeselect) {
      handleTokenDeselect();
    }
  };

  return (
    <Box
      component={'div'}
      sx={{
        height: '155px',
        padding: '1px',
        paddingTop: 0,
        backgroundColor: cryptoSecondary[50]
      }}
    >
      <Stack
        sx={{
          justifyContent: 'space-between',
          height: '95px',
          py: '10px',
          px: '15px',
          backgroundColor: cryptoPrimary[50],
          color: 'black'
        }}
      >
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '0.875rem' }}>
            {account.currency}
          </Typography>
          <Typography sx={{ fontSize: '0.875rem', color: 'rgba(0,0,0,0.3)' }}>
            {address.hd_path}
          </Typography>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction={'row'}
            sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
          >
            <IconButton disabled disableRipple sx={{ p: 0, borderRadius: 0 }}>
              <img
                style={{
                  width: 35
                }}
                src={dataImage}
                alt={account.symbol}
              />
            </IconButton>
            <Stack sx={{ alignItems: 'flex-start', px: '15px' }}>
              <Stack
                direction={'row'}
                onClick={handleTokenClick}
                sx={
                  setToken
                    ? {
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: cryptoGrey[100]
                        }
                      }
                    : {}
                }
              >
                <Typography sx={{ fontSize: '1.125rem' }}>
                  {account.symbol}
                </Typography>
                {setToken && (
                  <IconButton
                    disableRipple
                    sx={{
                      p: 0,
                      pl: '10px',
                      borderRadius: 0,
                      '&:hover': {
                        backgroundColor: 'inherit'
                      }
                    }}
                  >
                    <img
                      style={{
                        height: 16
                      }}
                      src={blueArrowIcon}
                      alt={language.translation.topWindow.asset}
                    />
                  </IconButton>
                )}
              </Stack>
              <Typography sx={{ fontSize: '0.875rem' }}>
                {rateString}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ alignItems: 'flex-end' }}>
            <Typography sx={{ fontSize: '1.125rem' }}>
              {decimalBalance}
            </Typography>
            <Typography sx={{ fontSize: '0.875rem' }}>
              {fiatBalanceString}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          height: '59px',
          py: '10px',
          px: '15px',
          backgroundColor: cryptoPrimary[50],
          color: 'black'
        }}
      >
        <Box
          component={'div'}
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'auto 80px',
            justifyContent: 'space-between'
          }}
        >
          <Tooltip title={copied ? 'Copied' : 'Click to copy'}>
            <Stack
              direction={'row'}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                cursor: 'pointer',
                width: '100%',
                overflow: 'hidden'
              }}
              onClick={handleCopyClick}
            >
              <AddressText
                address={address.address}
                fontHeight="1rem"
                lineHeight="1rem"
                stringLen={360}
                color={cryptoPrimary[200]}
              />
            </Stack>
          </Tooltip>
          <Stack direction={'row'} alignItems={'center'} sx={{ pl: '15px' }}>
            <Typography sx={textStyle}>
              {language.translation.coinField.coin}
            </Typography>
            <IconButton
              onClick={handleCoinClick}
              disableRipple
              sx={{
                p: 0,
                pl: '20px',
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: 'inherit'
                }
              }}
            >
              <img
                style={{
                  height: 16
                }}
                src={blueArrowIcon}
                alt={language.translation.coinField.coin}
              />
            </IconButton>
          </Stack>
        </Box>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography sx={textStyle}>{address.accounts[0].currency}</Typography>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography sx={textStyle}>{decimalBalanceCoin}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
