import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext, useEffect, useState } from 'react';
import api from '../api/api';
import { WalletInfo } from '../models/wallet';
import { appBackgroundStyle, listStyle, paperStyle } from '../theme/styles';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import Header from '../components/Header';
import AssetItem from '../components/AssetItem';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import Footer from '../components/Footer';
import bluePlusIcon from '../assets/blueplus.svg';
import { BluePlus } from '../components/icons/blueplus';
import AddAsset from './AddAsset';
import { cryptoAlpha, cryptoPrimary, cryptoSecondary } from '../theme/colors';
import ChooseAddress from './ChooseAddress';
import { Asset } from '../models/paymentData';
import { Account } from '../models/account';
import CurrencyInfo from './CurrencyInfo';
import AssetInfo from './AssetInfo';
import Payout from './Payout';
import { UserContext } from '../context/User';
import { Address } from '../models/address';
import { PaymentDataContext } from '../context/PaymentData';
import { CurrentWalletContext } from '../context/Wallet';

enum Sort {
  SYMBOL = 'symbol',
  FIAT_BALANCE = 'fiat_balance'
}

const itemStyle = {
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  pl: '15px',
  backgroundColor: cryptoPrimary[50],
  color: 'black'
} as const;

export default function Wallet() {
  const userCtx = useContext(UserContext);
  const paymentDataCtx = useContext(PaymentDataContext);
  const currentWalletCtx = useContext(CurrentWalletContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const { id } = useParams<{ id: string }>();
  const [wallet, setWallet] = useState<WalletInfo | null>(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  let navigate = useNavigate();
  const [openSecond, setOpenSecond] = useState(false);
  const [displayFirst, setDisplayFirst] = useState(false);
  const [addAsset, setAddAsset] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [sort, setSort] = useState<Sort>(Sort.SYMBOL);
  const [chooseAddress, setChooseAddress] = useState(false);
  const [invoice, setInvoice] = useState<Asset | null>(null);
  const [account, setAccount] = useState<Account | null>(null);
  const [currencyInfo, setCurrencyInfo] = useState(false);
  const [assetInfo, setAssetInfo] = useState(false);
  const [payout, setPayout] = useState(false);
  const [payoutAddress, setPayoutAddress] = useState<Address | null>(null);
  const [newKey, setNewKey] = useState<number>(new Date().getTime());
  //const [height, setHeight] = useState<number>(window.innerHeight);

  // const handleResize = useCallback(() => {
  //   if (height !== window.innerHeight) {
  //     setHeight(window.innerHeight);
  //   }
  // }, [height]);

  // useEffect(() => {
  //   window.addEventListener('load', handleResize);
  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('load', handleResize);
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, [window.innerHeight, handleResize]);

  useEffect(() => {
    if (!paymentDataCtx.paymentDataUpdated) {
      api.general
        .paymentData()
        .then((response) => {
          //console.log(response);
          if (response) {
            paymentDataCtx.setPaymentData(response);
            paymentDataCtx.setPaymentDataUpdated(true);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    }
  }, [paymentDataCtx.paymentDataUpdated]);

  useEffect(() => {
    if (id && (!wallet || wallet.id !== id || !updated)) {
      api.wallet
        .details(id)
        .then((response) => {
          //console.log(response);
          if (response) {
            setWallet(response);
            setUpdated(true);
            currentWalletCtx.setCurrentWallet(response);
            currentWalletCtx.setCurrentWalletUpdated(true);
            if (addAsset) {
              handleCloseAddAsset();
            } else if (chooseAddress) {
              handleCloseChooseAddress();
            } else if (currencyInfo) {
              handleCloseCurrencyInfo();
            } else if (assetInfo) {
              handleCloseAssetInfo();
            } else if (payout) {
              handleClosePayout();
            }
          }
        })
        .catch((err) => {
          //console.log(err);
          if (err.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    }
  }, [id, wallet, updated]);

  useEffect(() => {
    if (!openSecond || (openSecond && matches)) {
      setDisplayFirst(true);
    } else {
      setDisplayFirst(false);
    }
  }, [openSecond, matches]);

  useEffect(() => {
    if (wallet) {
      let sorted;
      if (sort === Sort.SYMBOL) {
        sorted = wallet.accounts.sort((a, b) =>
          a.symbol.localeCompare(b.symbol)
        );
      } else {
        sorted = wallet.accounts.sort(
          (a, b) => b.fiat_balance - a.fiat_balance
        );
      }
      setWallet({ ...wallet, accounts: sorted });
    }
  }, [sort]);

  useEffect(() => {
    if (invoice) {
      handleChooseAddressClick();
    }
  }, [invoice]);

  // useEffect(() => {
  //   if (account) {
  //     handleCurrencyInfoClick();
  //   }
  // }, [account]);

  const handleAddAssetClick = () => {
    setOpenSecond(true);
    setChooseAddress(false);
    setCurrencyInfo(false);
    setAssetInfo(false);
    setPayout(false);
    setAddAsset(true);
  };

  const handleChooseAddressClick = () => {
    setOpenSecond(true);
    setAddAsset(false);
    setCurrencyInfo(false);
    setAssetInfo(false);
    setPayout(false);
    setChooseAddress(true);
  };

  const handleCurrencyInfoClick = () => {
    setOpenSecond(true);
    setAddAsset(false);
    setChooseAddress(false);
    setAssetInfo(false);
    setPayout(false);
    setCurrencyInfo(true);
  };

  const handleAssetInfoClick = () => {
    setNewKey(new Date().getTime());
    setOpenSecond(true);
    setAddAsset(false);
    setChooseAddress(false);
    setCurrencyInfo(false);
    setPayout(false);
    setAssetInfo(true);
  };

  const handlePayoutClick = () => {
    setOpenSecond(true);
    setAddAsset(false);
    setChooseAddress(false);
    setCurrencyInfo(false);
    setAssetInfo(false);
    setPayout(true);
  };

  const handleCloseAddAsset = () => {
    setOpenSecond(false);
    setAddAsset(false);
  };

  const handleCloseChooseAddress = () => {
    setOpenSecond(false);
    setChooseAddress(false);
    setInvoice(null);
  };

  const handleCloseCurrencyInfo = () => {
    setOpenSecond(false);
    setCurrencyInfo(false);
    setAccount(null);
  };

  const handleCloseAssetInfo = () => {
    setOpenSecond(false);
    setAssetInfo(false);
    setAccount(null);
  };

  const handleClosePayout = () => {
    setOpenSecond(false);
    setPayout(false);
    setAccount(null);
  };

  const handleWalletListClick = () => {
    currentWalletCtx.setCurrentWallet(null);
    currentWalletCtx.setCurrentWalletUpdated(false);
    navigate('../../walletlist');
  };

  const handleClickLogo = () => {
    setUpdated(false);
  };

  const handleSortClick = () => {
    if (sort === Sort.SYMBOL) {
      setSort(Sort.FIAT_BALANCE);
    } else {
      setSort(Sort.SYMBOL);
    }
  };

  return (
    <Box component="div" sx={[appBackgroundStyle, { maxHeight: '100dvh' }]}>
      <Grid
        container
        columnSpacing={'4px'}
        sx={{
          justifyContent: 'flex-start',
          maxHeight: '100dvh'
        }}
      >
        {displayFirst && (
          <Grid item xs={12} sm={6} sx={{ maxHeight: '100dvh' }}>
            <Paper
              elevation={0}
              square
              sx={[
                paperStyle,
                {
                  marginRight: { xs: 'auto', sm: 0 },
                  justifyContent: 'flex-start'
                }
              ]}
            >
              <Header
                handleAddAssetClick={handleAddAssetClick}
                handleWalletListClick={handleWalletListClick}
                handleClickLogo={handleClickLogo}
                handleSortClick={handleSortClick}
              />
              <Box component="div" sx={listStyle}>
                {wallet &&
                  wallet.accounts.map((w) => (
                    <AssetItem
                      key={w.symbol}
                      account={w}
                      setAccount={setAccount}
                      handleCurrencyInfoClick={handleCurrencyInfoClick}
                      handleAssetInfoClick={handleAssetInfoClick}
                    />
                  ))}
                <Box
                  component={'div'}
                  sx={{
                    height: '60px',
                    minHeight: '60px',
                    padding: '1px',
                    paddingTop: 0,
                    backgroundColor: cryptoSecondary[50]
                  }}
                >
                  <Stack direction={'row'} sx={itemStyle}>
                    <Typography sx={{ minWidth: '100px' }}>
                      {language.translation.buttons.addAsset}
                    </Typography>
                    <Tooltip title={language.translation.buttons.addAsset}>
                      <IconButton
                        disableRipple
                        onClick={handleAddAssetClick}
                        sx={{
                          height: '100%',
                          width: '54px',
                          pr: '19px',
                          borderRadius: 0,
                          justifyContent: 'flex-end'
                        }}
                      >
                        <img
                          src={bluePlusIcon}
                          alt={language.translation.buttons.addAsset}
                          style={{ width: '16px' }}
                        />
                        {/* <BluePlus sx={{ width: '18px', height: '18px' }} /> */}
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>
              </Box>
              <Footer
                invoices={
                  wallet?.type === 'INVOICE' ||
                  currentWalletCtx.currentWallet?.type === 'INVOICE'
                    ? true
                    : false
                }
              />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} sm={6} sx={{ height: '100dvh' }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              maxWidth: '704px',
              backgroundColor: cryptoAlpha[900]
            }}
          >
            {addAsset && id && wallet && (
              <AddAsset
                walletId={id}
                wallet={wallet}
                setActive={handleCloseAddAsset}
                setUpdated={setUpdated}
                setInvoice={setInvoice}
              />
            )}
            {chooseAddress && id && invoice && (
              <ChooseAddress
                walletId={id}
                invoice={invoice}
                setActive={handleCloseChooseAddress}
                setUpdated={setUpdated}
              />
            )}
            {currencyInfo && account && (
              <CurrencyInfo
                setActive={handleCloseCurrencyInfo}
                account={account}
              />
            )}
            {assetInfo && account && wallet && (
              <AssetInfo
                key={newKey}
                setActive={handleCloseAssetInfo}
                account={account}
                wallet={wallet}
                setAccount={setAccount}
                setPayoutAddress={setPayoutAddress}
                handlePayoutClick={handlePayoutClick}
                setUpdated={setUpdated}
              />
            )}
            {payout && account && id && (
              <Payout
                walletId={id}
                setActive={handleClosePayout}
                account={account}
                fromAddress={payoutAddress ? payoutAddress : undefined}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
