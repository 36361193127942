import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { ConfirmMessage } from '../models/confirmMessage';
import { cryptoPrimary } from '../theme/colors';
import { useContext } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { positiveButtonStyle, secondaryButtonStyle } from '../theme/styles';
import AddressText from './AddressText';

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<ConfirmMessage | null>>;
  confirmMessage: ConfirmMessage;
  handleConfirm: () => void;
}

const labelStyle = {
  color: 'black',
  fontWeight: '500'
};

const valueStyle = {
  color: cryptoPrimary[900]
};

export default function ConfirmDialog({
  isOpen,
  setOpen,
  confirmMessage,
  handleConfirm
}: Props) {
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];

  const handleBack = () => {
    setOpen(null);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleBack}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      PaperProps={{
        style: {
          alignItems: 'center',
          backgroundColor: cryptoPrimary[50],
          borderRadius: '10px',
          minHeight: '230px',
          minWidth: '320px'
        }
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          p: '30px',
          paddingBottom: 0,
          gap: '6px'
        }}
      >
        <DialogTitle sx={[labelStyle, { p: 0, alignSelf: 'center' }]}>
          {language.translation.transferWindow.transfer}
        </DialogTitle>
        <Typography sx={labelStyle}>
          {language.translation.transferWindow.from}
        </Typography>
        <Stack
          direction={'row'}
          component={'div'}
          sx={{ width: '100%', overflow: 'hidden', alignItems: 'center' }}
        >
          <AddressText
            address={confirmMessage.from}
            fontHeight="1rem"
            lineHeight="1.5rem"
            stringLen={360}
            color={cryptoPrimary[900]}
          />
        </Stack>
        <Typography sx={labelStyle}>
          {language.translation.transferWindow.to}
        </Typography>
        <Stack
          direction={'row'}
          component={'div'}
          sx={{ width: '100%', overflow: 'hidden', alignItems: 'center' }}
        >
          <AddressText
            address={confirmMessage.to}
            fontHeight="1rem"
            lineHeight="1.5rem"
            stringLen={360}
            color={cryptoPrimary[900]}
          />
        </Stack>
        <Typography sx={labelStyle}>
          {language.translation.transferWindow.amount}
        </Typography>
        <Typography sx={valueStyle}>{confirmMessage.amount}</Typography>
        <Typography sx={labelStyle}>
          {language.translation.transferWindow.fee}
        </Typography>
        <Typography sx={valueStyle}>{confirmMessage.fee}</Typography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          p: '1.5rem',
          flexDirection: 'column',
          gap: '20px'
        }}
      >
        <Button
          sx={[secondaryButtonStyle, { color: 'white' }]}
          onClick={handleBack}
          autoFocus
        >
          {language.translation.mainButton.back}
        </Button>
        <Button
          sx={[positiveButtonStyle, { color: 'white' }]}
          onClick={handleConfirm}
          autoFocus
        >
          {language.translation.mainButton.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
