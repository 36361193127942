import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Account } from '../models/account';
import dash from '../assets/dash.svg';
import ltc from '../assets/ltc.svg';
import usdt from '../assets/usdt.svg';
import ada from '../assets/ada.svg';
import eth from '../assets/eth.svg';
import btc from '../assets/btc.svg';
import trid from '../assets/trid.svg';
import bnb from '../assets/bnb.svg';
import doge from '../assets/doge.svg';
import blueArrowIcon from '../assets/bluearrow.svg';
import { useContext, useMemo } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { cryptoGrey, cryptoPrimary, cryptoSecondary } from '../theme/colors';
import { PaymentDataContext } from '../context/PaymentData';

const images = {
  DASH: dash,
  LTC: ltc,
  USDT: usdt,
  ADA: ada,
  ETH: eth,
  BTC: btc,
  TRID: trid,
  BNB: bnb,
  DOGE: doge
};

interface Props {
  account: Account;
  setAccount?: React.Dispatch<React.SetStateAction<Account | null>>;
  handleCurrencyInfoClick?: () => void;
  handleAssetInfoClick?: () => void;
}

const itemStyle = {
  width: '100%',
  height: '95px',
  justifyContent: 'space-between',
  alignItems: 'center',
  px: '15px',
  color: 'black'
} as const;

export default function AssetItem({
  account,
  setAccount,
  handleCurrencyInfoClick,
  handleAssetInfoClick
}: Props) {
  const paymentDataCtx = useContext(PaymentDataContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  let image = '';
  Object.entries(images).find(([k, v]) => {
    if (k === account.symbol) {
      image = v;
      return true;
    } else {
      return false;
    }
  });

  const dataImage = useMemo(() => {
    const icon = paymentDataCtx.paymentData?.walletList.find(
      (asset) => asset.symbol === account.symbol
    )?.icon;
    if (icon) {
      return icon;
    } else {
      return image;
    }
  }, [account, paymentDataCtx.paymentData]);

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: account.fiat_currency || 'USD'
  });
  const [symbolRate, ...othersRate] = USDollar.formatToParts(account.rate);
  const rateString = `${symbolRate.value} ${othersRate
    .map((v) => v.value)
    .join('')}`;
  const [symbolBallance, ...othersBallance] = USDollar.formatToParts(
    account.fiat_balance
  );
  const fiatBalanceString = `${symbolBallance.value} ${othersBallance
    .map((v) => v.value)
    .join('')}`;
  let numberFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 5
  });
  const decimalBalance = numberFormat.format(
    Math.trunc(account.decimal_balance * 10000) / 10000
  );

  const handleIconClick = () => {
    if (setAccount && handleCurrencyInfoClick) {
      setAccount(account);
      handleCurrencyInfoClick();
    }
  };

  const handleAssetClick = () => {
    if (setAccount && handleAssetInfoClick) {
      setAccount(account);
      handleAssetInfoClick();
    }
  };

  return (
    <Box
      component={'div'}
      sx={{
        height: '95px',
        padding: '1px',
        paddingTop: 0,
        backgroundColor: cryptoSecondary[50]
      }}
    >
      <Stack
        sx={{
          height: '100%',
          py: '10px',
          px: '15px',
          backgroundColor: cryptoPrimary[50],
          color: 'black',
          '&:hover': {
            backgroundColor: handleCurrencyInfoClick
              ? cryptoGrey[100]
              : cryptoPrimary[50]
          }
        }}
      >
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '0.875rem' }}>
            {account.currency}
          </Typography>
          {account.type === 'CONTRACT' && handleCurrencyInfoClick && (
            <Typography sx={{ fontSize: '0.875rem', color: 'rgba(0,0,0,0.3)' }}>
              {account.blockchain}
            </Typography>
          )}
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction={'row'}
            sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
          >
            <IconButton
              disabled={handleCurrencyInfoClick ? false : true}
              disableRipple
              onClick={handleIconClick}
              sx={{ p: 0, borderRadius: 0 }}
            >
              <img
                style={{
                  width: 35
                }}
                src={dataImage}
                alt={account.symbol}
              />
            </IconButton>
            <Stack sx={{ alignItems: 'flex-start', px: '15px' }}>
              <Stack
                onClick={handleAssetClick}
                direction={'row'}
                sx={
                  handleAssetInfoClick
                    ? {
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: cryptoGrey[100]
                        }
                      }
                    : {}
                }
              >
                <Typography sx={{ fontSize: '1.125rem' }}>
                  {account.symbol}
                </Typography>
                {handleAssetInfoClick && (
                  <IconButton
                    disableRipple
                    sx={{
                      p: 0,
                      pl: '10px',
                      borderRadius: 0,
                      '&:hover': {
                        backgroundColor: 'inherit'
                      }
                    }}
                  >
                    <img
                      style={{
                        height: 16
                      }}
                      src={blueArrowIcon}
                      alt={language.translation.topWindow.asset}
                    />
                  </IconButton>
                )}
              </Stack>
              <Typography sx={{ fontSize: '0.875rem' }}>
                {rateString}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ alignItems: 'flex-end' }}>
            <Typography sx={{ fontSize: '1.125rem' }}>
              {decimalBalance}
            </Typography>
            <Typography sx={{ fontSize: '0.875rem' }}>
              {fiatBalanceString}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
