import axios from 'axios';

const baseURL = 'https://chart.googleapis.com';

const axiosInstance = axios.create({
  timeout: 2000,
  baseURL: baseURL,
  withCredentials: false
});

const qrImage = {
  generate: (params: URLSearchParams) =>
    axiosInstance
      .get('/chart', { responseType: 'blob', params })
      .then((response) => URL.createObjectURL(response.data))
      .catch((err) => {
        console.log(err);
        return null;
      })
};

export default qrImage;
