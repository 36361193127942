import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {
  appBackgroundStyle,
  listStyle,
  paperStyle,
  secondaryButtonStyle
} from '../theme/styles';
import { useContext, useEffect, useState } from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import {
  cryptoAlpha,
  cryptoGrey,
  cryptoPrimary,
  cryptoSecondary
} from '../theme/colors';
import { UserContext } from '../context/User';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { BlueSelect } from '../components/icons/blueselect';
import editIcon from '../assets/edit.svg';
import editDisabledIcon from '../assets/edit_disabled.svg';
import api from '../api/api';
import EditFormDialog from '../components/EditFormDialog';
import { format } from 'date-fns';
import { EventMessage } from '../models/eventMessage';
import EventDialog from '../components/EventDialog';
import { Wallet, WalletSeedData } from '../models/wallet';
import copyIcon from '../assets/copy.svg';
import downloadIcon from '../assets/download_report.svg';
import eyeIcon from '../assets/eye.svg';
import hideIcon from '../assets/hide.svg';
import { DeleteMessage } from '../models/deleteMessage';
import DeleteDialog from '../components/DeleteDialog';
import CreateInvoice from './CreateInvoice';
import { CurrentWalletContext } from '../context/Wallet';

interface InputProps {
  inputHeight: number;
}

const labelTextStyle = {
  color: 'black',
  fontWeight: 500
} as const;

const valueTextStyle = {
  color: cryptoPrimary[900],
  lineHeight: '2.1875rem'
} as const;

const linkTextStyle = {
  color: cryptoPrimary[200],
  cursor: 'pointer',
  px: '1rem'
} as const;

const menuItemStyle = {
  height: '30px',
  px: '10px',
  py: 0,
  backgroundColor: cryptoPrimary[50],
  justifyContent: 'space-between'
} as const;

const SelectInputStyle = {
  width: '68px',
  backgroundColor: cryptoSecondary[50],
  border: 'none',
  borderRadius: '10px',
  height: '40px',
  boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.25)',
  '&:after, &:before': {
    display: 'none'
  },
  '&:hover, & .Mui-focused': {
    backgroundColor: cryptoGrey[100]
  },
  '& .MuiFilledInput-input': {
    py: 0,
    width: '100%',
    color: 'black',
    fontSize: '1rem',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  '& .MuiIconButton-root': {
    borderRadius: 0
  }
} as const;

const SeedInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'inputHeight'
})<InputProps>(({ theme, inputHeight }) => ({
  width: '100%',
  backgroundColor: cryptoSecondary[50],
  boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.25)',
  border: 'none',
  height: `${inputHeight}px`,
  alignItems: 'flex-start',
  '& .MuiFilledInput-root': {
    '&.Mui-disabled': {
      WebkitTextFillColor: `${cryptoGrey[800]} !important`,
      backgroundColor: 'transparent !important'
    }
  },
  '& .MuiFilledInput-input': {
    color: cryptoGrey[800],
    fontSize: '1rem',
    lineHeight: '30px',
    padding: 0,
    width: '100%',
    '& .Mui-disabled': {
      WebkitTextFillColor: `${cryptoGrey[800]} !important`,
      backgroundColor: 'transparent !important'
    }
  },
  '& .Mui-disabled': {
    WebkitTextFillColor: cryptoGrey[800],
    backgroundColor: 'transparent',
    borderRadius: '10px'
  }
}));

export default function Settings() {
  const userCtx = useContext(UserContext);
  const currentWalletCtx = useContext(CurrentWalletContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [displayFirst, setDisplayFirst] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(language.id);
  const [currencyList, setCurrencyList] = useState<string[]>([]);
  const [editEmail, setEditEmail] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [editWalletName, setEditWalletName] = useState(false);
  const [currencyMenu, setCurrencyMenu] = useState(false);
  const [dateString, setDateString] = useState<string>('');
  const [creditsString, setCreditsString] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [eventMessage, setEventMessage] = useState<EventMessage | null>(null);
  const [defaultWallet, setDefaultWallet] = useState<Wallet | null>(null);
  const [walletData, setWalletData] = useState<WalletSeedData | null>(null);
  const [seedCopied, setSeedCopied] = useState(false);
  const [seedShow, setSeedShow] = useState(false);
  const [walletIdCopied, setWalletIdCopied] = useState(false);
  const [walletIdShow, setWalletIdShow] = useState(false);
  const [transferKeyCopied, setTransferKeyCopied] = useState(false);
  const [transferKeyShow, setTransferKeyShow] = useState(false);
  const [confirm, setConfirm] = useState<DeleteMessage | null>(null);
  const [createInvoice, setCreateInvoice] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    api.general
      .paymentData()
      .then((response) => {
        //console.log(response);
        if (response?.currencyFiatList) {
          setCurrencyList(response.currencyFiatList);
        }
      })
      .catch((er) => {
        //console.log(er);
        if (er.response.status === 401) {
          userCtx.setUser(null);
          navigate('../login');
        }
      });
  }, []);

  useEffect(() => {
    if (userCtx.user) {
      setDateString(format(userCtx.user.created, 'dd.MM.yyyy HH:mm:ss'));
      let FiatCurrency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: userCtx.user.fiatCurrency,
        currencyDisplay: 'code'
      });
      setCreditsString(
        FiatCurrency.format(userCtx.user.credits)
          .replace(`${userCtx.user.fiatCurrency}`, '')
          .trim()
      );
      const wallet = userCtx.user.wallets.find(
        (w) => w.id === userCtx.user?.defaultWalletId
      );
      if (wallet) {
        setDefaultWallet(wallet);
        currentWalletCtx.setCurrentWallet(wallet);
        currentWalletCtx.setCurrentWalletUpdated(true);
      }
    } else {
      setDateString('');
      setCreditsString('');
      setDefaultWallet(null);
      currentWalletCtx.setCurrentWallet(null);
      currentWalletCtx.setCurrentWalletUpdated(false);
    }
  }, [userCtx.user]);

  useEffect(() => {
    if (defaultWallet) {
      api.wallet
        .seed(defaultWallet.id)
        .then((response) => {
          //console.log(response);
          if (response) {
            setWalletData(response);
            setSeedCopied(false);
            setWalletIdCopied(false);
            setTransferKeyCopied(false);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../login');
          }
        });
    }
  }, [defaultWallet]);

  useEffect(() => {
    if (!openSecond || (openSecond && matches)) {
      setDisplayFirst(true);
    } else {
      setDisplayFirst(false);
    }
  }, [openSecond, matches]);

  const handleClickLogo = () => {};

  const handleWalletListClick = () => {
    currentWalletCtx.setCurrentWallet(null);
    currentWalletCtx.setCurrentWalletUpdated(false);
    navigate('../walletlist');
  };

  const handleLanguageChange = (event: SelectChangeEvent) => {
    setSelectedLanguage(event.target.value);
    api.account
      .changeLang({ language: event.target.value.toUpperCase() })
      .then((result) => {
        if (result) {
          //console.log(result);
          userCtx.setUpdated(false);
        }
      })
      .catch((er) => {
        //console.log(er);
        if (er.response.status === 401) {
          userCtx.setUser(null);
          navigate('../login');
        }
      });
  };

  const handleEditEmailClick = () => {
    setEditEmail(true);
  };

  const handleCurrencyMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget.parentElement);
    setCurrencyMenu(true);
  };

  const handleCurrencyMenuClose = () => {
    setCurrencyMenu(false);
  };

  const handleCurrencyMenuItemClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (userCtx.user?.fiatCurrency !== event.currentTarget.id) {
      api.account
        .changeCur({ fiatCurrency: event.currentTarget.id })
        .then((result) => {
          if (result) {
            //console.log(result);
            userCtx.setUpdated(false);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../login');
          }
        });
    }
  };

  const handleEditPasswordClick = () => {
    setEditPassword(true);
  };

  const handleEditWalletNameClick = () => {
    setEditWalletName(true);
  };

  const handleLogoutClick = () => {
    userCtx.setToken(null);
    userCtx.setUser(null);
    navigate('../login');
  };

  const handleDeleteWalletClick = () => {
    setConfirm({
      title: language.translation.deleteWallet.delete,
      text: language.translation.deleteWallet.confirm,
      button: language.translation.mainButton.deleteWallet
    });
  };

  const handleDeleteWallet = () => {
    if (defaultWallet) {
      api.wallet
        .delete(defaultWallet.id)
        .then((response) => {
          //console.log(response);
          if (response) {
            userCtx.setUpdated(false);
            currentWalletCtx.setCurrentWallet(null);
            currentWalletCtx.setCurrentWalletUpdated(false);
            navigate('../walletlist');
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../login');
          } else if (er.response.data) {
            setEventMessage({
              status: er.response.status,
              message: er.response.data.message
                ? er.response.data.message
                : er.message,
              positive: false
            });
          } else {
            setEventMessage({
              status: er.response.status ? er.response.status : 0,
              message: er.message,
              positive: false
            });
          }
        });
    }
  };

  const handleCopySeedClick = () => {
    if (walletData) {
      navigator.clipboard.writeText(walletData.mnemonic);
      setSeedCopied(true);
    }
  };

  const handleCopyWalletIdClick = () => {
    if (walletData) {
      navigator.clipboard.writeText(walletData.id);
      setWalletIdCopied(true);
    }
  };

  const handleCopyTransferKeyClick = () => {
    if (walletData) {
      navigator.clipboard.writeText(walletData.seed_hash);
      setTransferKeyCopied(true);
    }
  };

  const handleDownloadSeedClick = () => {
    if (walletData) {
      const blob = new Blob([walletData.mnemonic], {
        type: 'application/json'
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'seed.txt';
      link.href = url;
      link.click();
    }
  };

  const handleDownloadWalletIdClick = () => {
    if (walletData) {
      const blob = new Blob([walletData.id], {
        type: 'application/json'
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'walletId.txt';
      link.href = url;
      link.click();
    }
  };

  const handleDownloadTransferKeyClick = () => {
    if (walletData) {
      const blob = new Blob([walletData.seed_hash], {
        type: 'application/json'
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'transferKey.txt';
      link.href = url;
      link.click();
    }
  };

  const handleHideSeedClick = () => {
    setSeedShow(!seedShow);
  };

  const handleHideWalletIdClick = () => {
    setWalletIdShow(!walletIdShow);
  };

  const handleHideTransferKeyClick = () => {
    setTransferKeyShow(!transferKeyShow);
  };

  const hideString = (str: string) => {
    return '*'.repeat(str.length);
  };

  const handleCreateInvoiceClick = () => {
    setOpenSecond(true);
    setCreateInvoice(true);
  };

  const handleCloseCreateInvoice = () => {
    setOpenSecond(false);
    setCreateInvoice(false);
  };

  return (
    <Box component="div" sx={[appBackgroundStyle, { maxHeight: '100dvh' }]}>
      <Grid
        container
        columnSpacing={'4px'}
        sx={{ justifyContent: 'center', maxHeight: '100dvh' }}
      >
        {displayFirst && (
          <Grid item xs={12} sm={6} sx={{ maxHeight: '100dvh' }}>
            <Paper
              elevation={0}
              square
              sx={[paperStyle, { marginRight: { xs: 'auto', sm: 0 } }]}
            >
              <Header
                handleClickLogo={handleClickLogo}
                handleWalletListClick={handleWalletListClick}
              />
              <Box component="div" sx={[listStyle, { padding: '30px' }]}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  sx={{ mb: '10px' }}
                >
                  <Typography sx={labelTextStyle}>Language</Typography>
                  <Select
                    id="language-select-filled"
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    variant="filled"
                    IconComponent={BlueSelect}
                    sx={SelectInputStyle}
                    MenuProps={{
                      sx: {
                        '& .MuiMenu-paper': {
                          backgroundColor: cryptoSecondary[50]
                        },
                        '& .MuiMenu-list': {
                          p: '5px',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px'
                        }
                      }
                    }}
                  >
                    {languages.map((lang) => (
                      <MenuItem
                        key={lang.id}
                        id={lang.id}
                        selected={lang.id === selectedLanguage}
                        value={lang.id}
                        sx={menuItemStyle}
                      >
                        <Typography
                          component={'h5'}
                          sx={{
                            p: 0
                          }}
                        >
                          {lang.id.toUpperCase()}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Typography sx={labelTextStyle}>
                  {language.translation.settingsWindow.email}
                </Typography>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography sx={valueTextStyle}>
                    {userCtx.user?.username}
                  </Typography>
                  <Tooltip title={language.translation.settingsWindow.email}>
                    <IconButton
                      disableRipple
                      disabled
                      onClick={handleEditEmailClick}
                      sx={{
                        borderRadius: 0,
                        p: 0,
                        mb: '11px',
                        '&:hover': {
                          backgroundColor: cryptoGrey[100]
                        }
                      }}
                    >
                      <img
                        style={{
                          height: 24
                        }}
                        src={editDisabledIcon}
                        alt={language.translation.settingsWindow.email}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Typography sx={labelTextStyle}>
                  {language.translation.settingsWindow.created}
                </Typography>
                <Typography sx={valueTextStyle}>{dateString}</Typography>
                <Typography sx={labelTextStyle}>
                  {language.translation.settingsWindow.currency}
                </Typography>

                <Box>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography sx={valueTextStyle}>
                      {userCtx.user?.fiatCurrency}
                    </Typography>
                    <Tooltip
                      title={language.translation.settingsWindow.currency}
                    >
                      <IconButton
                        disableRipple
                        onClick={handleCurrencyMenuClick}
                        sx={{
                          borderRadius: 0,
                          p: 0,
                          mb: '11px',
                          '&:hover': {
                            backgroundColor: cryptoGrey[100]
                          }
                        }}
                      >
                        <img
                          style={{
                            height: 24
                          }}
                          src={editIcon}
                          alt={language.translation.settingsWindow.currency}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>

                  <Menu
                    id="currency-menu"
                    anchorEl={anchorEl}
                    open={currencyMenu}
                    onClose={handleCurrencyMenuClose}
                    onClick={handleCurrencyMenuClose}
                    PaperProps={{
                      elevation: 10,
                      sx: {
                        backgroundColor: cryptoSecondary[50],
                        '& .MuiMenu-list': {
                          p: '5px',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px'
                        }
                      }
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                  >
                    {currencyList.map((cur) => (
                      <MenuItem
                        key={cur}
                        id={cur}
                        selected={cur === userCtx.user?.fiatCurrency}
                        onClick={(event) => handleCurrencyMenuItemClick(event)}
                        sx={menuItemStyle}
                      >
                        <Typography component={'h5'} sx={{}}>
                          {cur}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <Stack
                  direction={'row'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                >
                  <Typography sx={labelTextStyle}>
                    {language.translation.settingsWindow.credits}
                  </Typography>
                  {defaultWallet?.type === 'INVOICE' && (
                    <Typography
                      sx={linkTextStyle}
                      onClick={handleCreateInvoiceClick}
                    >
                      {language.translation.settingsWindow.addCredits}
                    </Typography>
                  )}
                </Stack>
                <Typography sx={valueTextStyle}>{creditsString}</Typography>
                <Typography sx={labelTextStyle}>
                  {language.translation.settingsWindow.password}
                </Typography>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography sx={valueTextStyle}>________________</Typography>
                  <Tooltip title={language.translation.settingsWindow.password}>
                    <IconButton
                      disableRipple
                      onClick={handleEditPasswordClick}
                      sx={{
                        borderRadius: 0,
                        p: 0,
                        mb: '11px',
                        '&:hover': {
                          backgroundColor: cryptoGrey[100]
                        }
                      }}
                    >
                      <img
                        style={{
                          height: 24
                        }}
                        src={editIcon}
                        alt={language.translation.settingsWindow.password}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Button
                  disableRipple
                  onClick={handleLogoutClick}
                  sx={[
                    secondaryButtonStyle,
                    { color: 'white', m: '25px', alignSelf: 'center' }
                  ]}
                >
                  Log Out
                </Button>
                {defaultWallet && (
                  <>
                    <Divider sx={{ border: `1px solid ${cryptoGrey[800]}` }} />
                    <Typography sx={[labelTextStyle, { mt: '20px' }]}>
                      {language.translation.settingsWindow.walletName}
                    </Typography>
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Typography sx={valueTextStyle}>
                        {defaultWallet.name}
                      </Typography>
                      <Tooltip
                        title={language.translation.settingsWindow.walletName}
                      >
                        <IconButton
                          disableRipple
                          onClick={handleEditWalletNameClick}
                          sx={{
                            borderRadius: 0,
                            p: 0,
                            mb: '11px',
                            '&:hover': {
                              backgroundColor: cryptoGrey[100]
                            }
                          }}
                        >
                          <img
                            style={{
                              height: 24
                            }}
                            src={editIcon}
                            alt={language.translation.settingsWindow.walletName}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    {walletData && (
                      <>
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          sx={{ mt: '20px' }}
                        >
                          <Typography sx={labelTextStyle}>
                            {language.translation.settingsWindow.seedPhrase}
                          </Typography>
                          <Stack
                            direction={'row'}
                            justifyContent={'flex-end'}
                            gap={'30px'}
                          >
                            <Tooltip
                              title={
                                seedCopied ? 'Copied!' : 'Copy to clipboard'
                              }
                            >
                              <IconButton
                                disableRipple
                                onClick={handleCopySeedClick}
                                sx={{
                                  borderRadius: 0,
                                  p: 0,
                                  mb: '13px',
                                  '&:hover': {
                                    backgroundColor: cryptoGrey[100]
                                  }
                                }}
                              >
                                <img
                                  style={{
                                    height: 24
                                  }}
                                  src={copyIcon}
                                  alt={
                                    seedCopied ? 'Copied!' : 'Copy to clipboard'
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Download">
                              <IconButton
                                disableRipple
                                onClick={handleDownloadSeedClick}
                                sx={{
                                  borderRadius: 0,
                                  p: 0,
                                  mb: '13px',
                                  '&:hover': {
                                    backgroundColor: cryptoGrey[100]
                                  }
                                }}
                              >
                                <img
                                  style={{
                                    height: 24
                                  }}
                                  src={downloadIcon}
                                  alt="Download"
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={seedShow ? 'Hide' : 'Show'}>
                              <IconButton
                                disableRipple
                                onClick={handleHideSeedClick}
                                sx={{
                                  borderRadius: 0,
                                  p: 0,
                                  mb: '13px',
                                  '&:hover': {
                                    backgroundColor: cryptoGrey[100]
                                  }
                                }}
                              >
                                <img
                                  style={{
                                    height: 24
                                  }}
                                  src={seedShow ? hideIcon : eyeIcon}
                                  alt={seedShow ? 'Hide' : 'Show'}
                                />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Stack>
                        <SeedInput
                          fullWidth
                          inputHeight={185}
                          InputProps={{
                            disableUnderline: true
                          }}
                          variant="filled"
                          type={seedShow ? 'text' : 'password'}
                          multiline
                          rows={6}
                          id="seed"
                          name="seed"
                          placeholder={
                            language.translation.settingsWindow.seedPhrase
                          }
                          value={
                            seedShow
                              ? walletData.mnemonic
                              : hideString(walletData.mnemonic)
                          }
                          disabled
                        />
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          sx={{ mt: '20px' }}
                        >
                          <Typography sx={labelTextStyle}>
                            {language.translation.settingsWindow.walletId}
                          </Typography>
                          <Stack
                            direction={'row'}
                            justifyContent={'flex-end'}
                            gap={'30px'}
                          >
                            <Tooltip
                              title={
                                walletIdCopied ? 'Copied!' : 'Copy to clipboard'
                              }
                            >
                              <IconButton
                                disableRipple
                                onClick={handleCopyWalletIdClick}
                                sx={{
                                  borderRadius: 0,
                                  p: 0,
                                  mb: '13px',
                                  '&:hover': {
                                    backgroundColor: cryptoGrey[100]
                                  }
                                }}
                              >
                                <img
                                  style={{
                                    height: 24
                                  }}
                                  src={copyIcon}
                                  alt={
                                    walletIdCopied
                                      ? 'Copied!'
                                      : 'Copy to clipboard'
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Download">
                              <IconButton
                                disableRipple
                                onClick={handleDownloadWalletIdClick}
                                sx={{
                                  borderRadius: 0,
                                  p: 0,
                                  mb: '13px',
                                  '&:hover': {
                                    backgroundColor: cryptoGrey[100]
                                  }
                                }}
                              >
                                <img
                                  style={{
                                    height: 24
                                  }}
                                  src={downloadIcon}
                                  alt="Download"
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={walletIdShow ? 'Hide' : 'Show'}>
                              <IconButton
                                disableRipple
                                onClick={handleHideWalletIdClick}
                                sx={{
                                  borderRadius: 0,
                                  p: 0,
                                  mb: '13px',
                                  '&:hover': {
                                    backgroundColor: cryptoGrey[100]
                                  }
                                }}
                              >
                                <img
                                  style={{
                                    height: 24
                                  }}
                                  src={walletIdShow ? hideIcon : eyeIcon}
                                  alt={walletIdShow ? 'Hide' : 'Show'}
                                />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Stack>
                        <SeedInput
                          fullWidth
                          inputHeight={100}
                          InputProps={{
                            disableUnderline: true
                          }}
                          variant="filled"
                          type={walletIdShow ? 'text' : 'password'}
                          multiline
                          rows={3}
                          id="walletId"
                          name="walletId"
                          placeholder={
                            language.translation.settingsWindow.walletId
                          }
                          value={
                            walletIdShow
                              ? walletData.id
                              : hideString(walletData.id)
                          }
                          disabled
                        />
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          sx={{ mt: '20px' }}
                        >
                          <Typography sx={labelTextStyle}>
                            {language.translation.settingsWindow.transferKey}
                          </Typography>
                          <Stack
                            direction={'row'}
                            justifyContent={'flex-end'}
                            gap={'30px'}
                          >
                            <Tooltip
                              title={
                                transferKeyCopied
                                  ? 'Copied!'
                                  : 'Copy to clipboard'
                              }
                            >
                              <IconButton
                                disableRipple
                                onClick={handleCopyTransferKeyClick}
                                sx={{
                                  borderRadius: 0,
                                  p: 0,
                                  mb: '13px',
                                  '&:hover': {
                                    backgroundColor: cryptoGrey[100]
                                  }
                                }}
                              >
                                <img
                                  style={{
                                    height: 24
                                  }}
                                  src={copyIcon}
                                  alt={
                                    transferKeyCopied
                                      ? 'Copied!'
                                      : 'Copy to clipboard'
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Download">
                              <IconButton
                                disableRipple
                                onClick={handleDownloadTransferKeyClick}
                                sx={{
                                  borderRadius: 0,
                                  p: 0,
                                  mb: '13px',
                                  '&:hover': {
                                    backgroundColor: cryptoGrey[100]
                                  }
                                }}
                              >
                                <img
                                  style={{
                                    height: 24
                                  }}
                                  src={downloadIcon}
                                  alt="Download"
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={transferKeyShow ? 'Hide' : 'Show'}>
                              <IconButton
                                disableRipple
                                onClick={handleHideTransferKeyClick}
                                sx={{
                                  borderRadius: 0,
                                  p: 0,
                                  mb: '13px',
                                  '&:hover': {
                                    backgroundColor: cryptoGrey[100]
                                  }
                                }}
                              >
                                <img
                                  style={{
                                    height: 24
                                  }}
                                  src={transferKeyShow ? hideIcon : eyeIcon}
                                  alt={transferKeyShow ? 'Hide' : 'Show'}
                                />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Stack>
                        <SeedInput
                          fullWidth
                          inputHeight={100}
                          InputProps={{
                            disableUnderline: true
                          }}
                          variant="filled"
                          type={transferKeyShow ? 'text' : 'password'}
                          multiline
                          rows={3}
                          id="transferKey"
                          name="transferKey"
                          placeholder={
                            language.translation.settingsWindow.transferKey
                          }
                          value={
                            transferKeyShow
                              ? walletData.seed_hash
                              : hideString(walletData.seed_hash)
                          }
                          disabled
                        />
                        <Button
                          disableRipple
                          onClick={handleDeleteWalletClick}
                          sx={[
                            secondaryButtonStyle,
                            {
                              color: 'white',
                              m: '25px',
                              mt: '35px',
                              alignSelf: 'center'
                            }
                          ]}
                        >
                          {language.translation.mainButton.deleteWallet}
                        </Button>
                      </>
                    )}
                  </>
                )}
              </Box>
              <Footer
                wallet
                settingsDisabled
                invoices={
                  defaultWallet?.type === 'INVOICE' ||
                  currentWalletCtx.currentWallet?.type === 'INVOICE'
                    ? true
                    : false
                }
              />
            </Paper>
            {editEmail && !eventMessage && (
              <EditFormDialog
                isOpen={editEmail}
                setOpen={setEditEmail}
                setEventMessage={setEventMessage}
              />
            )}
            {editPassword && !eventMessage && (
              <EditFormDialog
                isOpen={editPassword}
                setOpen={setEditPassword}
                password
                setEventMessage={setEventMessage}
              />
            )}
            {editWalletName && defaultWallet && !eventMessage && (
              <EditFormDialog
                isOpen={editWalletName}
                setOpen={setEditWalletName}
                wallet={defaultWallet}
                setEventMessage={setEventMessage}
              />
            )}
            {eventMessage && (
              <EventDialog
                isOpen={Boolean(eventMessage)}
                setOpen={setEventMessage}
                positive={eventMessage.positive}
                message={eventMessage.message}
              />
            )}
            {confirm && (
              <DeleteDialog
                isOpen={Boolean(confirm)}
                setOpen={setConfirm}
                deleteMessage={confirm}
                handleDelete={handleDeleteWallet}
              />
            )}
          </Grid>
        )}

        <Grid item xs={12} sm={6} sx={{ height: '100dvh' }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              maxWidth: '704px',
              backgroundColor: cryptoAlpha[900]
            }}
          >
            {createInvoice && (
              <CreateInvoice setActive={handleCloseCreateInvoice} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
