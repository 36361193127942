import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import {
  errorStyle,
  listStyle,
  paperStyle,
  positiveButtonStyle
} from '../theme/styles';
import HeaderRight from '../components/HeaderRight';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { Account } from '../models/account';
import { cryptoGrey, cryptoPositive, cryptoPrimary } from '../theme/colors';
import scanIcon from '../assets/scan.svg';
import closeIcon from '../assets/del_address.svg';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { EventMessage } from '../models/eventMessage';
import EventDialog from '../components/EventDialog';
import api from '../api/api';
import { UserContext } from '../context/User';
import { useNavigate } from 'react-router-dom';
import { Address } from '../models/address';
import { CreateTXResponse } from '../models/transfer';
import testapi from '../api/testapi';
import ConfirmDialog from '../components/ConfirmDialog';
import { ConfirmMessage } from '../models/confirmMessage';
import AddressText from '../components/AddressText';

interface Props {
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  account: Account;
  fromAddress?: Address;
  walletId: string;
}

const PayoutInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  height: '50px',
  backgroundColor: cryptoGrey[700],
  border: 'none',
  '& .MuiFilledInput-input': {
    width: '100%'
    //fontSize: '3rem',
    //textAlign: 'center'
  }
}));

const labelStyle = {
  fontWeight: '500'
};

const formStyle = {
  maxWidth: 'sm',
  margin: 0,
  py: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '20px'
} as const;

const validationSchema = yup.object({
  amount: yup.number().nonNullable().required('Enter a valid amount'),
  address: yup.string().required('Address is required')
});

export default function Payout({
  setActive,
  account,
  fromAddress,
  walletId
}: Props) {
  const userCtx = useContext(UserContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];

  const [error, setError] = useState<string[]>([]);
  const [fiatAmount, setFiatAmount] = useState<string>('');
  const [toAddress, setToAddress] = useState<string>('');
  const [addressInChange, setAddressInChange] = useState<boolean>(false);
  const [scan, setScan] = useState(false);
  const [counter, setCounter] = useState<number>(0);
  const [eventMessage, setEventMessage] = useState<EventMessage | null>(null);
  const [maxsum, setMaxsum] = useState(false);
  const [TXResponse, setTXResponse] = useState<CreateTXResponse | null>(null);
  const [submitedAmount, setSubmitedAmount] = useState<number>(0);
  const [acceptedAmountString, setAcceptedAmountString] = useState<string>('');
  const [acceptedAmountFiatString, setAcceptedAmountFiatString] =
    useState<string>('');
  const [feesString, setFeesString] = useState<string>('');
  const [feesFiatString, setFeesFiatString] = useState<string>('');
  const [totalString, setTotalString] = useState<string>('');
  const [totalFiatString, setTotalFiatString] = useState<string>('');
  const [insofficient, setInsofficient] = useState<number>(0);
  const [seed, setSeed] = useState<string>('');
  const [confirm, setConfirm] = useState<ConfirmMessage | null>(null);
  const [tooMuch, setTooMuch] = useState<boolean>(false);
  let navigate = useNavigate();

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  let numberFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 20
  });
  let coinBalanceString;
  if (account.type === 'CONTRACT' && fromAddress) {
    coinBalanceString = `${numberFormat.format(
      fromAddress.accounts[0].decimal_balance
    )} ${fromAddress.accounts[0].symbol}`;
  }
  const maxString = `Max ${numberFormat.format(
    account.type === 'CONTRACT' && fromAddress
      ? fromAddress.accounts[1].decimal_balance
      : fromAddress
      ? fromAddress.accounts[0].decimal_balance
      : account.decimal_balance
  )} ${account.symbol}`;

  useEffect(() => {
    if (seed === '') {
      api.wallet
        .seed(walletId)
        .then((response) => {
          //console.log(response);
          if (response) {
            setSeed(response.seed_hash);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    }
    return setSeed('');
  }, []);

  useEffect(() => {
    if (scan) {
      if (counter > 0) {
        setTimeout(() => {
          setCounter(counter - 1);
        }, 1000);
        console.log('Scanning...');
      } else {
        setScan(false);
        setAddressInChange(false);
      }
    }
  }, [scan, counter]);

  useEffect(() => {
    if (TXResponse) {
      setTimeout(() => {
        if (TXResponse) {
          setEventMessage({
            status: 0,
            message: language.translation.windowText.timeUp,
            positive: false
          });
          setTXResponse(null);
        }
      }, 900000);
    }
  }, [TXResponse]);

  const formik = useFormik({
    initialValues: {
      amount: '',
      address: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { amount, address } = values;
      setSubmitedAmount(parseFloat(amount));
      //console.log(amount, address);
      const amountToSend = parseFloat(amount) * 10 ** account.decimal;
      //setToAddress(address);
      api.history
        .createTX(walletId, {
          blockchain: account.blockchain,
          contract_address: account.contract_address,
          seed_hash: seed,
          addresses: fromAddress?.address ? [fromAddress.address] : [],
          outputs: [
            {
              address: address,
              value: amountToSend
            }
          ]
        })
        .then((result) => {
          //console.log(result);
          if (result.data) {
            const createTXResponse = result.data as CreateTXResponse;
            setTXResponse(createTXResponse);
            const amount =
              createTXResponse.outputs[0].value / 10 ** account.decimal;
            const fees = createTXResponse.fees / 10 ** account.decimal;
            const total = amount + fees;
            const fiatAmount = amount * account.rate;
            if (account.type === 'CONTRACT' && fromAddress) {
              const coinFees =
                createTXResponse.fees / 10 ** fromAddress.accounts[0].decimal;
              const fiatFees = coinFees * fromAddress.accounts[0].rate;
              const fiatTotal = fiatAmount + fiatFees;
              setFeesString(
                `${numberFormat.format(coinFees)} ${
                  fromAddress.accounts[0].symbol
                }`
              );
              setFeesFiatString(USDollar.format(fiatFees));
              setTotalString(
                `${numberFormat.format(amount)} ${account.symbol}`
              );
              setTotalFiatString(USDollar.format(fiatTotal));
            } else {
              setFeesString(`${numberFormat.format(fees)} ${account.symbol}`);
              setFeesFiatString(USDollar.format(fees * account.rate));
              setTotalString(`${numberFormat.format(total)} ${account.symbol}`);
              setTotalFiatString(USDollar.format(total * account.rate));
            }

            setAcceptedAmountString(
              `${numberFormat.format(amount)} ${account.symbol}`
            );
            setAcceptedAmountFiatString(USDollar.format(fiatAmount));
          }
        })
        .catch((er) => {
          console.log(er);
          if (er.response.status === 403) {
            if (!isNaN(er.response.data.message)) {
              setInsofficient(er.response.data.message);
              const decimalUnsufficient =
                er.response.data / 10 ** account.decimal;
              let newAmount = -1;
              if (maxsum) {
                newAmount = amountToSend - Math.abs(er.response.data.message);
                //console.log(newAmount);
              }
              if (newAmount > 0) {
                handleSecondCreateTXRequest(newAmount);
              } else {
                setEventMessage({
                  status: er.response.status,
                  message: language.translation.windowText.insufficientFunds,
                  positive: false,
                  insufficientMessage: {
                    funds: `${numberFormat.format(decimalUnsufficient)} ${
                      account.symbol
                    }`,
                    amount: `${
                      account.type === 'CONTRACT' && fromAddress
                        ? fromAddress.accounts[1].decimal_balance
                        : fromAddress
                        ? fromAddress.accounts[0].decimal_balance
                        : account.decimal_balance
                    }`,
                    payout: amount,
                    fee: `${numberFormat.format(
                      (account.type === 'CONTRACT' && fromAddress
                        ? fromAddress.accounts[1].decimal_balance
                        : fromAddress
                        ? fromAddress.accounts[0].decimal_balance
                        : account.decimal_balance) -
                        parseFloat(amount) +
                        Math.abs(decimalUnsufficient)
                    )}`
                  }
                });
              }
            } else {
              setEventMessage({
                status: er.response.status,
                message: er.response.data.message,
                positive: false
              });
            }
          } else if (er.response.data) {
            setEventMessage({
              status: er.response.status,
              message: er.response.data.message
                ? er.response.data.message
                : er.message,
              positive: false
            });
          } else {
            setEventMessage({
              status: er.response.status ? er.response.status : 0,
              message: er.message,
              positive: false
            });
          }
        });
    }
  });

  const handleSecondCreateTXRequest = (amount: number) => {
    api.history
      .createTX(walletId, {
        blockchain: account.blockchain,
        contract_address: account.contract_address,
        seed_hash: seed,
        addresses: fromAddress?.address ? [fromAddress.address] : [],
        outputs: [
          {
            address: toAddress,
            value: amount
          }
        ]
      })
      .then((result) => {
        console.log(result);
        if (result.data) {
          const createTXResponse = result.data as CreateTXResponse;
          setTXResponse(createTXResponse);
          const amount =
            createTXResponse.outputs[0].value / 10 ** account.decimal;
          const fees = createTXResponse.fees / 10 ** account.decimal;
          const total = amount + fees;
          setAcceptedAmountString(
            `${numberFormat.format(amount)} ${account.symbol}`
          );
          setAcceptedAmountFiatString(USDollar.format(amount * account.rate));
          setFeesString(`${numberFormat.format(fees)} ${account.symbol}`);
          setFeesFiatString(USDollar.format(fees * account.rate));
          setTotalString(`${numberFormat.format(total)} ${account.symbol}`);
          setTotalFiatString(USDollar.format(total * account.rate));
        }
      })
      .catch((er) => {
        console.log(er);
        if (er.response.status === 403) {
          if (!Number.isNaN(er.response.data.message)) {
            setInsofficient(er.response.data.message);
            const decimalUnsufficient =
              er.response.data / 10 ** account.decimal;
            const decimalAmmount = amount / 10 ** account.decimal;
            formik.values.amount = '';
            setEventMessage({
              status: er.response.status,
              message: language.translation.windowText.insufficientFunds,
              positive: false,
              insufficientMessage: {
                funds: `${decimalUnsufficient} ${account.symbol}`,
                amount: `${
                  account.type === 'CONTRACT' && fromAddress
                    ? fromAddress.accounts[1].decimal_balance
                    : fromAddress
                    ? fromAddress.accounts[0].decimal_balance
                    : account.decimal_balance
                }`,
                payout: `${decimalAmmount}`,
                fee: `${numberFormat.format(
                  (account.type === 'CONTRACT' && fromAddress
                    ? fromAddress.accounts[1].decimal_balance
                    : fromAddress
                    ? fromAddress.accounts[0].decimal_balance
                    : account.decimal_balance) -
                    decimalAmmount +
                    Math.abs(decimalUnsufficient)
                )}`
              }
            });
          } else {
            setEventMessage({
              status: er.response.status,
              message: er.response.data.message,
              positive: false
            });
          }
        } else if (er.response.data) {
          setEventMessage({
            status: er.response.status,
            message: er.response.data.message
              ? er.response.data.message
              : er.message,
            positive: false
          });
        } else {
          setEventMessage({
            status: er.response.status ? er.response.status : 0,
            message: er.message,
            positive: false
          });
        }
      });
  };

  const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseFloat(event.target.value);
    if (!Number.isNaN(val)) {
      setFiatAmount(`=${USDollar.format(val * account.rate)}`);
      if (
        val >=
        (account.type === 'CONTRACT' && fromAddress
          ? fromAddress.accounts[1].decimal_balance
          : fromAddress
          ? fromAddress.accounts[0].decimal_balance
          : account.decimal_balance)
      ) {
        if (
          val >
          (account.type === 'CONTRACT' && fromAddress
            ? fromAddress.accounts[1].decimal_balance
            : fromAddress
            ? fromAddress.accounts[0].decimal_balance
            : account.decimal_balance)
        ) {
          setTooMuch(true);
        }
        setMaxsum(true);
      } else {
        setMaxsum(false);
        setTooMuch(false);
      }
      formik.handleChange(event);
    } else if (event.target.value === '') {
      formik.handleChange(event);
      setFiatAmount('');
    }
  };

  const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setAddressInChange(false);
    } else {
      setAddressInChange(true);
      setToAddress(event.target.value);
    }
    formik.handleChange(event);
  };

  const handleClickPaste = async () => {
    let pasted = await navigator.clipboard.readText();
    const parsed = parseAddressString(pasted);
    formik.values.address = parsed;
    setToAddress(parsed);
    setAddressInChange(true);
  };

  const parseAddressString = (str: string) => {
    let index = str.indexOf(':');
    if (index >= 0) {
      str = str.slice(index + 1);
    }
    index = str.indexOf('?amount=');
    if (index >= 0) {
      const val = parseFloat(str.slice(index + 8));
      formik.values.amount = str.slice(index + 8);
      setFiatAmount(`=${USDollar.format(val * account.rate)}`);
      str = str.slice(0, index);
    }
    return str;
  };

  const handleClickScan = () => {
    setCounter(30);
    setScan(true);
    setAddressInChange(true);
  };

  const handleScanedResult = (result: string) => {
    const parsed = parseAddressString(result);
    formik.values.address = parsed;
    setToAddress(parsed);
    setCounter(0);
    setScan(false);
  };

  const handleScanError = (error: Error) => {
    console.log(error?.message);
    if (error) {
      setError([error.message]);
    }
    setAddressInChange(false);
    setCounter(0);
    setScan(false);
  };

  const handleClickCancel = () => {
    setError([]);
    formik.values.address = '';
    setAddressInChange(false);
  };

  const handleClickMaxsum = () => {
    formik.values.amount =
      account.type === 'CONTRACT' && fromAddress
        ? fromAddress.accounts[1].decimal_balance.toString()
        : fromAddress
        ? fromAddress.accounts[0].decimal_balance.toString()
        : account.decimal_balance.toString();
    setFiatAmount(
      `=${USDollar.format(
        (account.type === 'CONTRACT' && fromAddress
          ? fromAddress.accounts[1].decimal_balance
          : fromAddress
          ? fromAddress.accounts[0].decimal_balance
          : account.decimal_balance) * account.rate
      )}`
    );
    setMaxsum(true);
    setTooMuch(false);
  };

  const handleClickPayout = () => {
    setConfirm({
      from: fromAddress ? fromAddress.address : account.symbol,
      to: toAddress,
      amount: acceptedAmountString,
      fee: feesString
    });
  };

  const handleClickBack = (bool: boolean) => {
    if (TXResponse) {
      setTXResponse(null);
    } else {
      setActive(bool);
    }
  };

  const handleConfirm = () => {
    setConfirm(null);
    if (TXResponse) {
      api.history
        .sendTX(walletId, {
          blockchain: account.blockchain,
          contract_address: account.contract_address,
          hex: TXResponse.hex
        })
        .then((result) => {
          if (result) {
            console.log('successfully payout');
            setActive(false);
          }
        })
        .catch((er) => {
          setEventMessage({
            status: er.response.status ? er.response.status : 0,
            message: er.message,
            positive: false
          });
        });
    }
  };

  const handleMouseDownScan = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Paper
      elevation={0}
      square
      sx={[
        paperStyle,
        { marginLeft: { xs: 'auto', sm: 0 }, minWidth: '265px' }
      ]}
    >
      <HeaderRight
        title={language.translation.topWindow.payOut}
        setActive={handleClickBack}
      />
      <Box component="div" sx={[listStyle, { p: '30px', gap: '7px' }]}>
        <Box
          component={'div'}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            //gridTemplateColumns: '3rem auto ',
            justifyContent: 'flex-start'
          }}
        >
          <Typography sx={[labelStyle, { minWidth: '3rem' }]}>
            {language.translation.historyWindow.from}
          </Typography>
          <Stack
            direction={'row'}
            component={'div'}
            sx={{ width: '100%', overflow: 'hidden', alignItems: 'center' }}
          >
            <AddressText
              address={fromAddress ? fromAddress.address : account.symbol}
              fontHeight="1rem"
              lineHeight="1rem"
              stringLen={365}
              color="black"
            />
          </Stack>
        </Box>
        {coinBalanceString && (
          <Typography sx={{ color: cryptoGrey[50], pl: '3rem' }}>
            {coinBalanceString}
          </Typography>
        )}
        <Typography
          onClick={TXResponse ? () => {} : handleClickMaxsum}
          sx={
            TXResponse
              ? { color: cryptoGrey[50], pl: '3rem' }
              : { color: cryptoPrimary[200], pl: '3rem', cursor: 'pointer' }
          }
        >
          {maxString}
        </Typography>
        {/* PayOut 2 part */}
        {TXResponse && (
          <>
            <Box
              component={'div'}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                //gridTemplateColumns: '3rem auto ',
                justifyContent: 'flex-start'
              }}
            >
              <Typography sx={[labelStyle, { minWidth: '3rem' }]}>
                {language.translation.historyWindow.to}
              </Typography>
              <Stack
                direction={'row'}
                component={'div'}
                sx={{ width: '100%', overflow: 'hidden', alignItems: 'center' }}
              >
                <AddressText
                  address={toAddress}
                  fontHeight="1rem"
                  lineHeight="1rem"
                  stringLen={365}
                  color="black"
                />
              </Stack>
            </Box>
            <Box sx={{ py: '13px' }}>
              <Divider />
            </Box>
            <Stack
              direction={'row'}
              sx={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Typography sx={labelStyle}>
                {language.translation.transferWindow.amount}
              </Typography>
              <Typography>{acceptedAmountString}</Typography>
            </Stack>
            <Typography sx={{ color: cryptoGrey[50], alignSelf: 'flex-end' }}>
              {acceptedAmountFiatString}
            </Typography>
            <Stack
              direction={'row'}
              sx={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Typography sx={labelStyle}>
                {language.translation.transferWindow.networkFee}
              </Typography>
              <Typography>{feesString}</Typography>
            </Stack>
            <Typography sx={{ color: cryptoGrey[50], alignSelf: 'flex-end' }}>
              {feesFiatString}
            </Typography>
            <Stack
              direction={'row'}
              sx={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Typography sx={labelStyle}>
                {language.translation.transferWindow.total}
              </Typography>
              <Typography>{totalString}</Typography>
            </Stack>
            {coinBalanceString && (
              <Typography sx={{ color: cryptoGrey[50], alignSelf: 'flex-end' }}>
                {feesString}
              </Typography>
            )}
            <Typography sx={{ color: cryptoGrey[50], alignSelf: 'flex-end' }}>
              {totalFiatString}
            </Typography>
            <Box sx={{ py: '13px' }}>
              <Divider />
            </Box>
            <Button
              variant="contained"
              disableRipple
              sx={[positiveButtonStyle, { alignSelf: 'center', my: '5px' }]}
              size="large"
              type="button"
              onClick={handleClickPayout}
            >
              {language.translation.mainButton.next}
            </Button>
          </>
        )}
        {/* End of PayOut 2 part */}
        {/* PayOut 1 part */}
        {!TXResponse && (
          <Box
            maxWidth="sm"
            component="form"
            sx={formStyle}
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <PayoutInput
              sx={{
                '& .MuiFilledInput-input': {
                  fontSize: '1rem',
                  color: tooMuch ? cryptoPositive[50] : 'white'
                }
              }}
              autoFocus
              fullWidth
              variant="filled"
              id="amount"
              name="amount"
              placeholder={language.translation.transferWindow.amount}
              value={formik.values.amount}
              onChange={handleChangeAmount}
              onBlur={formik.handleBlur}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      component={'div'}
                      sx={{ borderLeft: '2px solid white' }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: 'white',
                          px: '10px',
                          lineHeight: '25px'
                        }}
                      >
                        {account.symbol}
                      </Typography>
                    </Box>
                  </InputAdornment>
                )
              }}
            />
            <Box component={'div'} sx={{ height: '20px' }}>
              <Typography sx={{ color: cryptoGrey[50] }}>
                {fiatAmount}
              </Typography>
            </Box>
            <PayoutInput
              sx={{
                '& .MuiFilledInput-input': {
                  fontSize: '0.875rem'
                }
              }}
              fullWidth
              variant="filled"
              id="address"
              name="address"
              placeholder={language.translation.coinField.address}
              value={formik.values.address}
              onChange={handleChangeAddress}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {!addressInChange && (
                      <>
                        <Typography
                          variant="h6"
                          onClick={handleClickPaste}
                          sx={{
                            fontSize: '0.875rem',
                            cursor: 'pointer',
                            color: cryptoPrimary[200],
                            px: '20px'
                          }}
                        >
                          {language.translation.coinField.paste}
                        </Typography>
                        <IconButton
                          disableRipple
                          aria-label="scan"
                          onClick={handleClickScan}
                          onMouseDown={handleMouseDownScan}
                          sx={{ py: '10px', px: '5px' }}
                        >
                          <img
                            style={{
                              height: 23
                            }}
                            src={scanIcon}
                            alt={language.translation.topWindow.addresses}
                          />
                        </IconButton>
                      </>
                    )}
                    {addressInChange && (
                      <IconButton
                        disableRipple
                        aria-label="cancel"
                        onClick={handleClickCancel}
                        sx={{ py: '10px', px: 0 }}
                      >
                        <img
                          style={{
                            height: 27,
                            filter: 'drop-shadow(0px 2px)'
                          }}
                          src={closeIcon}
                          alt={language.translation.mainButton.cancel}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
            />
            <Box component={'div'} sx={errorStyle}>
              {(formik.touched.amount && Boolean(formik.errors.amount)) ||
              (formik.touched.address && Boolean(formik.errors.address))
                ? error.map((e) => (
                    <p key={e} style={{ margin: 0 }}>
                      {e}
                    </p>
                  ))
                : ' '}
              {scan && counter > 0 && <p>{counter}</p>}
            </Box>
            <Button
              variant="contained"
              disableRipple
              disabled={tooMuch || toAddress === ''}
              sx={positiveButtonStyle}
              size="large"
              type="submit"
            >
              {language.translation.mainButton.next}
            </Button>
          </Box>
        )}
        {/* End of PayOut 1 part */}
      </Box>
      {scan && (
        <QrScanner onDecode={handleScanedResult} onError={handleScanError} />
      )}
      {eventMessage && (
        <EventDialog
          isOpen={Boolean(eventMessage)}
          setOpen={setEventMessage}
          positive={eventMessage.positive}
          message={eventMessage.message}
          insufficientMessage={eventMessage.insufficientMessage}
        />
      )}
      {confirm && (
        <ConfirmDialog
          isOpen={Boolean(confirm)}
          setOpen={setConfirm}
          confirmMessage={confirm}
          handleConfirm={handleConfirm}
        />
      )}
    </Paper>
  );
}
