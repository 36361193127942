import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import bell from '../assets/bell.svg';
import smallLogo from '../assets/smalllogo.svg';
import plus from '../assets/plus.svg';
import plusDisabled from '../assets/plus_disabled.svg';
import sort from '../assets/sort.svg';
import sortDisabled from '../assets/sort_disabled.svg';
import filter from '../assets/filter.svg';
import filterDisabled from '../assets/filter_disabled.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { cryptoGrey, cryptoPrimary } from '../theme/colors';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/User';
import { Wallet } from '../models/wallet';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { logoButtonStyle } from '../theme/styles';

interface Props {
  handleAddAssetClick?: () => void;
  handleWalletListClick?: () => void;
  handleClickLogo: () => void;
  handleSortClick?: () => void;
}

const headerStyle = {
  justifyContent: 'space-between',
  background: 'linear-gradient(345deg, #329CFD 1.95%, #14386E 77%)',
  width: '100%',
  height: '135px',
  borderBottomLeftRadius: '25px',
  borderBottomRightRadius: '25px'
} as const;

export default function Header({
  handleAddAssetClick,
  handleWalletListClick,
  handleClickLogo,
  handleSortClick
}: Props) {
  const userCtx = useContext(UserContext);
  const user = userCtx.user;
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [defaultWallet, setDefaultWallet] = useState<Wallet | null>(null);
  const [ballance, setBallance] = useState<string>('');

  useEffect(() => {
    if (user) {
      if (user.defaultWalletId) {
        const defWallet = user.wallets.find(
          (w) => w.id === user.defaultWalletId
        );
        if (defWallet) {
          setDefaultWallet(defWallet);
        }
      }
    } else {
      userCtx.setUpdated(false);
    }
  }, [user]);

  useEffect(() => {
    if (defaultWallet) {
      let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: defaultWallet.fiat_currency || 'USD'
      });
      const [symbol, ...others] = USDollar.formatToParts(defaultWallet.balance);
      setBallance(`${symbol.value} ${others.map((v) => v.value).join('')}`);
    }
  }, [defaultWallet]);

  return (
    <>
      <AppBar position="absolute" sx={headerStyle}>
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
          <Tooltip title={language.translation.topWindow.notification}>
            <IconButton
              disableRipple
              // disabled={handleAddAssetClick ? false : true}
              // onClick={handleAddAssetClick ? handleAddAssetClick : () => {}}
              sx={{ p: '15px', borderRadius: 0 }}
            >
              <img
                style={{
                  width: 20
                  //filter: 'drop-shadow(0px 1px)'
                }}
                src={bell}
                alt={language.translation.topWindow.notification}
              />
            </IconButton>
          </Tooltip>
          <Box component={'div'} sx={logoButtonStyle}>
            <Box
              component="img"
              sx={{ height: '31px', cursor: 'pointer' }}
              src={smallLogo}
              alt="ApiForCrypto logo"
              onClick={handleClickLogo}
            />
          </Box>
          <Tooltip title={language.translation.topWindow.addAsset}>
            <span style={{ display: 'inline-block' }}>
              <IconButton
                disableRipple
                disabled={handleAddAssetClick ? false : true}
                onClick={handleAddAssetClick ? handleAddAssetClick : () => {}}
                sx={{
                  p: '15px',
                  borderRadius: 0
                }}
              >
                <img
                  style={{
                    width: 22
                    //filter: 'drop-shadow(0px 1px)'
                  }}
                  src={handleAddAssetClick ? plus : plusDisabled}
                  alt={language.translation.topWindow.addAsset}
                />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
        <Stack
          direction={'row'}
          sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}
        >
          <Tooltip title={language.translation.topWindow.sorting}>
            <span style={{ display: 'inline-block' }}>
              <IconButton
                disableRipple
                disabled={handleSortClick ? false : true}
                onClick={handleSortClick ? handleSortClick : () => {}}
                sx={{ p: '20px', borderRadius: 0 }}
              >
                <img
                  style={{
                    width: 20,
                    height: 20
                    //filter: 'drop-shadow(2px 2px)'
                  }}
                  src={handleSortClick ? sort : sortDisabled}
                  alt={language.translation.topWindow.sorting}
                />
              </IconButton>
            </span>
          </Tooltip>
          {defaultWallet && (
            <Stack sx={{ minWidth: '125px', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '1.5rem', pt: '5px' }}>
                {ballance}
              </Typography>
              <Typography sx={{ fontSize: '1.25rem', pb: '0.5rem' }}>
                {defaultWallet.name}
              </Typography>
            </Stack>
          )}
          <Tooltip title={language.translation.topWindow.chooseWallet}>
            <span style={{ display: 'inline-block' }}>
              <IconButton
                disableRipple
                disabled={handleWalletListClick ? false : true}
                onClick={
                  handleWalletListClick ? handleWalletListClick : () => {}
                }
                sx={{ p: '20px', borderRadius: 0 }}
              >
                <img
                  style={{
                    width: 20,
                    height: 20
                    //filter: 'drop-shadow(0px 1px)'
                  }}
                  src={handleWalletListClick ? filter : filterDisabled}
                  alt={language.translation.topWindow.chooseWallet}
                />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </AppBar>
      <Box component={'div'} sx={{ height: '135px', minHeight: '135px' }} />
    </>
  );
}
