import { createContext, useContext, useEffect, useState } from 'react';
//import { languages } from '../content/languages';
import languages from '../languages.json';
import { Language, LanguageContextType } from '../models/language';
import { UserContext } from './User';

const languageContextDefault: LanguageContextType = {
  language: languages[0],
  setLang: () => {}
};

export const LanguageContext = createContext<LanguageContextType>(
  languageContextDefault
);
interface Props {
  children: React.ReactNode;
}

export default function LanguageProvider({ children }: Props) {
  const userCtx = useContext(UserContext);
  const user = userCtx.user;
  const [language, setLanguage] = useState<Language>(languages[0]);

  useEffect(() => {
    if (user) {
      const langId = user.language.toLowerCase();
      if (langId) {
        const newLang = languages.find((l) => l.id === langId);
        if (newLang) {
          setLanguage(newLang);
        }
      }
    }
  }, [user]);

  function setLang(langId: string) {
    if (langId) {
      if (language.id !== langId) {
        const newLang = languages.find((l) => l.id === langId);
        if (newLang) {
          setLanguage(newLang);
        }
      }
    }
  }

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLang
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
