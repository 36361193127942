import { Account } from '../models/account';
import { Address } from '../models/address';
import blueArrowIcon from '../assets/bluearrow.svg';
import { useContext, useMemo, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { cryptoGrey, cryptoPrimary, cryptoSecondary } from '../theme/colors';
import findImage from '../content/images';
import AddressText from './AddressText';
import { PaymentDataContext } from '../context/PaymentData';

interface Props {
  account: Account;
  address: Address;
  setAddress?: (address: Address) => void;
  setToken?: (token: Account) => void;
  handleTokenSelect?: () => void;
  handleAddTokenClick?: () => void;
  handleTokensSelect?: () => void;
  handleTokensDeselect?: () => void;
}

export default function AssetItemEVM({
  account,
  address,
  setAddress,
  setToken,
  handleTokenSelect,
  handleAddTokenClick,
  handleTokensSelect,
  handleTokensDeselect
}: Props) {
  const paymentDataCtx = useContext(PaymentDataContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];

  const dataImage = useMemo(() => {
    const icon = paymentDataCtx.paymentData?.walletList.find(
      (asset) => asset.symbol === account.symbol
    )?.icon;
    if (icon) {
      return icon;
    } else {
      return findImage(account.symbol);
    }
  }, [account, paymentDataCtx.paymentData]);

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: account.fiat_currency || 'USD'
  });
  const [symbolRate, ...othersRate] = USDollar.formatToParts(account.rate);
  const rateString = `${symbolRate.value} ${othersRate
    .map((v) => v.value)
    .join('')}`;
  const [symbolBallance, ...othersBallance] = USDollar.formatToParts(
    account.fiat_balance
  );
  const fiatBalanceString = `${symbolBallance.value} ${othersBallance
    .map((v) => v.value)
    .join('')}`;
  let numberFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 9
  });
  const decimalBalance = numberFormat.format(
    Math.trunc(account.decimal_balance * 100000000) / 100000000
  );

  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(address.address);
    setCopied(true);
  };

  const handleAssetClick = () => {
    if (setAddress) {
      setAddress(address);
    }
  };

  const handleTokensClick = () => {
    if (setAddress) {
      setAddress(address);
    }
    if (handleTokensSelect) {
      handleTokensSelect();
    }
  };

  const handleTokenIconClick = (token: Account) => {
    if (setAddress) {
      setAddress(address);
    }
    if (setToken) {
      setToken(token);
    }
    if (handleTokenSelect) {
      handleTokenSelect();
    }
  };

  return (
    <Box
      component={'div'}
      sx={{
        height: '180px',
        padding: '1px',
        paddingTop: 0,
        backgroundColor: cryptoSecondary[50]
      }}
    >
      <Stack
        sx={{
          justifyContent: 'space-between',
          height: '120px',
          py: '10px',
          px: '15px',
          backgroundColor: cryptoPrimary[50],
          color: 'black',
          '&:hover': {
            backgroundColor: cryptoGrey[100]
          }
        }}
      >
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '0.875rem' }}>
            {account.currency}
          </Typography>
          <Typography sx={{ fontSize: '0.875rem', color: 'rgba(0,0,0,0.3)' }}>
            {address.hd_path}
          </Typography>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction={'row'}
            sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
          >
            <IconButton disabled disableRipple sx={{ p: 0, borderRadius: 0 }}>
              <img
                style={{
                  width: 35
                }}
                src={dataImage}
                alt={account.symbol}
              />
            </IconButton>
            <Stack sx={{ alignItems: 'flex-start', px: '15px' }}>
              <Stack
                onClick={handleAssetClick}
                direction={'row'}
                sx={
                  setAddress
                    ? {
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: cryptoGrey[100]
                        }
                      }
                    : {}
                }
              >
                <Typography sx={{ fontSize: '1.125rem' }}>
                  {account.symbol}
                </Typography>
                {setAddress && (
                  <IconButton
                    disableRipple
                    sx={{
                      p: 0,
                      pl: '10px',
                      borderRadius: 0,
                      '&:hover': {
                        backgroundColor: 'inherit'
                      }
                    }}
                  >
                    <img
                      style={{
                        height: 16
                      }}
                      src={blueArrowIcon}
                      alt={language.translation.topWindow.asset}
                    />
                  </IconButton>
                )}
              </Stack>
              <Typography sx={{ fontSize: '0.875rem' }}>
                {rateString}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ alignItems: 'flex-end' }}>
            <Typography sx={{ fontSize: '1.125rem' }}>
              {decimalBalance}
            </Typography>
            <Typography sx={{ fontSize: '0.875rem' }}>
              {fiatBalanceString}
            </Typography>
          </Stack>
        </Stack>
        <Tooltip title={copied ? 'Copied' : 'Click to copy'}>
          <Stack
            direction={'row'}
            sx={{
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mb: '5px',
              cursor: 'pointer'
            }}
            onClick={handleCopyClick}
          >
            <AddressText
              address={address.address}
              fontHeight="0.875rem"
              lineHeight="1rem"
              stringLen={320}
              color={cryptoPrimary[200]}
            />
          </Stack>
        </Tooltip>
      </Stack>
      {!handleAddTokenClick && (
        <Stack
          direction={'row'}
          sx={{
            height: '59px',
            p: '15px',
            justifyContent: 'space-between',
            backgroundColor: cryptoPrimary[50],
            color: 'black',
            '&:hover': {
              backgroundColor: cryptoGrey[100]
            }
          }}
        >
          <Stack direction={'row'} gap={'10px'}>
            {address.accounts.slice(1).map((a) => {
              const icon = paymentDataCtx.paymentData?.walletList.find(
                (asset) => asset.symbol === a.symbol
              )?.icon;
              return (
                <IconButton
                  disableRipple
                  onClick={() => handleTokenIconClick(a)}
                  key={a.symbol}
                  sx={{ p: 0 }}
                >
                  <img
                    style={{
                      height: 30
                    }}
                    src={icon ? icon : findImage(a.symbol)}
                    alt={a.symbol}
                  />
                </IconButton>
              );
            })}
          </Stack>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography>{language.translation.buttons.tokens}</Typography>
            <IconButton
              onClick={handleTokensClick}
              disableRipple
              sx={{
                p: 0,
                pl: '20px',
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: 'inherit'
                }
              }}
            >
              <img
                style={{
                  height: 16
                }}
                src={blueArrowIcon}
                alt={language.translation.topWindow.asset}
              />
            </IconButton>
          </Stack>
        </Stack>
      )}
      {handleAddTokenClick && (
        <Stack
          direction={'row'}
          sx={{
            height: '59px',
            p: '15px',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: cryptoPrimary[50],
            color: 'black',
            '&:hover': {
              backgroundColor: cryptoGrey[100]
            }
          }}
        >
          <Typography
            onClick={handleAddTokenClick}
            sx={{ color: cryptoPrimary[200], cursor: 'pointer' }}
          >
            {language.translation.buttons.addToken}
          </Typography>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography>{language.translation.buttons.tokens}</Typography>
            <IconButton
              onClick={handleTokensDeselect}
              disableRipple
              sx={{
                p: 0,
                pl: '20px',
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: 'inherit'
                }
              }}
            >
              <img
                style={{
                  height: 16,
                  transform: 'rotate(-90deg)'
                }}
                src={blueArrowIcon}
                alt={language.translation.topWindow.asset}
              />
            </IconButton>
          </Stack>
        </Stack>
      )}
    </Box>
  );
}
