import { createSvgIcon } from '@mui/material/utils';

export const RadioChecked = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <g filter="url(#filter0_i_1_2203)">
      <circle cx="16" cy="16" r="15" fill="#C6D6DC" />
    </g>
    <circle cx="16" cy="16" r="15.5" stroke="#297BC7" />
    <g filter="url(#filter1_i_1_2203)">
      <circle cx="16" cy="16" r="10" fill="#329DFF" />
    </g>
    <defs>
      <filter
        id="filter0_i_1_2203"
        x="0"
        y="0"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2"
          operator="erode"
          in="SourceAlpha"
          result="effect1_innerShadow_1_2203"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1_2203"
        />
      </filter>
      <filter
        id="filter1_i_1_2203"
        x="6"
        y="6"
        width="20"
        height="20"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2"
          operator="erode"
          in="SourceAlpha"
          result="effect1_innerShadow_1_2203"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3.2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1_2203"
        />
      </filter>
    </defs>
  </svg>,
  'RadioChecked'
);
