import { Box, IconButton, Stack, Typography } from '@mui/material';
import { cryptoGrey, cryptoPrimary } from '../theme/colors';
import { Asset } from '../models/paymentData';
import findImage from '../content/images';
import { useContext, useMemo } from 'react';
import { PaymentDataContext } from '../context/PaymentData';

interface Props {
  asset: Asset;
  handleAddToken: (token: Asset) => void;
}

export default function AddTokenItem({ asset, handleAddToken }: Props) {
  const paymentDataCtx = useContext(PaymentDataContext);

  const dataImage = useMemo(() => {
    const icon = paymentDataCtx.paymentData?.walletList.find(
      (a) => a.symbol === asset.symbol
    )?.icon;
    if (icon) {
      return icon;
    } else {
      return findImage(asset.symbol);
    }
  }, [asset, paymentDataCtx.paymentData]);

  const handleTokenClick = () => {
    handleAddToken(asset);
  };

  return (
    <Box
      component={'div'}
      sx={{
        height: '55px',
        margin: '5px',
        marginBottom: '2.5px'
      }}
    >
      <Stack
        direction={'row'}
        onClick={handleTokenClick}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: cryptoPrimary[50],
          borderRadius: '13px',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: cryptoGrey[100]
          }
        }}
      >
        <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
          <IconButton
            disableRipple
            sx={{
              p: '10px',
              borderRadius: '13px',
              '&:hover': {
                backgroundColor: 'inherit'
              }
            }}
          >
            <img
              style={{
                width: 35,
                height: 35
              }}
              src={dataImage}
              alt={asset.symbol}
            />
          </IconButton>
          <Stack
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'center',
              color: 'black'
            }}
          >
            <Typography sx={{ fontSize: '1.125rem' }}>
              {asset.symbol}
            </Typography>
            <Typography sx={{ fontSize: '0.875rem' }}>{asset.name}</Typography>
          </Stack>
        </Stack>
        {asset.type_currency === 'CONTRACT' && (
          <Typography sx={{ p: '10px', color: cryptoGrey[50] }}>
            {asset.blockchain}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
