import { createSvgIcon } from '@mui/material/utils';

export const BluePlus = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g>
      <path d="M17 11H11V17H7V11H1V7H7V1H11V7H17V11Z" fill="#329DFF" />
    </g>
  </svg>,
  'BluePlus'
);
