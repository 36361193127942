import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import { languages } from '../content/languages';
import { useContext, useEffect, useState } from 'react';
import en from '../assets/englang.svg';
import fr from '../assets/franchlang.svg';
import de from '../assets/germlang.svg';
import it from '../assets/itallang.svg';
import es from '../assets/esplang.svg';
import ru from '../assets/ruslang.svg';
import { cryptoPrimary } from '../theme/colors';
import checkbox from '../assets/button_checkbox_active.svg';
import { LanguageContext } from '../context/Language';

const images = {
  en: en,
  fr: fr,
  de: de,
  it: it,
  es: es,
  ru: ru
};

const menuItemStyle = {
  display: 'flex',
  alignItems: 'center',
  py: '0.625rem',
  cursor: 'pointer',
  '&:active, &:hover': {
    background: cryptoPrimary[600]
  }
} as const;

export default function Language() {
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedId, setSelectedId] = useState<string>(language.id);
  const [langImg, setLangImg] = useState<string>(en);

  useEffect(() => {
    Object.entries(images).find(([k, v]) => {
      if (k === selectedId) {
        setLangImg(v);
        return true;
      } else {
        return false;
      }
    });
  }, [selectedId]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
    setSelectedId(event.currentTarget.id);
    if (language.id !== event.currentTarget.id) {
      languageCtx.setLang(event.currentTarget.id);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          mt: '53px',
          ml: { sm: '68px', xs: 0 },
          alignSelf: 'flex-start',
          position: 'relative'
        }}
      >
        <Tooltip title={language.translation.enterWindow.changeLanguage}>
          <IconButton disableRipple
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'language-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <img
              style={{
                width: 32,
                height: 32,
                color: '#252F6C',
                filter: 'drop-shadow(2px 2px)'
              }}
              src={langImg}
              alt={language.name}
            />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          id="language-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 10,
            sx: {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '275px',
              color: 'white',
              bgcolor: cryptoPrimary[700],
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: cryptoPrimary[700],
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          {languages.map((language) => {
            let image;
            Object.entries(images).find(([k, v]) => {
              if (k === language.id) {
                image = v;
                return true;
              } else {
                return false;
              }
            });
            return (
              <MenuItem
                key={language.id}
                id={language.id}
                selected={language.id === selectedId}
                onClick={(event) => handleMenuItemClick(event)}
                sx={menuItemStyle}
              >
                <img
                  style={{
                    width: 32,
                    height: 32,
                    color: '#252F6C',
                    filter: 'drop-shadow(2px 2px)'
                  }}
                  src={image}
                  alt={language.name}
                />{' '}
                <Typography
                  component={'h5'}
                  sx={{ fontSize: '1.5rem', pl: '1.5rem' }}
                >
                  {language.name}
                </Typography>
                {language.id === selectedId ? (
                  <img
                    style={{
                      width: 24,
                      height: 24,
                      marginLeft: 'auto'
                    }}
                    src={checkbox}
                    alt={language.name}
                  />
                ) : null}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    </>
  );
}
