import {
  cryptoGrey,
  cryptoPositive,
  cryptoPrimary,
  cryptoSecondary
} from './colors';
import background from '../assets/background.svg';
import appBackground from '../assets/appbackground.svg';

export const welcomeBackgroundStyle = {
  position: 'relative',
  background: 'linear-gradient(6deg, #0A2545 -17.28%, #14386E 87.3%)'
};

export const appBackgroundStyle = {
  //position: 'fixed',
  // top: 0,
  // bottom: 0,
  // left: 0,
  // right: 0,
  //maxHeight: '-webkit-fill-available',
  height: '100%',
  //maxHeight: '100dvh',
  backgroundPosition: '-350px 0px',
  backgroundSize: '700px 100%',
  backgroundColor: cryptoSecondary[400],
  backgroundImage: `url("${appBackground}")`,
  overflow: 'hidden'
};

export const pageStyle = {
  background: `url(${background})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100%',
  height: '100dvh',
  maxWidth: '704px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between'
} as const;

export const containerStyle = {
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white'
} as const;

export const errorStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '3rem',
  color: '#F33'
};

export const paperStyle = {
  background: cryptoPrimary[50],
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  position: 'relative',
  width: '100%',
  height: '100dvh',
  maxWidth: '704px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start'
} as const;

export const listStyle = {
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  height: '100%',
  overflowY: 'scroll',
  scrollbarWidth: 'none', // Hide the scrollbar for firefox
  '&::-webkit-scrollbar': {
    display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
  },
  '&-ms-overflow-style:': {
    display: 'none' // Hide the scrollbar for IE
  }
} as const;

export const positiveButtonStyle = {
  '&, &:link, &.visited': {
    background: `linear-gradient(90deg, ${cryptoPositive[800]}, ${cryptoPositive[900]})`,
    boxShadow: 'none'
  },
  '&:hover': {
    background: `linear-gradient(90deg, ${cryptoPositive[700]}, ${cryptoPositive[900]})`,
    boxShadow: 'none'
  },
  '&:active': {
    background: `linear-gradient(90deg, ${cryptoPositive[600]}, ${cryptoPositive[900]})`,
    boxShadow: 'none'
  },
  '&.Mui-disabled': {
    background: `linear-gradient(90deg, ${cryptoSecondary[400]}, ${cryptoSecondary[500]})`,
    boxShadow: 'none'
  }
} as const;

export const secondaryButtonStyle = {
  '&, &:link, &.visited': {
    background: `linear-gradient(90deg, ${cryptoSecondary[800]}, ${cryptoSecondary[900]})`,
    boxShadow: 'none'
  },
  '&:hover': {
    background: `linear-gradient(90deg, ${cryptoSecondary[700]}, ${cryptoSecondary[900]})`,
    boxShadow: 'none'
  },
  '&:active': {
    background: `linear-gradient(90deg, ${cryptoSecondary[600]}, ${cryptoSecondary[900]})`,
    boxShadow: 'none'
  },
  '&.Mui-disabled': {
    background: `linear-gradient(90deg, ${cryptoSecondary[400]}, ${cryptoSecondary[500]})`,
    boxShadow: 'none'
  }
} as const;

export const logoButtonStyle = {
  '&, &:link, &.visited': {
    height: '50px',
    backgroundColor: cryptoPrimary[900],
    p: '9px',
    borderBottomLeftRadius: '13px',
    borderBottomRightRadius: '13px',

    boxShadow: `2px 2px 2px black`,
    transition: 'all .2s'
  },
  '&:hover': {
    backgroundColor: cryptoPrimary[900],
    boxShadow: '2px 5px 5px black',
    transform: 'translateY(-3px)'
  },
  '&:active, &:focus': {
    boxShadow: `2px 3px 2px black`,
    transform: 'translateY(-1px)'
  }
};
