import { Box, IconButton, Stack, Typography } from '@mui/material';
import { cryptoGrey, cryptoPrimary } from '../theme/colors';
import { Address } from '../models/address';
import { useContext } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import blueArrowIcon from '../assets/bluearrow.svg';
import api from '../api/api';
import AddressText from './AddressText';

interface Props {
  address: Address;
  setAddress: (address: Address) => void;
}

export default function AddressItem({ address, setAddress }: Props) {
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: address.accounts[0].fiat_currency || 'USD'
  });
  let numberFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 20
  });
  const [symbolBallance, ...othersBallance] = USDollar.formatToParts(
    address.accounts[0].fiat_balance
  );
  const fiatBalanceString = `${symbolBallance.value} ${othersBallance
    .map((v) => v.value)
    .join('')}`;
  const balanceString = numberFormat.format(
    address.accounts[0].decimal_balance
  );

  const handleAddressClick = () => {
    setAddress(address);
  };

  return (
    <Box
      component={'div'}
      sx={{
        height: '85px',
        mx: '2px',
        my: '1px'
      }}
    >
      <Stack
        onClick={handleAddressClick}
        sx={{
          height: '100%',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          backgroundColor: cryptoGrey[900],
          borderRadius: '13px',
          py: '10px',
          px: '15px',
          cursor: 'pointer'
        }}
      >
        <Stack
          direction={'row'}
          sx={{
            justifyContent: 'space-between',
            color: 'rgba(0, 0, 0, 0.50)',
            fontSize: '0.875rem'
          }}
        >
          <Typography
            sx={{
              lineHeight: 1
            }}
          >
            {address.blockchain}
          </Typography>
          <Typography sx={{ color: 'rgba(0, 0, 0, 0.50)', lineHeight: 1 }}>
            {address.hd_path}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            color: 'white'
          }}
        >
          <Typography>{fiatBalanceString}</Typography>
          <Stack direction={'row'} sx={{ alignItems: 'center' }}>
            <Typography sx={{ pr: '5px', lineHeight: 1 }}>
              {balanceString}
            </Typography>
            <Typography
              sx={{
                lineHeight: 1
              }}
            >
              {address.accounts[0].symbol}
            </Typography>
            <IconButton
              disableRipple
              sx={{
                p: 0,
                pl: '10px',
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: 'inherit'
                }
              }}
            >
              <img
                style={{
                  height: 16
                }}
                src={blueArrowIcon}
                alt={language.translation.buttons.addAddress}
              />
            </IconButton>
          </Stack>
        </Stack>
        <AddressText
          address={address.address}
          fontHeight="0.875rem"
          lineHeight="1rem"
          stringLen={280}
          color={cryptoPrimary[200]}
        />
      </Stack>
    </Box>
  );
}
