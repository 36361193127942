import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Asset } from '../models/paymentData';
import dash from '../assets/dash.svg';
import ltc from '../assets/ltc.svg';
import usdt from '../assets/usdt.svg';
import ada from '../assets/ada.svg';
import eth from '../assets/eth.svg';
import btc from '../assets/btc.svg';
import trid from '../assets/trid.svg';
import bnb from '../assets/bnb.svg';
import doge from '../assets/doge.svg';
import { cryptoGrey, cryptoPrimary } from '../theme/colors';
import api from '../api/api';
import { useNavigate } from 'react-router-dom';
import { EventMessage } from '../models/eventMessage';
import { useContext, useEffect, useMemo, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { UserContext } from '../context/User';
import { PaymentDataContext } from '../context/PaymentData';

interface Props {
  asset: Asset;
  walletId: string;
  eventMessage: EventMessage | null;
  setEventMessage: React.Dispatch<React.SetStateAction<EventMessage | null>>;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  setInvoice: React.Dispatch<React.SetStateAction<Asset | null>>;
}

const images = {
  DASH: dash,
  LTC: ltc,
  USDT: usdt,
  ADA: ada,
  ETH: eth,
  BTC: btc,
  TRID: trid,
  BNB: bnb,
  DOGE: doge
};

export default function CoinItem({
  asset,
  walletId,
  eventMessage,
  setEventMessage,
  setActive,
  setUpdated,
  setInvoice
}: Props) {
  const userCtx = useContext(UserContext);
  const paymentDataCtx = useContext(PaymentDataContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [added, setAdded] = useState(false);
  let navigate = useNavigate();
  let image = '';
  Object.entries(images).find(([k, v]) => {
    if (k === asset.symbol) {
      image = v;
      return true;
    } else {
      return false;
    }
  });

  const dataImage = useMemo(() => {
    const icon = paymentDataCtx.paymentData?.walletList.find(
      (a) => a.symbol === asset.symbol
    )?.icon;
    if (icon) {
      return icon;
    } else {
      return image;
    }
  }, [asset, paymentDataCtx.paymentData]);

  useEffect(() => {
    if (added && !eventMessage) {
      setActive(false);
    }
  }, [added, eventMessage]);

  const handleCoinClick = () => {
    if (asset.type_currency === 'CONTRACT') {
      setInvoice(asset);
    } else {
      api.address
        .create(walletId, {
          blockchain: asset.blockchain,
          standard: asset.hdPathStandards[0]
        })
        .then((response) => {
          //console.log(response);
          if (response.data) {
            userCtx.setUpdated(false);
            setEventMessage({
              status: response.status,
              message: language.translation.windowText.assetAdded,
              positive: true
            });
            setAdded(true);
            setUpdated(false);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            navigate('../../login');
          } else if (er.response.data) {
            setEventMessage({
              status: er.response.status,
              message: er.response.data.message
                ? er.response.data.message
                : er.message,
              positive: false
            });
          } else {
            setEventMessage({
              status: er.response.status ? er.response.status : 0,
              message: er.message,
              positive: false
            });
          }
        });
    }
  };

  return (
    <Box
      component={'div'}
      sx={{
        height: '55px',
        margin: '5px',
        marginBottom: '2.5px'
      }}
    >
      <Stack
        direction={'row'}
        onClick={handleCoinClick}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: cryptoPrimary[50],
          borderRadius: '13px',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: cryptoGrey[100]
          }
        }}
      >
        <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
          <IconButton
            disableRipple
            sx={{
              p: '10px',
              borderRadius: '13px',
              '&:hover': {
                backgroundColor: 'inherit'
              }
            }}
          >
            <img
              style={{
                width: 35,
                height: 35
              }}
              src={dataImage}
              alt={asset.symbol}
            />
          </IconButton>
          <Stack
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'center',
              color: 'black'
            }}
          >
            <Typography sx={{ fontSize: '1.125rem' }}>
              {asset.symbol}
            </Typography>
            <Typography sx={{ fontSize: '0.875rem' }}>{asset.name}</Typography>
          </Stack>
        </Stack>
        {asset.type_currency === 'CONTRACT' && (
          <Typography sx={{ p: '10px', color: cryptoGrey[50] }}>
            {asset.blockchain}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
