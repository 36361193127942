import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { listStyle, paperStyle } from '../theme/styles';
import HeaderRight from '../components/HeaderRight';
import { cryptoGrey, cryptoPrimary, cryptoSecondary } from '../theme/colors';
import { Transfer, TransferFilterParams } from '../models/transfer';
import downloadIcon from '../assets/download_report.svg';
import { useContext, useEffect, useRef, useState } from 'react';
import api from '../api/api';
import { useNavigate } from 'react-router-dom';
import TransferItem from '../components/TransferItem';
import { UserContext } from '../context/User';
import { getTableDataForExport, makeCsv } from '../utils/makecsv';
import { format, parseISO } from 'date-fns';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import PaginationType from '../models/pagination';
import { PAGESIZE } from '../content/constants';
import Pagination from '../components/Pagination';

interface Props {
  setActive: () => void;
  title: string;
  walletId: string;
  filter: TransferFilterParams;
  newHistory: boolean;
  setNewHistory: React.Dispatch<React.SetStateAction<boolean>>;
}

const itemStyle = {
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: cryptoPrimary[50],
  color: 'black'
} as const;

const defaultPagination: PaginationType = {
  size: PAGESIZE
};

export default function TransactionHistory({
  setActive,
  title,
  walletId,
  filter,
  newHistory,
  setNewHistory
}: Props) {
  const userCtx = useContext(UserContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [transactions, setTransactions] = useState<Transfer[]>([]);
  const [pagination, setPagination] =
    useState<PaginationType>(defaultPagination);
  let navigate = useNavigate();
  const historyListRef = useRef<HTMLElement>(null);
  const [scrollTop, setScrollTop] = useState(false);

  const columns = [
    { Header: 'date', accessor: 'date' },
    { Header: 'operation_type', accessor: 'operation_type' },
    { Header: 'blockchain', accessor: 'blockchain' },
    { Header: 'contract_address', accessor: 'contract_address' },
    { Header: 'hash', accessor: 'hash' },
    { Header: 'value', accessor: 'value' },
    { Header: 'decimal_value', accessor: 'decimal_value' },
    { Header: 'decimal_fees', accessor: 'decimal_fees' },
    { Header: 'type', accessor: 'type' },
    { Header: 'confirmations', accessor: 'confirmations' },
    { Header: 'inputs', accessor: 'inputs' },
    { Header: 'outputs', accessor: 'outputs' }
  ];

  useEffect(() => {
    if (newHistory) {
      const params = new URLSearchParams();
      if (filter.blockchain) {
        params.append('blockchain', filter.blockchain);
      }
      if (filter.contract) {
        params.append('contract', filter.contract);
      }
      if (filter.from) {
        params.append('from_date', filter.from);
      }
      if (filter.to) {
        params.append('to_date', filter.to);
      }
      if (filter.type) {
        params.append('type', filter.type);
      }
      if (pagination.page) {
        params.append('page', pagination.page.toString());
      }
      if (pagination.size) {
        params.append('size', pagination.size.toString());
      }
      api.history
        .list(walletId, params)
        .then((response) => {
          //console.log(response);
          if (response) {
            setTransactions(response);
            setScrollTop(true);
            setPagination({
              ...pagination,
              from_hash:
                pagination.size && response.length < pagination.size
                  ? undefined
                  : response[response.length - 1].hash
            });
            setNewHistory(false);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    }
  }, [newHistory]);

  useEffect(() => {
    if (scrollTop && historyListRef.current) {
      historyListRef.current.scrollTo({ top: 0 });
      setScrollTop(false);
    }
  }, [scrollTop]);

  const handleNextHistoryPage = () => {
    if (pagination.from_hash) {
      const params = new URLSearchParams();
      if (filter.blockchain) {
        params.append('blockchain', filter.blockchain);
      }
      if (filter.contract) {
        params.append('contract', filter.contract);
      }
      if (filter.from) {
        params.append('from_date', filter.from);
      }
      if (filter.to) {
        params.append('to_date', filter.to);
      }
      if (filter.type) {
        params.append('type', filter.type);
      }
      params.append('from_hash', pagination.from_hash);
      if (pagination.page) {
        params.append('page', pagination.page.toString());
      }
      if (pagination.size) {
        params.append('size', pagination.size.toString());
      }
      api.history
        .list(walletId, params)
        .then((response) => {
          //console.log(response);
          if (response) {
            let newItems = [...response];
            if (!pagination.page) {
              newItems.shift();
            }
            setTransactions([...transactions, ...newItems]);
            setPagination({
              ...pagination,
              page: pagination.page ? pagination.page + 1 : 1,
              from_hash:
                pagination.size && response.length < pagination.size
                  ? undefined
                  : pagination.from_hash
            });
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    }
  };

  const handleBackClick = () => {
    setActive();
    setPagination(defaultPagination);
  };

  const handleDownloadClick = () => {
    const dateString = format(Date.now(), 'yyyy-MM-dd_HH-mm');
    makeCsv(
      getTableDataForExport(
        transactions.map((t) => ({
          ...t,
          date: format(parseISO(t.date), 'yyyy.MM.dd HH:mm:ss')
        })),
        columns
      ),
      `transactions_${dateString}.csv`
    );
  };

  return (
    <Paper
      elevation={0}
      square
      sx={[
        paperStyle,
        {
          marginLeft: { xs: 'auto', sm: 0 },
          height: '100%',
          position: 'relative'
        }
      ]}
    >
      <HeaderRight title={title} setActive={handleBackClick} />
      <Box
        ref={historyListRef}
        component="div"
        sx={[
          listStyle,
          {
            backgroundColor: cryptoSecondary[50],
            height: '100%',
            overflowY: 'scroll'
          }
        ]}
      >
        {transactions.length > 0 && (
          <Box
            component={'div'}
            sx={{
              height: '60px',
              minHeight: '60px',
              padding: '1px',
              paddingTop: 0,
              backgroundColor: cryptoSecondary[50]
            }}
          >
            <Stack direction={'row'} sx={itemStyle}>
              <Typography sx={{ minWidth: '100px', px: '15px' }}>
                Download the Report
              </Typography>
              <Tooltip title={language.translation.historyWindow.report}>
                <IconButton
                  disableRipple
                  onClick={handleDownloadClick}
                  sx={{
                    p: '20px',
                    borderRadius: 0,
                    '&:hover': {
                      backgroundColor: cryptoGrey[100]
                    }
                  }}
                >
                  <img
                    style={{
                      width: 18
                    }}
                    src={downloadIcon}
                    alt={language.translation.historyWindow.report}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
        )}
        {transactions.length > 0 && (
          <>
            {transactions.map((t) => (
              <TransferItem key={t.date} transfer={t} symbol="" />
            ))}
            {pagination.from_hash && (
              <Pagination handleNextPage={handleNextHistoryPage} />
            )}
          </>
        )}
      </Box>
    </Paper>
  );
}
