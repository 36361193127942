import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
  } from '@mui/material';
  import { ConfirmMessage } from '../models/confirmMessage';
  import { cryptoPrimary } from '../theme/colors';
  import { useContext } from 'react';
  import { LanguageContext } from '../context/Language';
  import { languages } from '../content/languages';
  import { positiveButtonStyle, secondaryButtonStyle } from '../theme/styles';
  
  interface Props {
    isOpen: boolean;
    setOpen: React.Dispatch<React.SetStateAction<string | null>>;
    message: string;
    handleConfirm: () => void;
  }
  
  const labelStyle = {
    color: 'black',
    fontWeight: '500'
  };
  
  const valueStyle = {
    color: cryptoPrimary[900]
  };
  
  export default function RedirectDialog({
    isOpen,
    setOpen,
    message,
    handleConfirm
  }: Props) {
    const languageCtx = useContext(LanguageContext);
    const language = languageCtx.language ? languageCtx.language : languages[0];
  
    const handleBack = () => {
      setOpen(null);
    };
  
    return (
      <Dialog
        open={isOpen}
        onClose={handleBack}
        aria-labelledby="redirect-dialog-title"
        aria-describedby="redirect-dialog-description"
        PaperProps={{
          style: {
            alignItems: 'center',
            backgroundColor: cryptoPrimary[50],
            borderRadius: '10px',
            minHeight: '230px',
            minWidth: '320px'
          }
        }}
      >
        <DialogContent sx={{ textAlign: 'center', p: '30px', paddingBottom: 0 }}>
        <DialogContentText
          id="redirect-dialog-description"
          sx={{ color: 'black' }}
        >
          {message}
        </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            p: '1.5rem',
            flexDirection: 'column',
            gap: '20px'
          }}
        >
          <Button
            sx={[secondaryButtonStyle, { color: 'white' }]}
            onClick={handleBack}
            autoFocus
          >
            {language.translation.mainButton.back}
          </Button>
          <Button
            sx={[positiveButtonStyle, { color: 'white' }]}
            onClick={handleConfirm}
            autoFocus
          >
            {language.translation.mainButton.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  