import { Box, Stack, Typography } from '@mui/material';
import logo from '../assets/logo.svg';
import { cryptoPrimary } from '../theme/colors';
import '@fontsource-variable/rubik';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  size: number;
  cursorStyle: string;
}

export default function Logo({ size, cursorStyle }: Props) {
  const titleStyle = useMemo(
    () => ({
      color: '#FFF',
      fontSize: `${size / 54.5}rem`,
      textAlign: 'center',
      fontFamily: 'Rubik Variable',
      fontWeight: 700,
      fontStyle: 'normal',
      zIndex: 2,
      userSelect: 'none',
      cursor: `${cursorStyle}`,
      mt: `${size / 187.5}rem`,
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        zIndex: -1,
        '-webkit-text-stroke-width': `${size / 18.75}px`,
        '-webkit-text-stroke-color': cryptoPrimary[500]
      }
    }),
    [size, cursorStyle]
  );

  let navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const handleClickLogo = () => {
    if (pathname !== '/login') {
      navigate('login');
    }
  };

  return (
    <Stack>
      <Box
        component="img"
        sx={{ height: `${size}px`, cursor: `${cursorStyle}` }}
        src={logo}
        alt="ApiForCrypto logo"
        onClick={handleClickLogo}
      />
      <Typography
        data-text="ApiForCrypto"
        sx={titleStyle}
        onClick={handleClickLogo}
      >
        ApiForCrypto
      </Typography>
    </Stack>
  );
}
