export interface Transfer {
  date: string;
  operation_type: string;
  blockchain: string;
  contract_address: string;
  hash: string;
  value: number;
  decimal_value: number;
  fees: number;
  decimal_fees: number;
  type: string;
  confirmations: number;
  inputs: string[];
  outputs: string[];
  symbol: string;
}

export interface CreateTXRequest {
  blockchain: string;
  contract_address: string;
  seed_hash: string;
  addresses: string[];
  outputs: Output[];
}

export interface Output {
  address: string;
  value: number;
}

export interface CreateTXResponse {
  hash: string;
  fees: number;
  inputs: Input[];
  outputs: Output[];
  hex: string;
  vsize: number;
}

export interface Input {
  address: string | null;
  prev_hash: string;
  output_index: number;
  output_value: number;
}

export interface SendTXRequest {
  blockchain: string;
  contract_address: string;
  hex: string;
}

export interface TransferFilterParams {
  blockchain?: string;
  contract?: string;
  from?: string;
  to?: string;
  type?: TransferType;
}

export enum TransferType {
  IN = 'in',
  OUT = 'out'
}
