import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { positiveButtonStyle, secondaryButtonStyle } from '../theme/styles';
import { cryptoPrimary } from '../theme/colors';
import { EventMessage } from '../models/eventMessage';
import { useContext } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import copyClip from '../assets/copyclip.svg';
import { InsufficientMessage } from '../models/insufficientMessage';
import { PayMessage } from '../models/payMessage';

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<EventMessage | null>>;
  positive: boolean;
  message: string | null;
  mnemonic?: string;
  insufficientMessage?: InsufficientMessage;
  payMessage?: PayMessage;
}

const labelStyle = {
  color: 'black',
  fontWeight: '500',
  pr: '5px'
};

export default function EventDialog({
  isOpen,
  setOpen,
  positive,
  message,
  mnemonic,
  insufficientMessage,
  payMessage
}: Props) {
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const handleClose = () => {
    setOpen(null);
  };

  const handleCopyClick = () => {
    if (mnemonic) {
      navigator.clipboard.writeText(mnemonic);
    } else if (payMessage) {
      navigator.clipboard.writeText(payMessage.payString);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          alignItems: 'center',
          backgroundColor: cryptoPrimary[50],
          borderRadius: '10px',
          minHeight: '230px',
          width: '320px'
        }
      }}
    >
      <DialogContent sx={{ textAlign: 'center', p: '30px', paddingBottom: 0 }}>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ color: 'black' }}
        >
          {message}
        </DialogContentText>
        {payMessage && (
          <>
            <Tooltip title={'Copy to clipboard'}>
              <IconButton disableRipple
                onClick={handleCopyClick}
                sx={{ p: '11px', borderRadius: 0 }}
              >
                <img
                  style={{
                    width: 24,
                    height: 24
                  }}
                  src={copyClip}
                  alt={'Copy to clipboard'}
                />
              </IconButton>
            </Tooltip>
            <Typography>{payMessage.dataString}</Typography>
          </>
        )}
        {mnemonic && (
          <>
            <Stack
              direction={'row'}
              sx={{
                color: 'black',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '2rem'
              }}
            >
              <Typography>mnemonic:</Typography>
              <Tooltip title={'Copy to clipboard'}>
                <IconButton disableRipple
                  onClick={handleCopyClick}
                  sx={{ p: '11px', borderRadius: 0 }}
                >
                  <img
                    style={{
                      width: 24,
                      height: 24
                    }}
                    src={copyClip}
                    alt={'Copy to clipboard'}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
            <Typography>{mnemonic}</Typography>
          </>
        )}
        {insufficientMessage && (
          <>
            <Typography sx={[labelStyle, { py: '3px', pb: '1.75rem' }]}>
              {insufficientMessage.funds}
            </Typography>
            <Stack
              direction={'row'}
              sx={{ justifyContent: 'center', py: '3px' }}
            >
              <Typography sx={labelStyle}>
                {language.translation.windowText.totalAmmount}
              </Typography>
              <Typography>{insufficientMessage.amount}</Typography>
            </Stack>
            <Stack
              direction={'row'}
              sx={{ justifyContent: 'center', py: '3px' }}
            >
              <Typography sx={labelStyle}>
                {language.translation.windowText.payOut}
              </Typography>
              <Typography>{insufficientMessage.payout}</Typography>
            </Stack>
            <Stack
              direction={'row'}
              sx={{ justifyContent: 'center', py: '3px' }}
            >
              <Typography sx={labelStyle}>
                {language.translation.windowText.networkFee}
              </Typography>
              <Typography>{insufficientMessage.fee}</Typography>
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', p: '1.5rem' }}>
        <Button
          sx={positive ? positiveButtonStyle : secondaryButtonStyle}
          onClick={handleClose}
          autoFocus
        >
          {language.translation.mainButton.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
