import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { listStyle, paperStyle } from '../theme/styles';
import HeaderRight from '../components/HeaderRight';
import { cryptoGrey, cryptoPrimary, cryptoSecondary } from '../theme/colors';
import { useContext, useEffect, useRef, useState } from 'react';
import downloadIcon from '../assets/download_report.svg';
import { Invoice, InvoiceFilterParams } from '../models/invoice';
import api from '../api/api';
import { UserContext } from '../context/User';
import { useNavigate } from 'react-router-dom';
import InvoiceItem from '../components/InvoiceItem';
import { format, parseISO } from 'date-fns';
import { getTableDataForExport, makeCsv } from '../utils/makecsv';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import PaginationType from '../models/pagination';
import { PAGESIZE } from '../content/constants';
import Pagination from '../components/Pagination';

interface Props {
  setActive: () => void;
  title: string;
  walletId: string;
  filter: InvoiceFilterParams;
  invoiceId: string | null;
  tariffPay: boolean;
  newHistory: boolean;
  setNewHistory: React.Dispatch<React.SetStateAction<boolean>>;
}

const itemStyle = {
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: cryptoPrimary[50],
  color: 'black'
} as const;

const defaultPagination: PaginationType = {
  size: PAGESIZE
};

export default function InvoiceHistory({
  setActive,
  title,
  walletId,
  filter,
  invoiceId,
  tariffPay,
  newHistory,
  setNewHistory
}: Props) {
  const userCtx = useContext(UserContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [pagination, setPagination] =
    useState<PaginationType>(defaultPagination);
  let navigate = useNavigate();
  const invoiceHistoryListRef = useRef<HTMLElement>(null);
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    if (newHistory) {
      if (invoiceId) {
        api.wallet
          .invoice(invoiceId)
          .then((response) => {
            //console.log(response);
            if (response) {
              setInvoices([response]);
              setNewHistory(false);
            }
          })
          .catch((er) => {
            //console.log(er);
            if (er.response.status === 401) {
              userCtx.setUser(null);
              navigate('../../login');
            }
          });
      } else {
        const params = new URLSearchParams();
        if (filter.blockchain) {
          params.append('blockchain', filter.blockchain);
        }
        if (filter.address) {
          params.append('address', filter.address);
        }
        if (filter.contract) {
          params.append('contract', filter.contract);
        }
        if (filter.status) {
          params.append('status', filter.status);
        }
        if (filter.active) {
          params.append('active', filter.active ? 'true' : 'false');
        }
        if (filter.order_id) {
          params.append('order_id', filter.order_id);
        }
        if (tariffPay) {
          if (filter.from) {
            params.append('from', filter.from);
          }
          if (filter.to) {
            params.append('to', filter.to);
          }
          api.wallet
            .invoicesTariffPay(walletId, params)
            .then((response) => {
              //console.log(response);
              if (response) {
                setInvoices(response);
                setNewHistory(false);
              }
            })
            .catch((er) => {
              //console.log(er);
              if (er.response.status === 401) {
                userCtx.setUser(null);
                navigate('../../login');
              }
            });
        } else {
          if (filter.from) {
            params.append('from_date', filter.from);
          }
          if (filter.to) {
            params.append('to_date', filter.to);
          }
          if (pagination.page) {
            params.append('page', pagination.page.toString());
          }
          if (pagination.size) {
            params.append('size', pagination.size.toString());
          }
          api.wallet
            .invoices(walletId, params)
            .then((response) => {
              //console.log(response);
              if (response) {
                // if (filter.status === 'Cancelled') {
                //   const filtered = response.filter(
                //     (invoice) => parseISO(invoice.expiry) < parseISO(invoice.created)
                //   );
                //   setInvoices(filtered);
                // } else {
                setInvoices(response);
                setScrollTop(true);
                setPagination({
                  ...pagination,
                  from_id:
                    pagination.size && response.length < pagination.size
                      ? undefined
                      : response[response.length - 1].id
                });
                setNewHistory(false);
                // }
              }
            })
            .catch((er) => {
              //console.log(er);
              if (er.response.status === 401) {
                userCtx.setUser(null);
                navigate('../../login');
              }
            });
        }
      }
    }
  }, [newHistory]);

  useEffect(() => {
    if (scrollTop && invoiceHistoryListRef.current) {
      invoiceHistoryListRef.current.scrollTo({ top: 0 });
      setScrollTop(false);
    }
  }, [scrollTop]);

  const handleNextInvoicePage = () => {
    if (pagination.from_id) {
      const params = new URLSearchParams();
      if (filter.blockchain) {
        params.append('blockchain', filter.blockchain);
      }
      if (filter.address) {
        params.append('address', filter.address);
      }
      if (filter.contract) {
        params.append('contract', filter.contract);
      }
      if (filter.from) {
        params.append('from_date', filter.from);
      }
      if (filter.to) {
        params.append('to_date', filter.to);
      }
      if (filter.status) {
        params.append('status', filter.status);
      }
      if (filter.active) {
        params.append('active', filter.active ? 'true' : 'false');
      }
      if (filter.order_id) {
        params.append('order_id', filter.order_id);
      }
      params.append('from_id', pagination.from_id);
      if (pagination.page) {
        params.append('page', pagination.page.toString());
      }
      if (pagination.size) {
        params.append('size', pagination.size.toString());
      }
      api.wallet
        .invoices(walletId, params)
        .then((response) => {
          //console.log(response);
          if (response) {
            let newItems = [...response];
            if (!pagination.page) {
              newItems.shift();
            }
            setInvoices([...invoices, ...newItems]);
            setPagination({
              ...pagination,
              page: pagination.page ? pagination.page + 1 : 1,
              from_id:
                pagination.size && response.length < pagination.size
                  ? undefined
                  : pagination.from_id
            });
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    }
  };

  const columns = [
    { Header: 'id', accessor: 'id' },
    { Header: 'blockchain', accessor: 'blockchain' },
    { Header: 'symbol', accessor: 'symbol' },
    { Header: 'contract_address', accessor: 'contract_address' },
    { Header: 'active', accessor: 'active' },
    { Header: 'value', accessor: 'value' },
    { Header: 'created', accessor: 'created' },
    { Header: 'expiry', accessor: 'expiry' },
    { Header: 'status', accessor: 'status' },
    { Header: 'confirmations', accessor: 'confirmations' },
    { Header: 'fiat_currency.currency', accessor: 'fiat_currency_currency' },
    { Header: 'fiat_currency.value', accessor: 'fiat_currency_value' },
    { Header: 'fiat_currency.percent', accessor: 'fiat_currency_percent' },
    { Header: 'fiat_currency.rate', accessor: 'fiat_currency_rate' },
    { Header: 'txs', accessor: 'txs' },
    { Header: 'data.order_id', accessor: 'data_order_id' },
    { Header: 'data.title', accessor: 'data_title' },
    { Header: 'data.description', accessor: 'data_description' },
    { Header: 'data.user_data', accessor: 'data_user_data' }
  ];

  const handleBackClick = () => {
    setActive();
    setPagination(defaultPagination);
  };

  const handleDownloadClick = () => {
    const dateString = format(Date.now(), 'yyyy-MM-dd_HH-mm');
    makeCsv(
      getTableDataForExport(
        invoices.map((i) => ({
          ...i,
          created: format(parseISO(i.created), 'yyyy.MM.dd HH:mm:ss'),
          expiry: format(parseISO(i.expiry), 'yyyy.MM.dd HH:mm:ss'),
          fiat_currency_currency: i.fiat_currency.currency,
          fiat_currency_value: i.fiat_currency.value,
          fiat_currency_percent: i.fiat_currency.percent,
          fiat_currency_rate: i.fiat_currency.rate,
          data_order_id: i.data?.order_id,
          data_title: i.data?.title,
          data_description: i.data?.description,
          data_user_data: JSON.stringify(i.data?.user_data)
        })),
        columns
      ),
      `invoices_${dateString}.csv`
    );
  };

  return (
    <Paper
      elevation={0}
      square
      sx={[
        paperStyle,
        {
          marginLeft: { xs: 'auto', sm: 0 },
          height: '100%',
          position: 'relative'
        }
      ]}
    >
      <HeaderRight title={title} setActive={handleBackClick} />
      <Box
        ref={invoiceHistoryListRef}
        component="div"
        sx={[
          listStyle,
          {
            backgroundColor: cryptoSecondary[50],
            height: '100%',
            overflowY: 'scroll'
          }
        ]}
      >
        {invoices.length > 0 && (
          <Box
            component={'div'}
            sx={{
              height: '60px',
              minHeight: '60px',
              padding: '1px',
              paddingTop: 0,
              backgroundColor: cryptoSecondary[50]
            }}
          >
            <Stack direction={'row'} sx={itemStyle}>
              <Typography sx={{ minWidth: '100px', px: '15px' }}>
                Download the Report
              </Typography>
              <Tooltip title={language.translation.historyWindow.report}>
                <IconButton
                  disableRipple
                  onClick={handleDownloadClick}
                  sx={{
                    p: '20px',
                    borderRadius: 0,
                    '&:hover': {
                      backgroundColor: cryptoGrey[100]
                    }
                  }}
                >
                  <img
                    style={{
                      width: 18
                    }}
                    src={downloadIcon}
                    alt={language.translation.historyWindow.report}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
        )}
        {invoices.length > 0 && (
          <>
            {invoices.map((i) => (
              <InvoiceItem key={i.id} invoice={i} />
            ))}
            {pagination.from_id && (
              <Pagination handleNextPage={handleNextInvoicePage} />
            )}
          </>
        )}
        <Box sx={{ height: '100%' }} />
      </Box>
    </Paper>
  );
}
