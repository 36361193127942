import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useContext } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import leftArrow from '../assets/bluearrowleft.svg';
import redBin from '../assets/redbin.svg';

interface Props {
  title: string;
  rightTitle?: string;
  setActive: any;
  handleHideAccountClick?: () => void;
}

const headerRightStyle = {
  justifyContent: 'space-between',
  background: 'linear-gradient(345deg, #329CFD 1.95%, #14386E 75.29%)',
  width: '100%',
  height: '60px'
} as const;

const titleStyle = {
  fontSize: '1.25rem',
  fontWeight: 500,
  userSelect: 'none',
  margin: '15px'
} as const;

export default function HeaderRight({
  title,
  rightTitle,
  setActive,
  handleHideAccountClick
}: Props) {
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];

  const handleArrowClick = () => {
    setActive(false);
  };

  return (
    <>
      <AppBar position="absolute" sx={headerRightStyle}>
        <Stack
          direction={'row'}
          sx={{
            height: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack
            direction={'row'}
            sx={{ height: '100%', alignItems: 'center' }}
          >
            <Box
              sx={{
                height: '100%',
                width: '42px',
                backgroundColor: '#15396F',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Tooltip title={language.translation.mainButton.back}>
                <IconButton disableRipple
                  onClick={handleArrowClick}
                  sx={{ px: '15px', py: '20px', borderRadius: 0 }}
                >
                  <img
                    style={{
                      width: 10,
                      height: 16
                    }}
                    src={leftArrow}
                    alt={language.translation.mainButton.back}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography sx={titleStyle}>{title}</Typography>
          </Stack>
          {handleHideAccountClick && (
            <Stack direction={'row'}>
              <Typography sx={titleStyle}>{rightTitle}</Typography>
              <IconButton disableRipple
                onClick={handleHideAccountClick}
                sx={{ px: '15px', py: '20px', borderRadius: 0 }}
              >
                <img
                  style={{
                    width: 10
                  }}
                  src={redBin}
                  alt={rightTitle}
                />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </AppBar>
      <Box sx={{ height: '60px', minHeight: '60px' }} />
    </>
  );
}
