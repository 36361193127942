import dash from '../assets/dash.svg';
import ltc from '../assets/ltc.svg';
import usdt from '../assets/usdt.svg';
import ada from '../assets/ada.svg';
import eth from '../assets/eth.svg';
import btc from '../assets/btc.svg';
import trid from '../assets/trid.svg';
import bnb from '../assets/bnb.svg';
import doge from '../assets/doge.svg';

const images = {
  DASH: dash,
  LTC: ltc,
  USDT: usdt,
  ADA: ada,
  ETH: eth,
  BTC: btc,
  TRID: trid,
  BNB: bnb,
  DOGE: doge
};

const findImage = (symbol: string) => {
  let image;
  Object.entries(images).find(([k, v]) => {
    if (k === symbol) {
      image = v;
      return true;
    } else {
      return false;
    }
  });
  return image;
};

export default findImage;
