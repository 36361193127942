import { createContext, useState } from 'react';
import { CurrentWalletContextType, Wallet } from '../models/wallet';

const currentWalletContextDefault: CurrentWalletContextType = {
  currentWallet: null,
  setCurrentWallet: () => {},
  currentWalletUpdated: false,
  setCurrentWalletUpdated: () => {}
};

export const CurrentWalletContext = createContext<CurrentWalletContextType>(
  currentWalletContextDefault
);

interface Props {
  children: React.ReactNode;
}

export default function CurrentWalletProvider({ children }: Props) {
  const [currentWallet, setCurrentWallet] = useState<Wallet | null>(null);
  const [currentWalletUpdated, setCurrentWalletUpdated] = useState(false);

  return (
    <CurrentWalletContext.Provider
      value={{
        currentWallet,
        setCurrentWallet,
        currentWalletUpdated,
        setCurrentWalletUpdated
      }}
    >
      {children}
    </CurrentWalletContext.Provider>
  );
}
