import { Box, Stack, Typography } from '@mui/material';
import {
  cryptoGrey,
  cryptoPositive,
  cryptoPrimary,
  cryptoSecondary
} from '../theme/colors';
import { Invoice, InvoiceStatus } from '../models/invoice';
import { format, parseISO, addMinutes } from 'date-fns';
import { useContext } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import AddressText from './AddressText';

interface Props {
  invoice: Invoice;
}

const invoiceTextStyle = {
  fontSize: '0.875rem',
  lineHeight: '1.25rem'
};

export default function InvoiceItem({ invoice }: Props) {
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];

  let numberFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 20
  });

  let FiatCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: invoice.fiat_currency ? invoice.fiat_currency.currency : 'USD',
    currencyDisplay: 'code'
  });
  const fiatString = FiatCurrency.format(invoice.fiat_currency.value)
    .replace(
      `${invoice.fiat_currency ? invoice.fiat_currency.currency : 'USD'}`,
      ''
    )
    .trim();

  const dateString = format(
    addMinutes(parseISO(invoice.created), -new Date().getTimezoneOffset()),
    'MMM dd yyyy HH:mm'
  );
  const statusString = () => {
    switch (invoice.status) {
      case InvoiceStatus.PAID:
        return language.translation.historyWindow.completed;
      case InvoiceStatus.IN_BLOCK:
        return language.translation.historyWindow.hasBlock;
      case InvoiceStatus.IN_MEMPOOL:
        return language.translation.historyWindow.inMempool;
      case InvoiceStatus.PARTIALLY_PAID:
        return language.translation.historyWindow.partiallyPaid;
      case InvoiceStatus.NOT_PAID:
        return language.translation.historyWindow.unpaid;
    }
  };

  return (
    <Box
      component={'div'}
      sx={{
        height: '100%',
        padding: '1px',
        paddingTop: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
      }}
    >
      <Stack
        sx={{
          height: '100%',
          py: '10px',
          px: '15px',
          backgroundColor: cryptoGrey[900],
          color: cryptoSecondary[200],
          gap: '5px'
        }}
      >
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
          <Typography sx={invoiceTextStyle}>{dateString}</Typography>
          <Typography
            sx={[
              invoiceTextStyle,
              {
                color:
                  invoice.status === InvoiceStatus.PAID
                    ? cryptoPositive[400]
                    : invoice.status === InvoiceStatus.IN_BLOCK
                    ? cryptoSecondary[100]
                    : cryptoSecondary[200]
              }
            ]}
          >
            {statusString()}
          </Typography>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
          <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
            <Typography
              sx={[invoiceTextStyle, { color: cryptoSecondary[100] }]}
            >
              {language.translation.historyWindow.invoice}
            </Typography>
            <Typography
              sx={[
                invoiceTextStyle,
                {
                  px: '20px',
                  color: 'white'
                }
              ]}
            >
              {`${numberFormat.format(invoice.value)} ${invoice.symbol}`}
            </Typography>
          </Stack>
          {invoice.active && (
            <Typography sx={[invoiceTextStyle, { color: cryptoPositive[900] }]}>
              {language.translation.historyWindow.active}
            </Typography>
          )}
        </Stack>
        <Stack
          direction={'row'}
          sx={{ justifyContent: 'flex-start', width: '100%' }}
        >
          <Typography sx={[invoiceTextStyle, { width: '60px' }]}>
            {language.translation.historyWindow.address}
          </Typography>
          <Stack direction={'row'} sx={{ width: 'calc(100% - 60px)' }}>
            <AddressText
              address={invoice.address}
              fontHeight={'0.875rem'}
              lineHeight={'1.25rem'}
              stringLen={0}
              color={cryptoSecondary[200]}
            />
          </Stack>
          {/* <Typography sx={[invoiceTextStyle, { px: '5px' }]}>
            {invoice.address}
          </Typography> */}
        </Stack>
        <Stack
          direction={'row'}
          sx={{ justifyContent: 'flex-start', width: '100%' }}
        >
          <Typography sx={invoiceTextStyle}>UUID:</Typography>
          <Typography noWrap sx={[invoiceTextStyle, { px: '5px' }]}>
            {invoice.id}
          </Typography>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
          <Typography sx={invoiceTextStyle}>
            {language.translation.historyWindow.orderId}
          </Typography>
          <Typography sx={[invoiceTextStyle, { px: '5px' }]}>
            {invoice.data?.order_id}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          sx={{ justifyContent: 'flex-start', width: '100%' }}
        >
          <Typography sx={[invoiceTextStyle, { width: '35px' }]}>
            Hash:
          </Typography>
          <Stack sx={{ width: 'calc(100% - 35px)' }}>
            {invoice.txs.length > 0 &&
              invoice.txs.map((t) => (
                <Typography
                  noWrap
                  key={t}
                  sx={[
                    invoiceTextStyle,
                    { px: '5px', color: cryptoPrimary[200] }
                  ]}
                >
                  {t}
                </Typography>
              ))}
          </Stack>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
          <Typography sx={invoiceTextStyle}>
            {language.translation.historyWindow.fiat}
          </Typography>
          <Typography sx={[invoiceTextStyle, { px: '5px' }]}>
            {`${fiatString} ${invoice.fiat_currency.currency}`}
          </Typography>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
          <Typography sx={invoiceTextStyle}>
            {language.translation.historyWindow.percent}
          </Typography>
          <Typography sx={[invoiceTextStyle, { px: '5px' }]}>
            {invoice.fiat_currency.percent}
          </Typography>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
          <Typography sx={invoiceTextStyle}>
            {language.translation.historyWindow.rate}
          </Typography>
          <Typography sx={[invoiceTextStyle, { px: '5px' }]}>
            {invoice.fiat_currency.rate}
          </Typography>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
          <Typography sx={invoiceTextStyle}>
            {language.translation.historyWindow.title}
          </Typography>
          <Typography sx={[invoiceTextStyle, { px: '5px' }]}>
            {invoice.data?.title}
          </Typography>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
          <Typography sx={invoiceTextStyle}>
            {language.translation.historyWindow.description}
          </Typography>
          <Typography sx={[invoiceTextStyle, { px: '5px' }]}>
            {invoice.data?.description}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
