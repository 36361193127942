export interface PaymentData {
  currencyFiatList: string[];
  walletList: Asset[];
}

export interface Asset {
  blockchain: string;
  contract_address: string;
  type_currency: string;
  symbol: string;
  name: string;
  blockchain_type: string;
  decimal: number;
  icon: string | null;
  commissionTransfer: number | null;
  commissionExchange: number | null;
  exchangeData: ExchangeData;
  http_explorer: string;
  hdPathStandards: string[];
}

export interface ExchangeData {
  rate: {
    USD: number;
  };
  market_cap: number;
  calculation_supply: number;
  total_supply: number;
  volume?: number;
  other_info: string | null;
}

export enum BlockchainType {
  UTXO = 'UTXO',
  EVM = 'EVM'
}

export interface PaymentDataContextType {
  paymentData: PaymentData | null;
  setPaymentData: React.Dispatch<React.SetStateAction<PaymentData | null>>;
  paymentDataUpdated: boolean;
  setPaymentDataUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}
