import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../api/api';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import Language from '../components/Language';
import Logo from '../components/Logo';
import {
  containerStyle,
  errorStyle,
  pageStyle,
  positiveButtonStyle,
  secondaryButtonStyle,
  welcomeBackgroundStyle
} from '../theme/styles';
import { EventMessage } from '../models/eventMessage';
import EventDialog from '../components/EventDialog';
import { cryptoPrimary } from '../theme/colors';
import { UserContext } from '../context/User';
import theme from '../theme/normal';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';

const NumberInput = styled(TextField)(({ theme }) => ({
  width: '175px',
  '& .MuiFilledInput-input': {
    fontSize: '3rem',
    textAlign: 'center'
  }
}));

const titleStyle = {
  fontSize: '1.25rem',
  fontWeight: 500,
  color: 'white',
  my: '3rem'
};

const formStyle = {
  maxWidth: 'sm',
  margin: '0 auto',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem'
} as const;

export default function Activation() {
  const userCtx = useContext(UserContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [activated, setActivated] = useState(false);
  const [error, setError] = useState<string>('');
  const [eventMessage, setEventMessage] = useState<EventMessage | null>(null);
  const [counter, setCounter] = useState<number>(60);

  const validationSchema = yup.object({
    code: yup
      .number()
      .integer(language.translation.enterWindow.validCode)
      .required(language.translation.enterWindow.validCode)
  });

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter]);

  let navigate = useNavigate();

  useEffect(() => {
    if (activated && !eventMessage) {
      navigate('../login');
    }
  }, [activated, eventMessage, navigate]);

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { code } = values;
      //console.log(code);
      if (userCtx.registrationRequest) {
        api.account
          .activate({
            paramName: 'password',
            email: userCtx.registrationRequest?.email,
            code: code.toString()
          })
          .then((result) => {
            if (result) {
              setEventMessage({
                status: result.status,
                message: language.translation.windowText.activated,
                positive: true
              });
              setActivated(true);
            }
          })
          .catch((er) => {
            if (er.response.status === 423) {
              setEventMessage({
                status: er.response.status,
                message: language.translation.windowText.expired,
                positive: false
              });
            } else if (er.response.data && er.response.data.message) {
              setEventMessage({
                status: er.response.status,
                message: er.response.data.message,
                positive: false
              });
            } else {
              setEventMessage({
                status: er.response.status ? er.response.status : 0,
                message: er.message,
                positive: false
              });
            }
          });
      }
    }
  });

  useEffect(() => {
    if (Boolean(formik.errors.code)) {
      setError(`${formik.errors.code ? formik.errors.code : ''}`);
    } else {
      setError('');
    }
  }, [formik.errors]);

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleResendLink = () => {
    if (counter === 0 && userCtx.registrationRequest) {
      api.account
        .register(userCtx.registrationRequest)
        .then((result) => {
          if (result) {
            setEventMessage({
              status: result.status,
              message: language.translation.windowText.newCode,
              positive: true
            });
          }
        })
        .catch((er) => {
          if (er.response.status === 429) {
            setEventMessage({
              status: er.response.status,
              message: language.translation.windowText.tooManyRequests,
              positive: false
            });
          } else if (er.response.data && er.response.data.message) {
            setEventMessage({
              status: er.response.status,
              message: er.response.data.message,
              positive: false
            });
          } else {
            setEventMessage({
              status: er.response.status ? er.response.status : 0,
              message: er.message,
              positive: false
            });
          }
        });
    }
  };

  const handleChangeField = (e: React.ChangeEvent<any>) => {
    if (!isNaN(e.target.value) && e.target.value.length < 6) {
      formik.handleChange(e);
    }
  };

  return (
    <Box component="div" sx={welcomeBackgroundStyle}>
      <Box
        component="div"
        sx={[pageStyle, { height: '100%', minHeight: '100dvh' }]}
      >
        <Language />
        <Box component="div" sx={containerStyle}>
          <Logo size={120} cursorStyle="auto" />
          <Typography sx={titleStyle}>
            {language.translation.codeWindow.enter}
          </Typography>
          <Box
            maxWidth="sm"
            component="form"
            sx={formStyle}
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <NumberInput
              autoFocus
              fullWidth
              InputProps={{ disableUnderline: true }}
              variant="filled"
              id="code"
              name="code"
              placeholder="Code"
              value={formik.values.code}
              onChange={handleChangeField}
              onBlur={formik.handleBlur}
              error={formik.touched.code && Boolean(formik.errors.code)}
            />
            <Typography sx={errorStyle}>
              {formik.touched.code && Boolean(formik.errors.code)
                ? `${error}`
                : ' '}
            </Typography>
            <Button
              variant="contained"
              disableRipple
              sx={positiveButtonStyle}
              size="large"
              type="submit"
            >
              {language.translation.mainButton.signIn}
            </Button>
            <Stack sx={{ alignItems: 'center' }}>
              <Typography
                sx={{ pt: '1.2rem', fontSize: '1.25rem', color: '#BEBEBE' }}
              >
                {counter}
              </Typography>
              <Typography
                onClick={handleResendLink}
                sx={
                  counter === 0
                    ? {
                        color: cryptoPrimary[200],
                        mb: '14px',
                        fontSize: '1.25rem',
                        cursor: 'pointer',
                        textDecoration: 'underline'
                      }
                    : {
                        color: '#BEBEBE',
                        mb: '14px',
                        fontSize: '1.25rem'
                      }
                }
              >
                {language.translation.codeWindow.resend}
              </Typography>
            </Stack>
            <Button
              variant="contained"
              disableRipple
              sx={[secondaryButtonStyle, { mt: '1.5rem' }]}
              size="large"
              type="button"
              onClick={handleClickBack}
            >
              {language.translation.mainButton.back}
            </Button>
          </Box>
        </Box>
        <Typography sx={{ color: cryptoPrimary[200], mb: '20px' }}></Typography>
      </Box>
      {eventMessage && (
        <EventDialog
          isOpen={Boolean(eventMessage)}
          setOpen={setEventMessage}
          positive={eventMessage.positive}
          message={eventMessage.message}
        />
      )}
    </Box>
  );
}
