import { Box } from '@mui/material';
import background from '../assets/background.svg';
import Logo from '../components/Logo';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { welcomeBackgroundStyle } from '../theme/styles';

const containerStyle = {
  background: `url(${background})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  position: 'relative',
  width: '100%',
  minHeight: '100dvh',
  maxWidth: '704px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'center',
  color: 'white'
} as const;

export default function Wellcome() {
  let navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => navigate('login'), 2000);
  }, []);

  return (
    <Box component="div" sx={welcomeBackgroundStyle}>
      <Box component="div" sx={containerStyle}>
        <Logo size={150} cursorStyle="pointer" />
      </Box>
    </Box>
  );
}
