import { createContext, useEffect, useState } from 'react';
import { RegisterRequest, UserContextType, UserProfile } from '../models/user';
import api from '../api/api';

const userContextDefault: UserContextType = {
  user: null,
  setUser: () => {},
  userToken: null,
  setToken: () => {},
  registrationRequest: null,
  setRegistrationRequest: () => {},
  setUpdated: () => {}
};

export const UserContext = createContext<UserContextType>(userContextDefault);

interface Props {
  children: React.ReactNode;
}

export default function UserProvider({ children }: Props) {
  const [user, setUser] = useState<UserProfile | null>(null);
  const [registrationRequest, setRegistrationRequest] =
    useState<RegisterRequest | null>(null);
  const [userToken, setUserToken] = useState<string | null>(
    window.localStorage.getItem('apiforcrypto.jwt')
  );
  const [updated, setUpdated] = useState(false);

  function setToken(token: string | null) {
    if (token) {
      window.localStorage.setItem('apiforcrypto.jwt', token);
      setUserToken(token);
    } else {
      setUserToken(null);
    }
  }

  useEffect(() => {
    if (userToken && !updated) {
      //console.log('Trying to get user ...');
      api.account
        .profile()
        .then((response) => {
          //console.log(response);
          if (response) {
            // response.wallets.push({
            //   id: 'da19fe88-6e2c-42ee-a22c-c5e903b60e6b',
            //   created: '2023-06-02T00:00:00',
            //   type: 'INVOICE',
            //   name: 'Wallet_1',
            //   balance: 20.632712
            // });
            setUser(response);
            setUpdated(true);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (err.response.status === 401) {
            setUser(null);
          }
        });
    }
  }, [userToken, updated]);

  useEffect(() => {
    if (!user) {
      setUpdated(false);
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userToken,
        setToken,
        registrationRequest,
        setRegistrationRequest,
        setUpdated
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
