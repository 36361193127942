import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import {
  cryptoAlpha,
  cryptoGrey,
  cryptoPrimary,
  cryptoSecondary
} from '../theme/colors';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import CloseIcon from '../assets/icon_cancel.svg';
import { errorStyle, positiveButtonStyle } from '../theme/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { UserContext } from '../context/User';
import api from '../api/api';
import { useNavigate } from 'react-router-dom';
import { EventMessage } from '../models/eventMessage';
import { Wallet } from '../models/wallet';

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  password?: boolean;
  setEventMessage: React.Dispatch<React.SetStateAction<EventMessage | null>>;
  wallet?: Wallet;
}

interface DialogProps {
  redBackground?: boolean;
}

const formStyle = {
  padding: '8px 30px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '7px'
} as const;

const DialogInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'redBackground'
})<DialogProps>(({ theme, redBackground }) => ({
  width: '100%',
  backgroundColor: redBackground ? cryptoAlpha[700] : 'white',
  boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.25)',
  border: 'none',
  height: '50px',
  '& .MuiFilledInput-root': {
    backgroundColor: redBackground ? cryptoAlpha[700] : cryptoSecondary[50],
    boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px'
  },
  '& .MuiFilledInput-input': {
    width: '100%',
    color: 'black'
  }
}));

const linkTextStyle = {
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  textAlign: 'center',
  color: cryptoPrimary[200],
  textDecoration: 'underline',
  cursor: 'pointer'
} as const;

export default function EditFormDialog({
  isOpen,
  setOpen,
  password,
  setEventMessage,
  wallet
}: Props) {
  const userCtx = useContext(UserContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [validationSchema, setValidationSchema] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [registered, setRegistered] = useState(false);
  const [activated, setActivated] = useState(false);
  const [codeNotValid, setCodeNotValid] = useState(false);
  const [counter, setCounter] = useState<number>(0);
  let navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter]);

  useEffect(() => {
    if (password) {
      const passwordValidationSchema = yup.object({
        password: yup
          .string()
          .min(6, language.translation.enterWindow.minimumChar)
          .required(language.translation.enterWindow.passwordRequired)
      });
      setValidationSchema(passwordValidationSchema);
    } else if (wallet) {
      const nameValidationSchema = yup.object({
        name: yup.string().required('Wallet name is required')
      });
      setValidationSchema(nameValidationSchema);
    } else if (registered) {
      const codeValidationSchema = yup.object({
        code: yup
          .number()
          .integer(language.translation.enterWindow.validCode)
          .required(language.translation.enterWindow.validCode)
      });
      setValidationSchema(codeValidationSchema);
      setCounter(60);
    } else {
      const emailValidationSchema = yup.object({
        email: yup
          .string()
          .email(language.translation.enterWindow.validEmail)
          .required(language.translation.enterWindow.emailRequired)
      });
      setValidationSchema(emailValidationSchema);
    }
  }, [language, registered, password]);

  const formik = useFormik({
    initialValues: password
      ? {
          password: ''
        }
      : wallet
      ? {
          name: wallet.name
        }
      : {
          email: userCtx.user?.username
        },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (userCtx.user && password) {
        console.log(values);
        const { password } = values;
        if (password) {
          const registrationData = {
            email: userCtx.user.username,
            password: password,
            language: language.id.toUpperCase()
          };
          api.account
            .register(registrationData)
            .then((result) => {
              if (result) {
                setRegistered(true);
                userCtx.setRegistrationRequest(registrationData);
                resetForm();
                setCounter(60);
              }
            })
            .catch((er) => {
              if (er.response.status === 429) {
                setEventMessage({
                  status: er.response.status,
                  message: language.translation.windowText.tooManyRequests,
                  positive: false
                });
              } else if (er.response.data && er.response.data.message) {
                setEventMessage({
                  status: er.response.status,
                  message: er.response.data.message,
                  positive: false
                });
              } else {
                setEventMessage({
                  status: er.response.status ? er.response.status : 0,
                  message: er.message,
                  positive: false
                });
              }
            });
        }
      } else if (wallet) {
        const { name } = values;
        if (name) {
          api.wallet
            .changeName({ id: wallet.id, name: name })
            .then((result) => {
              if (result) {
                //console.log(result);
                userCtx.setUpdated(false);
                setOpen(false);
              }
            })
            .catch((er) => {
              //console.log(er);
              if (er.response.status === 401) {
                userCtx.setUser(null);
                navigate('../login');
              } else if (er.response.data && er.response.data.message) {
                setEventMessage({
                  status: er.response.status,
                  message: er.response.data.message,
                  positive: false
                });
              } else {
                setEventMessage({
                  status: er.response.status ? er.response.status : 0,
                  message: er.message,
                  positive: false
                });
              }
            });
        }
      } else {
        const { email } = values;
        if (email) {
          api.account
            .changeEmail({ username: email })
            .then((result) => {
              if (result) {
                //console.log(result);
                userCtx.setToken(null);
                userCtx.setUser(null);
                navigate('../activation');
              }
            })
            .catch((er) => {
              //console.log(er);
              if (er.response.status === 401) {
                userCtx.setUser(null);
                navigate('../login');
              } else if (er.response.data && er.response.data.message) {
                setEventMessage({
                  status: er.response.status,
                  message: er.response.data.message,
                  positive: false
                });
              } else {
                setEventMessage({
                  status: er.response.status ? er.response.status : 0,
                  message: er.message,
                  positive: false
                });
              }
            });
        }
      }
    }
  });

  const codeFormik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { code } = values;
      handleCodeSubmit(code);
    }
  });

  const handleResendLink = () => {
    if (counter === 0 && userCtx.registrationRequest) {
      api.account
        .register(userCtx.registrationRequest)
        .then((result) => {
          if (result) {
            userCtx.setToken(null);
            userCtx.setUser(null);
            navigate('../login');
            setActivated(true);
            setCounter(0);
          }
        })
        .catch((er) => {
          if (er.response.status === 403) {
            setCodeNotValid(true);
            setCounter(0);
          } else if (er.response.status === 429) {
            setEventMessage({
              status: er.response.status,
              message: language.translation.windowText.tooManyRequests,
              positive: false
            });
          } else if (er.response.data && er.response.data.message) {
            setEventMessage({
              status: er.response.status,
              message: er.response.data.message,
              positive: false
            });
          } else {
            setEventMessage({
              status: er.response.status ? er.response.status : 0,
              message: er.message,
              positive: false
            });
          }
        });
    }
  };

  const handleCodeSubmit = (code: string) => {
    if (code && userCtx.registrationRequest) {
      api.account
        .activate({
          paramName: 'password',
          email: userCtx.registrationRequest?.email,
          code: code.toString()
        })
        .then((result) => {
          if (result) {
            userCtx.setToken(null);
            userCtx.setUser(null);
            navigate('../login');
            setActivated(true);
            setCounter(0);
          }
        })
        .catch((er) => {
          if (er.response.status === 403) {
            setCodeNotValid(true);
            setCounter(0);
          } else if (er.response.status === 423) {
            setEventMessage({
              status: er.response.status,
              message: language.translation.windowText.expired,
              positive: false
            });
          } else if (er.response.data && er.response.data.message) {
            setEventMessage({
              status: er.response.status,
              message: er.response.data.message,
              positive: false
            });
          } else {
            setEventMessage({
              status: er.response.status ? er.response.status : 0,
              message: er.message,
              positive: false
            });
          }
        });
    }
  };

  const handleChangeField = (e: React.ChangeEvent<any>) => {
    if (!registered) {
      formik.handleChange(e);
    } else if (!isNaN(e.target.value) && e.target.value.length < 6) {
      codeFormik.handleChange(e);
      if (codeNotValid) {
        setCodeNotValid(false);
      }
    }
  };

  useEffect(() => {
    if (formik.errors.email) {
      setError(formik.errors.email);
    } else if (formik.errors.password) {
      setError(formik.errors.password);
    } else if (formik.errors.name) {
      setError(formik.errors.name);
    } else if (codeFormik.errors.code) {
      setError(codeFormik.errors.code);
    } else {
      setError(null);
    }
  }, [formik.errors, codeFormik.errors]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="edit-form-dialog-title"
      aria-describedby="edit-form-dialog-description"
      PaperProps={{
        style: {
          alignItems: 'center',
          backgroundColor: cryptoPrimary[50],
          borderRadius: '10px',
          minHeight: '230px',
          width: '320px',
          padding: '10px'
        }
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          pb: '5px'
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            pt: '20px'
          }}
        >
          {!registered && (
            <DialogTitle sx={{ p: 0, fontSize: '1rem', fontWeight: 'bold' }}>
              {password
                ? language.translation.windowText.changePassword
                : wallet
                ? language.translation.windowText.changeWalletName
                : language.translation.windowText.changeEmail}
            </DialogTitle>
          )}
          {registered && (
            <DialogTitle sx={{ p: 0, fontSize: '1rem', fontWeight: 'bold' }}>
              {language.translation.windowText.enterCode}
            </DialogTitle>
          )}
        </Box>
        <IconButton
          disableRipple
          onClick={handleClose}
          sx={{
            p: 0,
            borderRadius: 0,
            '&:hover': {
              backgroundColor: cryptoGrey[100]
            }
          }}
        >
          <img
            style={{
              height: 25
            }}
            src={CloseIcon}
            alt="Close"
          />
        </IconButton>
      </Stack>
      {!registered && (
        <Box
          maxWidth="sm"
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <DialogContent sx={{ p: 0, overflowY: 'inherit' }}>
            <Typography
              sx={{ mb: '40px', fontWeight: '500', textAlign: 'center' }}
            >
              {password
                ? language.translation.windowText.enterPassword
                : wallet
                ? language.translation.windowText.enterWalletName
                : language.translation.windowText.enterEmail}
            </Typography>

            <DialogInput
              autoFocus
              fullWidth
              InputProps={{ disableUnderline: true }}
              variant="filled"
              id={password ? 'password' : wallet ? 'name' : 'email'}
              name={password ? 'password' : wallet ? 'name' : 'email'}
              value={
                password
                  ? formik.values.password
                  : wallet
                  ? formik.values.name
                  : formik.values.email
              }
              onChange={handleChangeField}
              onBlur={formik.handleBlur}
              error={
                password
                  ? formik.touched.password && Boolean(formik.errors.password)
                  : wallet
                  ? formik.touched.name && Boolean(formik.errors.name)
                  : formik.touched.email && Boolean(formik.errors.email)
              }
            />

            <Box component={'div'} sx={errorStyle}>
              {(formik.touched.email && Boolean(formik.errors.email)) ||
              (formik.touched.name && Boolean(formik.errors.name)) ||
              (formik.touched.password && Boolean(formik.errors.password)) ? (
                <p style={{ margin: 0 }}>{error}</p>
              ) : (
                ' '
              )}
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', p: '15px', pt: 0 }}>
            <Button
              disableRipple
              sx={[positiveButtonStyle, { color: 'white' }]}
              type="submit"
            >
              {language.translation.mainButton.change}
            </Button>
          </DialogActions>
        </Box>
      )}
      {registered && (
        <Box
          maxWidth="sm"
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={codeFormik.handleSubmit}
          sx={{ width: '200px' }}
        >
          <DialogContent sx={{ p: 0, overflowY: 'inherit' }}>
            <Stack
              sx={{
                mt: '8px',
                mb: '12px',
                justifyContent: 'flex-end',
                height: '3rem'
              }}
            >
              {counter > 0 && (
                <Typography
                  sx={{
                    fontSize: '1.25rem',
                    lineHeight: '1.5rem',
                    color: cryptoSecondary[500],
                    textAlign: 'center'
                  }}
                >
                  {counter}
                </Typography>
              )}
              <Typography
                sx={[
                  linkTextStyle,
                  counter > 0 && {
                    color: cryptoSecondary[500],
                    cursor: 'inherit'
                  }
                ]}
                onClick={handleResendLink}
              >
                {language.translation.codeWindow.resend}
              </Typography>
            </Stack>
            <DialogInput
              redBackground={codeNotValid}
              autoFocus
              fullWidth
              InputProps={{ disableUnderline: true }}
              variant="filled"
              id="code"
              name="code"
              value={codeFormik.values.code}
              onChange={handleChangeField}
              onBlur={codeFormik.handleBlur}
              error={codeFormik.touched.code && Boolean(codeFormik.errors.code)}
            />

            <Box component={'div'} sx={errorStyle}>
              {codeFormik.touched.code && Boolean(codeFormik.errors.code) ? (
                <p style={{ margin: 0 }}>{error}</p>
              ) : (
                ' '
              )}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: 'center', py: '15px', pt: 0, px: 0 }}
          >
            <Button
              disableRipple
              sx={[positiveButtonStyle, { color: 'white' }]}
              onClick={() => handleCodeSubmit(codeFormik.values.code)}
            >
              {language.translation.mainButton.confirm}
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
}
