import { Language } from '../models/language';

export const languages: Language[] = [
  {
    id: 'en',
    name: 'English',
    img: '../assets/englang.svg',
    translation: {
      codeWindow: {
        enter: 'enter the code sent to your email',
        resend: 'resend code'
      },
      mainButton: {
        import: 'Import',
        history: 'Get History',
        ok: 'Ok',
        payOut: 'Pay Out',
        share: 'Share',
        deleteWallet: 'Delete Wallet',
        hide: 'Hide',
        signIn: 'Sign In',
        back: 'Back',
        registration: 'Registration',
        next: 'Next',
        change: 'Change',
        walletPay: 'Pay from this Wallet',
        paid: 'I Paid',
        cancel: 'Cancel',
        confirm: 'Confirm',
        createInvoice: 'Create Invoice'
      },
      enterWindow: {
        authorization: 'Authorization',
        registration: 'Registration',
        forgot: 'Forgot',
        email: 'Email',
        password: 'Password',
        minimumChar: 'Password minimum 8 characters',
        validEmail: 'Enter a valid email',
        emailRequired: 'Email is required',
        passwordRequired: 'Password is required',
        validCode: 'Enter a valid code',
        changeLanguage: 'Change Language'
      },
      transferWindow: {
        transfer: 'TRANSFER',
        from: 'From:',
        to: 'To:',
        amount: 'Amount:',
        fee: 'Fee:',
        networkFee: 'Network Fee:',
        total: 'Total:'
      },
      receiveWindow: {
        receive: 'RECEIVE',
        asset: 'Asset:',
        receiveAddress: 'Your address to receive:'
      },
      deleteWallet: {
        delete: 'DELETE WALLET',
        confirm: 'Are you sure you want to delete your wallet and all its data?'
      },
      hideAddress: {
        hide: 'HIDE ADDRESS',
        confirm: 'Are you sure you want to hide address?'
      },
      hideToken: {
        hide: 'HIDE TOKEN',
        confirm: 'Are you sure you want to hide token?'
      },
      cancelInvoice: {
        cancel: 'Cancel invoice',
        confirm: 'Are you sure you want to cancel invoice?'
      },
      createWallet: {
        selectAccount:
          'Select the account for payment and paste the data from the clipboard.',
        dataIncludes:
          'The data includes the payment amount and the receiving address'
      },
      footerMenu: {
        wallet: 'WALLET',
        settings: 'SETTINGS',
        invoices: 'INVOICES'
      },
      coinField: {
        coin: 'Coin',
        paste: 'Paste',
        address: 'Address'
      },
      bill: {
        buyCredits: 'Buy credits',
        payBill: 'Pay the bill',
        invoice: 'Invoice: ',
        paymentFor: 'Payment for:',
        valid: 'Invoice is valid:',
        asset: 'Asset:',
        blockchain: 'Blockchain:',
        amount: 'Amount:',
        payToAddress: 'Pay to this address:'
      },
      topWindow: {
        addresses: 'Addresses',
        selectHistory: 'Select history options',
        history: 'History',
        foreignId: 'Enter Foreign ID',
        uuid: 'Enter UUID',
        transactionHistory: 'Transaction History',
        invoiceHistory: 'Invoices History',
        importWallet: 'Import Wallet',
        createWallet: 'Create Wallet',
        tokens: 'Tokens',
        asset: 'Asset',
        wallet: 'Wallet',
        account: 'Account',
        addAsset: 'Add Asset',
        payOut: 'Pay Out',
        token: 'Token',
        createInvoice: 'Create Invoice',
        hiddenAddresses: 'Hidden addresses',
        currencyInfo: 'Currency Info',
        notification: 'Notification',
        chooseAddress: 'Choose address',
        hideAddress: 'Hide Address',
        hideToken: 'Hide Token',
        hideAccount: 'Hide Account',
        hideAsset: 'Hide Asset',
        deleteAll: 'Delete All',
        sorting: 'Sorting',
        chooseWallet: 'Choose Wallet'
      },
      buttons: {
        addresses: 'Addresses',
        addAccount: 'Add Account',
        addToken: 'Add Token',
        showHidden: 'Show hidden',
        addAddress: 'Add Address',
        createWallet: 'Create wallet',
        addAsset: 'Add Asset',
        importWallet: 'Import wallet',
        tokens: 'Tokens',
        historyAndInvoices: 'History and Invoices',
        invoicesHistory: 'Invoices history',
        transactionHistory: 'Transaction History',
        chooseUuid: 'Choose UUID',
        chooseId: 'Choose Your ID',
        selectedHistory: 'Selected History',
        creditsHistory: 'Credits History'
      },
      createWalletWindow: {
        privateWallet: 'Private Wallet',
        forInvoices: 'For Invoices',
        forInvoicesDescription: [
          'The “For Invoices” wallet is designed for issuing invoices for payments for a business account.',
          'Do not transfer funds to this wallet without an invoice, payment without an invoice may affect invoices already issued.',
          'After creating a wallet, you can download the key phrase in the settings of this wallet.'
        ],
        seedPhrase: 'Seed Phrase :',
        privateWalletDescription: [
          'A private wallet is intended for personal use.',
          'You can receive funds on it, store them and send funds without any restrictions.',
          'After creating a wallet, you can download the key phrase in the settings of this wallet.'
        ]
      },
      settingsWindow: {
        email: 'Email:',
        created: 'Created',
        currency: 'Currency',
        credits: 'Credits',
        addCredits: 'Add Credits',
        password: 'Password',
        walletName: 'Wallet Name',
        seedPhrase: 'Seed Phrase :',
        walletId: 'Wallet ID',
        transferKey: 'Transfer Key'
      },
      selectParameterWindow: {
        date: 'Date',
        from: 'From',
        to: 'To',
        asset: 'Asset',
        transaction: 'Transaction',
        invoices: 'Invoices',
        completed: 'Completed',
        partiallyPaid: 'Partially paid',
        inMempool: 'In the mempool',
        hasBlock: 'Has a block',
        unpaid: 'Unpaid',
        cancelled: 'Cancelled',
        incoming: 'Incoming',
        outgoing: 'Outgoing'
      },
      historyWindow: {
        completed: 'Completed',
        inProcess: 'In Process',
        unpaid: 'Unpaid',
        transfer: 'Transfer',
        networkFee: 'Network fee:',
        from: 'From:',
        to: 'To:',
        invoice: 'Invoice',
        address: 'Address:',
        foreignId: 'Foreign ID:',
        fiat: 'Fiat:',
        percent: 'Percent:',
        rate: 'Rate:',
        cancel: 'Cancel',
        inMempool: 'In the mempool',
        partiallyPaid: 'Partially paid',
        hasBlock: 'Has a block',
        cancelled: 'Cancelled',
        orderId: 'Order ID:',
        title: 'Title:',
        description: 'Description:',
        report: 'Download the report',
        toOrder: 'Go to the order page',
        invoiceId: 'Invoice ID',
        invoiceUUID: 'Invoice UUID',
        active: 'Active',
        next: 'Load more...'
      },
      coinInfo: {
        name: 'Name:',
        symbol: 'Symbol:',
        type: 'Type:',
        blockchain: 'Blockchain:',
        contractAddress: 'Contract address:',
        rate: 'Rate:',
        marketCap: 'Market cap:',
        volume: 'Volume (24h) :',
        circulation: 'Circulating Supply:',
        total: 'Total Supply:'
      },
      windowText: {
        newCode: 'A new code has been sent to your email',
        walletCreated: 'The wallet was successfully created',
        userNotFound: 'User or username not found',
        walletImported: 'The wallet was successfully imported',
        assetAdded: 'Asset was added successfully',
        assetAddedToAddress:
          'The asset was successfully added to the new address',
        error: 'Unknown error',
        accountBlocked: 'This account is temporarily blocked.',
        insufficientFunds: 'Insufficient funds',
        totalAmmount: 'Total Amount:',
        payOut: 'Pay Out:',
        networkFee: 'Network Fee:',
        addressUnfrozen: 'The address was successfully unfrozen.',
        timeUp: 'Time is up. The network commission may have changed.',
        enterInvoice: 'Enter the invoice ID',
        changePassword: 'Сhange password',
        enterPassword: 'Enter a new password',
        changeEmail: 'Сhange e-mail',
        enterEmail: 'Enter a new e-mail',
        enterCode: 'Enter the code sent to you email',
        changeWalletName: 'Сhange wallet name',
        enterWalletName: 'Enter a new Wallet name',
        maxAddresses: 'You have maximum addresses.',
        hideAddresses: 'Hide addresses to add new ones.',
        logined: 'You are successfully logined',
        registered: 'You are successfully registered',
        activated: 'Account successfully activated',
        tooManyRequests: 'Too many requests',
        expired: 'Activation time expired'
      }
    }
  },
  {
    id: 'fr',
    name: 'Français',
    img: '../assets/franchlang.svg',
    translation: {
      codeWindow: {
        enter: 'Entrez le code envoyé à votre e-mail',
        resend: 'Renvoyer le code'
      },
      mainButton: {
        import: 'Importer',
        history: "Obtenir l'historique",
        ok: "D'accord",
        payOut: 'Payer',
        share: 'Partager',
        deleteWallet: 'Supprimer le portefeuille',
        hide: 'Cacher',
        signIn: 'Se connecter',
        back: 'Retour',
        registration: 'Inscription',
        next: 'Suivant',
        change: 'Changer',
        walletPay: 'Payer depuis ce portefeuille',
        paid: "J'ai payé",
        cancel: 'Annuler',
        confirm: 'Confirmer',
        createInvoice: 'Créer une facture'
      },
      enterWindow: {
        authorization: 'Autorisation',
        registration: 'Inscription',
        forgot: 'Oublié',
        email: 'Courriel',
        password: 'Mot de passe',
        minimumChar: 'Mot de passe au moins 8 caractères',
        validEmail: 'Entrez une adresse e-mail valide',
        emailRequired: "L'email est obligatoire",
        passwordRequired: 'Le mot de passe est obligatoire',
        validCode: 'Entrez un code valide',
        changeLanguage: 'Changer de langue'
      },
      transferWindow: {
        transfer: 'TRANSFÉRER',
        from: 'De:',
        to: 'À:',
        amount: 'Montant:',
        fee: 'Frais:',
        networkFee: 'Frais réseau:',
        total: 'Total:'
      },
      receiveWindow: {
        receive: 'REÇOIS',
        asset: 'Actif:',
        receiveAddress: 'Ton adresse pour recevoir:'
      },
      deleteWallet: {
        delete: 'SUPPRIMER LE PORTEFEUILLE',
        confirm:
          'Es-tu sûr de vouloir supprimer ton portefeuille et toutes ses données?'
      },
      hideAddress: {
        hide: "MASQUER L'ADRESSE",
        confirm: "Es-tu sûr de vouloir masquer l'adresse?"
      },
      hideToken: {
        hide: 'MASQUER LE JETON',
        confirm: 'Es-tu sûr de vouloir masquer le jeton?'
      },
      cancelInvoice: {
        cancel: 'Annuler la facture',
        confirm: 'Es-tu sûr de vouloir annuler la facture?'
      },
      createWallet: {
        selectAccount:
          'Sélectionne le compte pour le paiement et colle les données depuis le presse-papiers.',
        dataIncludes:
          "Les données incluent le montant du paiement et l'adresse de réception."
      },
      footerMenu: {
        wallet: 'PORTEFEUILLE',
        settings: 'PARAMÈTRES',
        invoices: 'FACTURES'
      },
      coinField: {
        coin: 'Monnaie',
        paste: 'Coller',
        address: 'Adresse'
      },
      bill: {
        buyCredits: 'Acheter',
        payBill: 'Payer la',
        invoice: 'Facture: ',
        paymentFor: 'Paiement:',
        valid: 'La factu est valide:',
        asset: 'Actif:',
        blockchain: 'Blockchain:',
        amount: 'Montant:',
        payToAddress: 'Payer à cette adresse:'
      },
      topWindow: {
        addresses: 'Adresses',
        selectHistory: "Sélectionne les options d'historique",
        history: 'Histoire',
        foreignId: "Entrez l'ID étranger",
        uuid: "Entrez l'UUID",
        transactionHistory: 'Histoire des transactions',
        invoiceHistory: 'Histoire des factures',
        importWallet: 'Importer le portefeuille',
        createWallet: 'Créer un portefeuille',
        tokens: 'Jetons',
        asset: 'Actif',
        wallet: 'Portefeuille',
        account: 'Compte',
        addAsset: 'Ajouter un actif',
        payOut: 'Payer',
        token: 'Jeton',
        createInvoice: 'Créer une facture',
        hiddenAddresses: 'Adresses masquées',
        currencyInfo: 'Informations sur la devise',
        notification: 'Notification',
        chooseAddress: 'Choisir une adresse',
        hideAddress: "Masquer l'adresse",
        hideToken: 'Masquer le jeton',
        hideAccount: 'Masquer le compte',
        hideAsset: "Masquer l'actif",
        deleteAll: 'Tout effacer',
        sorting: 'Tri',
        chooseWallet: 'Choisissez un portefeuille'
      },
      buttons: {
        addresses: 'Adresses',
        addAccount: 'Ajouter un compte',
        addToken: 'Ajouter un jeton',
        showHidden: 'Afficher caché',
        addAddress: 'Ajouter une adresse',
        createWallet: 'Créer un portefeuille',
        addAsset: 'Ajouter un actif',
        importWallet: 'Importer un portefeuille',
        tokens: 'Jetons',
        historyAndInvoices: 'Historique et factures',
        invoicesHistory: 'Histoire des factures',
        transactionHistory: 'Historique des transactions',
        chooseUuid: 'UUID sélectionné',
        chooseId: 'Choisir votre ID',
        selectedHistory: 'Historique sélectionné',
        creditsHistory: 'Historique des crédits'
      },
      createWalletWindow: {
        privateWallet: 'Portefeuille privé',
        forInvoices: 'Pour les factures',
        forInvoicesDescription: [
          "Le portefeuille 'Pour les factures' est conçu pour émettre des factures pour les paiements d'un compte d'entreprise.",
          'Ne transférez pas de fonds sur ce portefeuille sans facture, le paiement sans facture peut affecter les factures déjà émises.',
          "Après la création d'un portefeuille, vous pouvez télécharger la phrase clé dans les paramètres de ce portefeuille."
        ],
        seedPhrase: 'Phrase de passe:',
        privateWalletDescription: [
          'Un portefeuille privé est destiné à un usage personnel.',
          'Vous pouvez recevoir des fonds, les stocker et les envoyer sans aucune restriction.',
          "Après la création d'un portefeuille, vous pouvez télécharger la phrase clé dans les paramètres de ce portefeuille."
        ]
      },
      settingsWindow: {
        email: 'E-mail:',
        created: 'Créé',
        currency: 'Devise',
        credits: 'Crédits',
        addCredits: 'Ajouter des crédits',
        password: 'Mot de passe',
        walletName: 'Nom du portefeuille',
        seedPhrase: 'Phrase de passe:',
        walletId: 'ID du portefeuille',
        transferKey: 'Clé de transfert'
      },
      selectParameterWindow: {
        date: 'Date',
        from: 'De',
        to: 'À',
        asset: 'Actif',
        transaction: 'Transaction',
        invoices: 'Factures',
        completed: 'Complet',
        partiallyPaid: 'Partiellement payé',
        inMempool: 'Dans le mempool',
        hasBlock: 'A un bloc',
        unpaid: 'Impayé',
        cancelled: 'Annulé',
        incoming: 'Entrant',
        outgoing: 'Sortant'
      },
      historyWindow: {
        completed: 'Complet',
        inProcess: 'En cours',
        unpaid: 'Impayé',
        transfer: 'Transfert',
        networkFee: 'Commission réseau:',
        from: 'De:',
        to: 'À:',
        invoice: 'Facture',
        address: 'Adresse:',
        foreignId: 'ID étranger:',
        fiat: 'Fiat:',
        percent: 'Pourcentage:',
        rate: 'Tarif:',
        cancel: 'Annuler',
        inMempool: 'Dans le mempool',
        partiallyPaid: 'Partiellement payé',
        hasBlock: 'A un bloc',
        cancelled: 'Annulé',
        orderId: 'Identifiant de commande:',
        title: 'Titre:',
        description: 'Description:',
        report: 'Télécharger le rapport',
        toOrder: 'Aller à la page de commande',
        invoiceId: 'Identifiant de facture',
        invoiceUUID: 'UUID de facture',
        active: 'Actives',
        next: 'Voir plus...'
      },
      coinInfo: {
        name: 'Nom:',
        symbol: 'Symbole:',
        type: 'Type:',
        blockchain: 'Blockchain:',
        contractAddress: 'Adresse du contrat:',
        rate: 'Tarif:',
        marketCap: 'Capitalisation boursière:',
        volume: 'Volume (24h) :',
        circulation: 'Offre en Circulation:',
        total: 'Offre totale:'
      },
      windowText: {
        newCode: 'Un nouveau code a été envoyé à votre adresse e-mail',
        walletCreated: 'Le portefeuille a été créé avec succès',
        userNotFound: "Utilisateur ou nom d'utilisateur introuvable",
        walletImported: 'Le portefeuille a été importé avec succès',
        assetAdded: "L'actif a été ajouté avec succès",
        assetAddedToAddress:
          "L'actif a été ajouté avec succès à la nouvelle adresse",
        error: 'Erreur inconnue',
        accountBlocked: 'Ce compte est temporairement bloqué.',
        insufficientFunds: 'Fonds insuffisants',
        totalAmmount: 'Montant total:',
        payOut: 'Payer:',
        networkFee: 'Commission réseau:',
        addressUnfrozen: "L'adresse a été dégelée avec succès.",
        timeUp: 'Le temps est écoulé. La commission réseau peut avoir changé.',
        enterInvoice: "Entrez l'ID de la facture",
        changePassword: 'Changer de mot de passe',
        enterPassword: 'Entrez un nouveau mot de passe',
        changeEmail: "Changer d'e-mail",
        enterEmail: 'Entrez un nouvel e-mail',
        enterCode: 'Entrez le code envoyé à votre adresse e-mail',
        changeWalletName: 'Changer le nom du portefeuille',
        enterWalletName: 'Entrez un nouveau nom de portefeuille',
        maxAddresses: "Vous avez le nombre maximal d'adresses.",
        hideAddresses: 'Masquez les adresses pour en ajouter de nouvelles.',
        logined: 'Vous êtes connecté avec succès',
        registered: 'Vous êtes inscrit avec succès',
        activated: 'Compte activé avec succès',
        tooManyRequests: 'Trop de demandes',
        expired: "Le temps d'activation a expiré"
      }
    }
  },
  {
    id: 'de',
    name: 'Deutsch',
    img: '../assets/germlang.svg',
    translation: {
      codeWindow: {
        enter: 'Geben Sie den Code ein, der an Ihre E-Mail gesendet wurde.',
        resend: 'Code erneut senden'
      },
      mainButton: {
        import: 'Importieren',
        history: 'Verlauf erhalten',
        ok: 'In Ordnung',
        payOut: 'Auszahlen',
        share: 'Teilen',
        deleteWallet: 'Wallet löschen',
        hide: 'Verbergen',
        signIn: 'Anmelden',
        back: 'Zurück',
        registration: 'Registrierung',
        next: 'Weiter',
        change: 'Ändern',
        walletPay: 'Von diesem Wallet bezahlen',
        paid: 'Ich habe bezahlt',
        cancel: 'Stornieren',
        confirm: 'Bestätigen',
        createInvoice: 'Rechnung erstellen'
      },
      enterWindow: {
        authorization: 'Autorisierung',
        registration: 'Anmeldung',
        forgot: 'Vergessen',
        email: 'E-Mail',
        password: 'Passwort',
        minimumChar: 'Passwort mindestens 8 Zeichen',
        validEmail: 'Geben Sie eine gültige E-Mail-Adresse ein',
        emailRequired: 'E-Mail ist erforderlich',
        passwordRequired: 'Passwort ist erforderlich',
        validCode: 'Geben Sie einen gültigen Code ein',
        changeLanguage: 'Sprache ändern'
      },
      transferWindow: {
        transfer: 'ÜBERTRAGEN',
        from: 'Von:',
        to: 'Zu:',
        amount: 'Betrag:',
        fee: 'Gebühr:',
        networkFee: 'Netzwerkgebühr:',
        total: 'Gesamt:'
      },
      receiveWindow: {
        receive: 'Holen Sie sich',
        asset: 'Vermögenswert:',
        receiveAddress: 'Ihre Adresse zum Empfangen:'
      },
      deleteWallet: {
        delete: 'LÖSCHEN SIE DAS PORTFOLIO',
        confirm:
          'Sind Sie sicher, dass Sie Ihr Portfolio und alle seine Daten löschen möchten?'
      },
      hideAddress: {
        hide: 'ADRESSE AUSBLICKEN',
        confirm: 'Sind Sie sicher, dass Sie die Adresse ausblenden möchten?'
      },
      hideToken: {
        hide: 'TOKEN AUSBLICKEN',
        confirm: 'Sind Sie sicher, dass Sie den Token ausblenden möchten?'
      },
      cancelInvoice: {
        cancel: 'Rechnung stornieren',
        confirm: 'Sind Sie sicher, dass Sie die Rechnung stornieren möchten?'
      },
      createWallet: {
        selectAccount:
          'Wählen Sie das Konto für die Zahlung aus und fügen Sie die Daten aus der Zwischenablage ein.',
        dataIncludes:
          'Die Daten umfassen den Zahlungsbetrag und die Empfangsadresse.'
      },
      footerMenu: {
        wallet: 'PORTFOLIO',
        settings: 'EINSTELLUNG',
        invoices: 'RECHNUNGEN'
      },
      coinField: {
        coin: 'Münze',
        paste: 'Einfügen',
        address: 'Adresse'
      },
      bill: {
        buyCredits: 'Credits kaufen',
        payBill: 'Rechnung bezahlen',
        invoice: 'Rechnung:',
        paymentFor: 'Zahlung für:',
        valid: 'Die Rechnung ist gültig:',
        asset: 'Vermögenswert:',
        blockchain: 'Blockchain:',
        amount: 'Menge:',
        payToAddress: 'An diese Adresse zahlen:'
      },
      topWindow: {
        addresses: 'Adressen',
        selectHistory: 'Wählen Sie die Historienoptionen aus',
        history: 'Geschichte',
        foreignId: 'Geben Sie eine fremde ID ein',
        uuid: 'Geben Sie eine UUID ein',
        transactionHistory: 'Transaktionsgeschichte',
        invoiceHistory: 'Rechnungshistorie',
        importWallet: 'Wallet importieren',
        createWallet: 'Wallet erstellen',
        tokens: 'Tokens',
        asset: 'Vermögenswert',
        wallet: 'Brieftasche',
        account: 'Konto',
        addAsset: 'Vermögenswert hinzufügen',
        payOut: 'Geld auszahlen',
        token: 'Token',
        createInvoice: 'Rechnung erstellen',
        hiddenAddresses: 'Versteckte Adressen',
        currencyInfo: 'Währungsinformation',
        notification: 'Benachrichtigung',
        chooseAddress: 'Wählen Sie die Adresse aus',
        hideAddress: 'Adresse ausblenden',
        hideToken: 'Token ausblenden',
        hideAccount: 'Konto ausblenden',
        hideAsset: 'Vermögenswert ausblenden',
        deleteAll: 'Alles löschen',
        sorting: 'Sortierung',
        chooseWallet: 'Wählen Sie eine Brieftasche'
      },
      buttons: {
        addresses: 'Adressen',
        addAccount: 'Konto hinzufügen',
        addToken: 'Token hinzufügen',
        showHidden: 'Versteckte anzeigen',
        addAddress: 'Adresse hinzufügen',
        createWallet: 'Wallet erstellen',
        addAsset: 'Vermögenswert hinzufügen',
        importWallet: 'Wallet importieren',
        tokens: 'Token',
        historyAndInvoices: 'Geschichte und Rechnungen',
        invoicesHistory: 'Rechnungshistorie',
        transactionHistory: 'Transaktionsgeschichte',
        chooseUuid: 'UUID auswählen',
        chooseId: 'Ihre ID auswählen',
        selectedHistory: 'Ausgewählte Geschichte',
        creditsHistory: 'Kreditgeschichte'
      },
      createWalletWindow: {
        privateWallet: 'Persönliches Wallet',
        forInvoices: 'Für Rechnungen',
        forInvoicesDescription: [
          'Das Wallet „Für Rechnungen“ ist für das Ausstellen von Rechnungen für Zahlungen von einem Geschäftskonto aus bestimmt.',
          'Überweisen Sie keine Gelder auf dieses Wallet ohne Rechnung. Eine Zahlung ohne Rechnung kann bereits ausgestellte Rechnungen beeinflussen.',
          'Nach dem Erstellen eines Wallets können Sie die Schlüsselphrase in den Einstellungen dieses Wallets herunterladen.'
        ],
        seedPhrase: 'Seed Phrase :',
        privateWalletDescription: [
          'Ein privates Wallet ist für den persönlichen Gebrauch vorgesehen.',
          'Sie können Geld darauf empfangen, es speichern und ohne Einschränkungen senden.',
          'Nach dem Erstellen eines Wallets können Sie die Schlüsselphrase in den Einstellungen dieses Wallets herunterladen.'
        ]
      },
      settingsWindow: {
        email: 'E-Mail:',
        created: 'Erstellt',
        currency: 'Währung',
        credits: 'Credits',
        addCredits: 'Credits hinzufügen',
        password: 'Passwort',
        walletName: 'Wallet-Name',
        seedPhrase: 'Seed Phrase :',
        walletId: 'Wallet ID',
        transferKey: 'Transfer-Schlüssel'
      },
      selectParameterWindow: {
        date: 'Datum',
        from: 'Von',
        to: 'Nach',
        asset: 'Vermögenswert',
        transaction: 'Transaktion',
        invoices: 'Rechnungen',
        completed: 'Abgeschlossen',
        partiallyPaid: 'Teilweise bezahlt',
        inMempool: 'Im Mempool',
        hasBlock: 'Hat einen Block',
        unpaid: 'Nicht bezahlt',
        cancelled: 'Abgebrochen',
        incoming: 'Eingehend',
        outgoing: 'Ausgehend'
      },
      historyWindow: {
        completed: 'Abgeschlossen',
        inProcess: 'In Bearbeitung',
        unpaid: 'Nicht bezahlt',
        transfer: 'Transfer',
        networkFee: 'Netzwerkgebühr:',
        from: 'Von:',
        to: 'Nach:',
        invoice: 'Rechnung',
        address: 'Adresse:',
        foreignId: 'Fremde ID:',
        fiat: 'Fiat:',
        percent: 'Prozent:',
        rate: 'Rate:',
        cancel: 'Stornieren',
        inMempool: 'Im Mempool',
        partiallyPaid: 'Teilweise bezahlt',
        hasBlock: 'Hat einen Block',
        cancelled: 'Abgebrochen',
        orderId: 'Bestellnummer:',
        title: 'Titel:',
        description: 'Beschreibung:',
        report: 'Bericht herunterladen',
        toOrder: 'Zur Bestellseite gehen',
        invoiceId: 'Rechnungsnummer',
        invoiceUUID: 'Eindeutige Rechnungs-ID',
        active: 'Aktiv',
        next: 'Mehr anzeigen...'
      },
      coinInfo: {
        name: 'Name:',
        symbol: 'Symbol:',
        type: 'Typ:',
        blockchain: 'Blockchain:',
        contractAddress: 'Vertragsadresse:',
        rate: 'Rate:',
        marketCap: 'Marktkapitalisierung:',
        volume: 'Volumen (24 Stunden) :',
        circulation: 'Umlaufversorgung:',
        total: 'Gesamtangebot:'
      },
      windowText: {
        newCode: 'Ein neuer Code wurde an Ihre E-Mail-Adresse gesendet',
        walletCreated: 'Das Wallet wurde erfolgreich erstellt',
        userNotFound: 'Benutzer oder Benutzername nicht gefunden',
        walletImported: 'Das Wallet wurde erfolgreich importiert',
        assetAdded: 'Vermögenswert wurde erfolgreich hinzugefügt',
        assetAddedToAddress:
          'Der Vermögenswert wurde erfolgreich zum neuen Adressen hinzugefügt',
        error: 'Unbekannter Fehler',
        accountBlocked: 'Dieses Konto ist vorübergehend gesperrt.',
        insufficientFunds: 'Unzureichende Mittel',
        totalAmmount: 'Gesamtmenge:',
        payOut: 'Auszahlen:',
        networkFee: 'Netzwerkgebühr:',
        addressUnfrozen: 'Die Adresse wurde erfolgreich aufgetaut.',
        timeUp:
          'Die Zeit ist abgelaufen. Die Netzwerkgebühr kann sich geändert haben.',
        enterInvoice: 'Geben Sie die Rechnungs-ID ein',
        changePassword: 'Passwort ändern',
        enterPassword: 'Geben Sie ein neues Passwort ein',
        changeEmail: 'E-Mail-Adresse ändern',
        enterEmail: 'Geben Sie eine neue E-Mail-Adresse ein',
        enterCode:
          'Geben Sie den Code ein, der Ihnen per E-Mail gesendet wurde',
        changeWalletName: 'Wallet-Namen ändern',
        enterWalletName: 'Geben Sie einen neuen Wallet-Namen ein',
        maxAddresses: 'Sie haben die maximale Anzahl von Adressen.',
        hideAddresses: 'Blenden Sie Adressen aus, um neue hinzuzufügen.',
        logined: 'Sie haben sich erfolgreich angemeldet',
        registered: 'Sie haben sich erfolgreich registriert',
        activated: 'Konto erfolgreich aktiviert',
        tooManyRequests: 'Zu viele Anfragen',
        expired: 'Aktivierungszeit abgelaufen'
      }
    }
  },
  {
    id: 'it',
    name: 'Italiano',
    img: '../assets/itallang.svg',
    translation: {
      codeWindow: {
        enter: 'Inserisci il codice inviato alla tua email',
        resend: 'Inviare nuovamente il codice'
      },
      mainButton: {
        import: 'Importare',
        history: 'Ottenere la cronologia',
        ok: 'Ok',
        payOut: 'Pagare',
        share: 'Condividere',
        deleteWallet: 'Elimina il portafoglio',
        hide: 'Nascondere',
        signIn: 'Accedere',
        back: 'Indietro',
        registration: 'Registrazione',
        next: 'Avanti',
        change: 'Cambiare',
        walletPay: 'Pagare da questo portafoglio',
        paid: 'Ho pagato',
        cancel: 'Annulla',
        confirm: 'Confermare',
        createInvoice: 'Creare fattura'
      },
      enterWindow: {
        authorization: 'Autorizzazione',
        registration: 'Registrazione',
        forgot: 'Dimenticato',
        email: 'Email',
        password: 'Password',
        minimumChar: 'Password minimo 8 caratteri',
        validEmail: "Inserisci un'email valida",
        emailRequired: "L'email è obbligatoria",
        passwordRequired: 'La password è obbligatoria',
        validCode: 'Inserisci un codice valido',
        changeLanguage: 'Cambia lingua'
      },
      transferWindow: {
        transfer: 'TRASFERIRE',
        from: 'Da:',
        to: 'A:',
        amount: 'Importo:',
        fee: 'Commissione:',
        networkFee: 'Commissione di rete:',
        total: 'Totale:'
      },
      receiveWindow: {
        receive: 'Ricevi',
        asset: 'Attivo:',
        receiveAddress: 'Il tuo indirizzo per ricevere:'
      },
      deleteWallet: {
        delete: 'ELIMINA IL PORTAFOGLIO',
        confirm:
          'Sei sicuro di voler eliminare il tuo portafoglio e tutti i suoi dati?'
      },
      hideAddress: {
        hide: 'NASCONDI INDIRIZZO',
        confirm: "Sei sicuro di voler nascondere l'indirizzo?"
      },
      hideToken: {
        hide: 'NASCONDI TOKEN',
        confirm: 'Sei sicuro di voler nascondere il token?'
      },
      cancelInvoice: {
        cancel: 'Annulla fattura',
        confirm: 'Sei sicuro di voler annullare la fattura?'
      },
      createWallet: {
        selectAccount:
          "Seleziona l'account per il pagamento e incolla i dati dalla clipboard.",
        dataIncludes:
          "I dati includono l'importo del pagamento e l'indirizzo di ricezione."
      },
      footerMenu: {
        wallet: 'PORTAFOGLIO',
        settings: 'IMPOSTAZIONI',
        invoices: 'FATTURE'
      },
      coinField: {
        coin: 'Moneta',
        paste: 'Incolla',
        address: 'Indirizzo'
      },
      bill: {
        buyCredits: 'Compra crediti',
        payBill: 'Paga il conto',
        invoice: 'Fattura:',
        paymentFor: 'Pagamento per:',
        valid: 'La fattura è valida:',
        asset: 'Attività:',
        blockchain: 'Blockchain:',
        amount: 'Importo:',
        payToAddress: 'Paga a questo indirizzo:'
      },
      topWindow: {
        addresses: 'Indirizzi',
        selectHistory: 'Seleziona le opzioni di cronologia',
        history: 'Storia',
        foreignId: 'Inserisci ID estero',
        uuid: 'Inserisci UUID',
        transactionHistory: 'Storia delle transazioni',
        invoiceHistory: 'Storia delle fatture',
        importWallet: 'Importa portafoglio',
        createWallet: 'Crea portafoglio',
        tokens: 'Token',
        asset: 'Attività',
        wallet: 'Portafoglio',
        account: 'Account',
        addAsset: 'Aggiungi attività',
        payOut: 'Paga fuori',
        token: 'Token',
        createInvoice: 'Crea fattura',
        hiddenAddresses: 'Indirizzi nascosti',
        currencyInfo: 'Informazioni sulla valuta',
        notification: 'Notifica',
        chooseAddress: "Scegli l'indirizzo",
        hideAddress: 'Nascondi indirizzo',
        hideToken: 'Nascondi token',
        hideAccount: 'Nascondi account',
        hideAsset: 'Nascondi attività',
        deleteAll: 'Cancella tutto',
        sorting: 'Ordinamento',
        chooseWallet: 'Scegli un portafoglio'
      },
      buttons: {
        addresses: 'Indirizzi',
        addAccount: 'Aggiungi account',
        addToken: 'Aggiungi token',
        showHidden: 'Mostra nascosto',
        addAddress: 'Aggiungi indirizzo',
        createWallet: 'Crea portafoglio',
        addAsset: 'Aggiungi attività',
        importWallet: 'Importa portafoglio',
        tokens: 'Token',
        historyAndInvoices: 'Cronologia e fatture',
        invoicesHistory: 'Storia delle fatture',
        transactionHistory: 'Storia delle transazioni',
        chooseUuid: 'UUID selezionato',
        chooseId: 'Seleziona la tua ID',
        selectedHistory: 'Storia selezionata',
        creditsHistory: 'Cronologia dei crediti'
      },
      createWalletWindow: {
        privateWallet: 'Portafoglio privato',
        forInvoices: 'Per le fatture',
        forInvoicesDescription: [
          'Il portafoglio "Per le fatture" è progettato per emettere fatture per pagamenti da un account aziendale.',
          'Non trasferire fondi su questo portafoglio senza una fattura, il pagamento senza una fattura potrebbe influenzare le fatture già emesse.',
          'Dopo la creazione di un portafoglio, è possibile scaricare la frase chiave nelle impostazioni di questo portafoglio.'
        ],
        seedPhrase: 'Seed Phrase :',
        privateWalletDescription: [
          "Un portafoglio privato è destinato all'uso personale.",
          'Puoi ricevere fondi, conservarli e inviarli senza restrizioni.',
          'Dopo la creazione di un portafoglio, è possibile scaricare la frase chiave nelle impostazioni di questo portafoglio.'
        ]
      },
      settingsWindow: {
        email: 'E-mail:',
        created: 'Creato',
        currency: 'Valuta',
        credits: 'Crediti',
        addCredits: 'Aggiungi crediti',
        password: 'Password',
        walletName: 'Nome del portafoglio',
        seedPhrase: 'Seed Phrase:',
        walletId: 'ID portafoglio',
        transferKey: 'Chiave di trasferimento'
      },
      selectParameterWindow: {
        date: 'Data',
        from: 'Da',
        to: 'A',
        asset: 'Attività',
        transaction: 'Transazione',
        invoices: 'Fatture',
        completed: 'Completato',
        partiallyPaid: 'Parzialmente pagato',
        inMempool: 'Nel mempool',
        hasBlock: 'Ha un blocco',
        unpaid: 'Non pagato',
        cancelled: 'Annullato',
        incoming: 'Entrante',
        outgoing: 'Uscente'
      },
      historyWindow: {
        completed: 'Completato',
        inProcess: 'In elaborazione',
        unpaid: 'Non pagato',
        transfer: 'Trasferimento',
        networkFee: 'Commissione di rete:',
        from: 'Da:',
        to: 'A:',
        invoice: 'Fattura',
        address: 'Indirizzo:',
        foreignId: 'ID esterno:',
        fiat: 'Fiat:',
        percent: 'Percentuale:',
        rate: 'Tariffa:',
        cancel: 'Cancella',
        inMempool: 'Nel mempool',
        partiallyPaid: 'Parzialmente pagato',
        hasBlock: 'Ha un blocco',
        cancelled: 'Annullato',
        orderId: 'ID ordine:',
        title: 'Titolo:',
        description: 'Descrizione:',
        report: 'Scarica il rapporto',
        toOrder: "Vai alla pagina dell'ordine",
        invoiceId: 'ID fattura',
        invoiceUUID: 'UUID fattura',
        active: 'Attive',
        next: 'Vedi altro...'
      },
      coinInfo: {
        name: 'Nome:',
        symbol: 'Simbolo:',
        type: 'Tipo:',
        blockchain: 'Blockchain:',
        contractAddress: 'Indirizzo del contratto:',
        rate: 'Tariffa:',
        marketCap: 'Capitalizzazione di mercato:',
        volume: 'Volume (24 ore) :',
        circulation: 'Offerta Circolante:',
        total: 'Offerta totale:'
      },
      windowText: {
        newCode: 'Un nuovo codice è stato inviato alla tua email',
        walletCreated: 'Il portafoglio è stato creato con successo',
        userNotFound: 'Utente o nome utente non trovato',
        walletImported: 'Il portafoglio è stato importato con successo',
        assetAdded: "L'attività è stata aggiunta con successo",
        assetAddedToAddress:
          "L'attività è stata aggiunta con successo al nuovo indirizzo",
        error: 'Errore sconosciuto',
        accountBlocked: 'Questo account è temporaneamente bloccato.',
        insufficientFunds: 'Fondi insufficienti',
        totalAmmount: 'Importo totale:',
        payOut: 'Pagare:',
        networkFee: 'Commissione di rete:',
        addressUnfrozen: "L'indirizzo è stato scongelato con successo.",
        timeUp:
          'Il tempo è scaduto. La commissione di rete potrebbe essere cambiata.',
        enterInvoice: "Inserisci l'ID fattura",
        changePassword: 'Cambia password',
        enterPassword: 'Inserisci una nuova password',
        changeEmail: 'Cambia e-mail',
        enterEmail: 'Inserisci una nuova e-mail',
        enterCode: 'Inserisci il codice inviato alla tua email',
        changeWalletName: 'Cambia nome portafoglio',
        enterWalletName: 'Inserisci un nuovo nome portafoglio',
        maxAddresses: 'Hai il numero massimo di indirizzi.',
        hideAddresses: 'Nascondi gli indirizzi per aggiungerne di nuovi.',
        logined: "Hai effettuato l'accesso con successo",
        registered: 'Ti sei registrato con successo',
        activated: 'Account attivato con successo',
        tooManyRequests: 'Troppi richieste',
        expired: 'Tempo di attivazione scaduto'
      }
    }
  },
  {
    id: 'es',
    name: 'Español',
    img: '../assets/esplang.svg',
    translation: {
      codeWindow: {
        enter: 'Introduzca el código enviado a su correo electrónico',
        resend: 'Reenviar código'
      },
      mainButton: {
        import: 'Importar',
        history: 'Obtener historial',
        ok: 'Ok',
        payOut: 'Pagar',
        share: 'Compartir',
        deleteWallet: 'Eliminar la billetera',
        hide: 'Ocultar',
        signIn: 'Iniciar sesión',
        back: 'Atrás',
        registration: 'Registro',
        next: 'Siguiente',
        change: 'Cambiar',
        walletPay: 'Pagar desde esta billetera',
        paid: 'He pagado',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        createInvoice: 'Crear factura'
      },
      enterWindow: {
        authorization: 'Autorización',
        registration: 'Registro',
        forgot: 'Olvidó',
        email: 'E-mail',
        password: 'Contraseña',
        minimumChar: 'Contraseña mínimo 8 caracteres',
        validEmail: 'Introduce una dirección de correo electrónico válida',
        emailRequired: 'Se requiere correo electrónico',
        passwordRequired: 'Se requiere contraseña',
        validCode: 'Introduce un código válido',
        changeLanguage: 'Cambiar idioma'
      },
      transferWindow: {
        transfer: 'TRANSFERIR',
        from: 'Desde:',
        to: 'A:',
        amount: 'Cantidad:',
        fee: 'Tarifa:',
        networkFee: 'Tarifa de red:',
        total: 'Total:'
      },
      receiveWindow: {
        receive: 'RECIBIR',
        asset: 'Activo:',
        receiveAddress: 'Tu dirección para recibir:'
      },
      deleteWallet: {
        delete: 'ELIMINAR LA BILLETERA',
        confirm:
          '¿Estás seguro de que deseas eliminar tu billetera y todos sus datos?'
      },
      hideAddress: {
        hide: 'OCULTAR LA DIRECCIÓN',
        confirm: '¿Estás seguro de que deseas ocultar la dirección?'
      },
      hideToken: {
        hide: 'OCULTAR EL TOKEN',
        confirm: '¿Estás seguro de que deseas ocultar el token?'
      },
      cancelInvoice: {
        cancel: 'Cancelar la factura',
        confirm: '¿Estás seguro de que deseas cancelar la factura?'
      },
      createWallet: {
        selectAccount:
          'Selecciona la cuenta para el pago y pega los datos del portapapeles.',
        dataIncludes:
          'Los datos incluyen la cantidad del pago y la dirección de recepción.'
      },
      footerMenu: {
        wallet: 'BILLETERA',
        settings: 'AJUSTES',
        invoices: 'FACTURAS'
      },
      coinField: {
        coin: 'Moneda',
        paste: 'Pegar',
        address: 'Dirección'
      },
      bill: {
        buyCredits: 'Comprar créditos',
        payBill: 'Pagar la factura',
        invoice: 'Factura:',
        paymentFor: 'Pago por:',
        valid: 'La factura es válida:',
        asset: 'Activo:',
        blockchain: 'Blockchain:',
        amount: 'Cantidad:',
        payToAddress: 'Pagar a esta dirección:'
      },
      topWindow: {
        addresses: 'Direcciones',
        selectHistory: 'Selecciona las opciones de historial',
        history: 'Historial',
        foreignId: 'Ingresa el ID extranjero',
        uuid: 'Ingresa el UUID',
        transactionHistory: 'Historial de transacciones',
        invoiceHistory: 'Historial de facturas',
        importWallet: 'Importar billetera',
        createWallet: 'Crear billetera',
        tokens: 'Tokens',
        asset: 'Activo',
        wallet: 'Billetera',
        account: 'Cuenta',
        addAsset: 'Agregar activo',
        payOut: 'Pagar',
        token: 'Token',
        createInvoice: 'Crear factura',
        hiddenAddresses: 'Direcciones ocultas',
        currencyInfo: 'Información de la moneda',
        notification: 'Notificación',
        chooseAddress: 'Elegir dirección',
        hideAddress: 'Ocultar dirección',
        hideToken: 'Ocultar token',
        hideAccount: 'Ocultar cuenta',
        hideAsset: 'Ocultar activo',
        deleteAll: 'Eliminar todo',
        sorting: 'Clasificación',
        chooseWallet: 'Elija una billetera'
      },
      buttons: {
        addresses: 'Direcciones',
        addAccount: 'Agregar cuenta',
        addToken: 'Agregar token',
        showHidden: 'Mostrar oculto',
        addAddress: 'Agregar dirección',
        createWallet: 'Crear billetera',
        addAsset: 'Agregar activo',
        importWallet: 'Importar billetera',
        tokens: 'Tokens',
        historyAndInvoices: 'Historial e facturas',
        invoicesHistory: 'Historial de facturas',
        transactionHistory: 'Historial de transacciones',
        chooseUuid: 'UUID seleccionado',
        chooseId: 'Elija su ID',
        selectedHistory: 'Historial seleccionado',
        creditsHistory: 'Historial de créditos'
      },
      createWalletWindow: {
        privateWallet: 'Billetera privada',
        forInvoices: 'Para facturas',
        forInvoicesDescription: [
          'La billetera "Para facturas" está diseñada para emitir facturas para pagos de una cuenta de empresa.',
          'No transfiera fondos a esta billetera sin una factura, el pago sin una factura puede afectar las facturas ya emitidas.',
          'Después de crear una billetera, puedes descargar la frase clave en la configuración de esta billetera.'
        ],
        seedPhrase: 'Frase clave:',
        privateWalletDescription: [
          'Una billetera privada está destinada a uso personal.',
          'Puedes recibir fondos en ella, almacenarlos y enviarlos sin restricciones.',
          'Después de crear una billetera, puedes descargar la frase clave en la configuración de esta billetera.'
        ]
      },
      settingsWindow: {
        email: 'Correo electrónico:',
        created: 'Creado',
        currency: 'Moneda',
        credits: 'Créditos',
        addCredits: 'Agregar créditos',
        password: 'Contraseña',
        walletName: 'Nombre de la billetera',
        seedPhrase: 'Frase clave:',
        walletId: 'ID de la billetera',
        transferKey: 'Clave de transferencia'
      },
      selectParameterWindow: {
        date: 'Fecha',
        from: 'Desde',
        to: 'Hasta',
        asset: 'Activo',
        transaction: 'Transacción',
        invoices: 'Facturas',
        completed: 'Completo',
        partiallyPaid: 'Parcialmente pagado',
        inMempool: 'En el mempool',
        hasBlock: 'Tiene un bloque',
        unpaid: 'Impago',
        cancelled: 'Cancelado',
        incoming: 'Entrante',
        outgoing: 'Saliente'
      },
      historyWindow: {
        completed: 'Completo',
        inProcess: 'En proceso',
        unpaid: 'Impago',
        transfer: 'Transferir',
        networkFee: 'Comisión de red:',
        from: 'Desde:',
        to: 'Hasta:',
        invoice: 'Factura',
        address: 'Dirección:',
        foreignId: 'ID extranjero:',
        fiat: 'Fiat:',
        percent: 'Porcentaje:',
        rate: 'Tarifa:',
        cancel: 'Cancelar',
        inMempool: 'En el mempool',
        partiallyPaid: 'Parcialmente pagado',
        hasBlock: 'Tiene un bloque',
        cancelled: 'Cancelado',
        orderId: 'ID de pedido:',
        title: 'Título:',
        description: 'Descripción:',
        report: 'Descargar el informe',
        toOrder: 'Ir a la página de pedido',
        invoiceId: 'ID de factura',
        invoiceUUID: 'UUID de factura',
        active: 'Activas',
        next: 'Ver más...'
      },
      coinInfo: {
        name: 'Nombre:',
        symbol: 'Símbolo:',
        type: 'Tipo:',
        blockchain: 'Blockchain:',
        contractAddress: 'Dirección del contrato:',
        rate: 'Tarifa:',
        marketCap: 'Capitalización del mercado:',
        volume: 'Volumen (24h):',
        circulation: 'Suministro Circulante:',
        total: 'Suministro total:'
      },
      windowText: {
        newCode: 'Se ha enviado un nuevo código a tu correo electrónico',
        walletCreated: 'La billetera se creó con éxito',
        userNotFound: 'Usuario o nombre de usuario no encontrado',
        walletImported: 'La billetera se importó correctamente',
        assetAdded: 'El activo se agregó correctamente',
        assetAddedToAddress:
          'El activo se agregó correctamente a la nueva dirección',
        error: 'Error desconocido',
        accountBlocked: 'Esta cuenta está bloqueada temporalmente.',
        insufficientFunds: 'Fondos insuficientes',
        totalAmmount: 'Cantidad total:',
        payOut: 'Pagar:',
        networkFee: 'Comisión de red:',
        addressUnfrozen: 'La dirección se descongeló correctamente.',
        timeUp: 'Se acabó el tiempo. La comisión de red puede haber cambiado.',
        enterInvoice: 'Ingresa el ID de la factura',
        changePassword: 'Cambiar la contraseña',
        enterPassword: 'Ingresa una nueva contraseña',
        changeEmail: 'Cambiar el correo electrónico',
        enterEmail: 'Ingresa un nuevo correo electrónico',
        enterCode: 'Ingresa el código que se envió a tu correo electrónico',
        changeWalletName: 'Cambiar el nombre de la billetera',
        enterWalletName: 'Ingresa un nuevo nombre de billetera',
        maxAddresses: 'Tienes la cantidad máxima de direcciones.',
        hideAddresses: 'Oculta las direcciones para agregar nuevas.',
        logined: 'Has iniciado sesión con éxito',
        registered: 'Te has registrado con éxito',
        activated: 'Cuenta activada con éxito',
        tooManyRequests: 'Demasiadas solicitudes',
        expired: 'Tiempo de activación expirado'
      }
    }
  },
  {
    id: 'ru',
    name: 'Русский',
    img: '../assets/ruslang.svg',
    translation: {
      codeWindow: {
        enter: 'Введите код, отправленный на вашу почту',
        resend: 'Повторно отправить код'
      },
      mainButton: {
        import: 'Импорт',
        history: 'Получить историю',
        ok: 'Хорошо',
        payOut: 'Вывести средства',
        share: 'Поделиться',
        deleteWallet: 'Удалить кошелек',
        hide: 'Скрыть',
        signIn: 'Вход',
        back: 'Назад',
        registration: 'Регистрация',
        next: 'Далее',
        change: 'Изменить',
        walletPay: 'Оплатить с этого кошелька',
        paid: 'Я оплатил',
        cancel: 'Отменить',
        confirm: 'Подтвердить',
        createInvoice: 'Создать счёт'
      },
      enterWindow: {
        authorization: 'Авторизация',
        registration: 'Регистрация',
        forgot: 'Забыли',
        email: 'E-mail',
        password: 'Пароль',
        minimumChar: 'Пароль минимум 8 символов',
        validEmail: 'Введите действительный e-mail',
        emailRequired: 'Требуется адрес электронной почты',
        passwordRequired: 'Требуется пароль',
        validCode: 'Введите действительный код',
        changeLanguage: 'Изменить язык'
      },
      transferWindow: {
        transfer: 'Перевести',
        from: 'Откуда:',
        to: 'Куда:',
        amount: 'Сумма:',
        fee: 'Комиссия:',
        networkFee: 'Комиссия сети:',
        total: 'Итого:'
      },
      receiveWindow: {
        receive: 'ПОЛУЧИТЬ',
        asset: 'Актив:',
        receiveAddress: 'Ваш адрес для получения:'
      },
      deleteWallet: {
        delete: 'УДАЛИТЬ КОШЕЛЕК',
        confirm:
          'Вы уверены, что хотите удалить свой кошелек и всю его информацию?'
      },
      hideAddress: {
        hide: 'СКРЫТЬ АДРЕС',
        confirm: 'Вы уверены, что хотите скрыть адрес?'
      },
      hideToken: {
        hide: 'СКРЫТЬ ТОКЕН',
        confirm: 'Вы уверены, что хотите скрыть токен?'
      },
      cancelInvoice: {
        cancel: 'Отменить счёт',
        confirm: 'Вы уверены, что хотите отменить счёт?'
      },
      createWallet: {
        selectAccount:
          'Выберите счет для оплаты и вставьте данные из буфера обмена.',
        dataIncludes: 'Данные включают сумму платежа и адрес получателя.'
      },
      footerMenu: {
        wallet: 'КОШЕЛЕК',
        settings: 'НАСТРОЙКА',
        invoices: 'СЧЕТА'
      },
      coinField: {
        coin: 'Монета',
        paste: 'Вставить',
        address: 'Адрес'
      },
      bill: {
        buyCredits: 'Купить кредиты',
        payBill: 'Оплатить счет',
        invoice: 'Инвойс:',
        paymentFor: 'Платеж за:',
        valid: 'Инвойс действителен:',
        asset: 'Актив:',
        blockchain: 'Блокчейн:',
        amount: 'Сумма:',
        payToAddress: 'Оплатить на этот адрес:'
      },
      topWindow: {
        addresses: 'Адреса',
        selectHistory: 'Выберите параметры истории',
        history: 'История',
        foreignId: 'Введите иностранный идентификатор',
        uuid: 'Введите UUID',
        transactionHistory: 'История транзакций',
        invoiceHistory: 'История инвойсов',
        importWallet: 'Импорт кошелька',
        createWallet: 'Создать кошелек',
        tokens: 'Токены',
        asset: 'Актив',
        wallet: 'Кошелёк',
        account: 'Счёт',
        addAsset: 'Добавить актив',
        payOut: 'Вывести средства',
        token: 'Токен',
        createInvoice: 'Создать инвойс',
        hiddenAddresses: 'Скрытые адреса',
        currencyInfo: 'Информация о валюте',
        notification: 'Уведомление',
        chooseAddress: 'Выберите адрес',
        hideAddress: 'Скрыть адрес',
        hideToken: 'Скрыть токен',
        hideAccount: 'Скрыть счёт',
        hideAsset: 'Скрыть актив',
        deleteAll: 'Удалить все',
        sorting: 'Сортировка',
        chooseWallet: 'Выберите кошелёк'
      },
      buttons: {
        addresses: 'Адреса',
        addAccount: 'Добавить счёт',
        addToken: 'Добавить токен',
        showHidden: 'Показать скрытые',
        addAddress: 'Добавить адрес',
        createWallet: 'Создать кошелек',
        addAsset: 'Добавить актив',
        importWallet: 'Импорт кошелька',
        tokens: 'Токены',
        historyAndInvoices: 'История и инвойсы',
        invoicesHistory: 'История инвойсов',
        transactionHistory: 'История транзакций',
        chooseUuid: 'Выберите UUID',
        chooseId: 'Выберите свой идентификатор',
        selectedHistory: 'Выбранная история',
        creditsHistory: 'История кредитов'
      },
      createWalletWindow: {
        privateWallet: 'Личный кошелёк',
        forInvoices: 'Для инвойсов',
        forInvoicesDescription: [
          'Личный кошелёк предназначен для выставления счетов для оплаты с бизнес-аккаунта.',
          'Не переводите средства на этот кошелёк без счёта, оплата без счета может повлиять на уже выставленные счета.',
          'После создания кошелька вы можете скачать ключевую фразу в настройках этого кошелька.'
        ],
        seedPhrase: 'Seed Phrase :',
        privateWalletDescription: [
          'Личный кошелёк предназначен для личного использования.',
          'Вы можете принимать средства, хранить и отправлять их без каких-либо ограничений.',
          'После создания кошелька вы можете скачать ключевую фразу в настройках этого кошелька.'
        ]
      },
      settingsWindow: {
        email: 'Электронная почта:',
        created: 'Создан',
        currency: 'Валюта',
        credits: 'Кредиты',
        addCredits: 'Добавить кредиты',
        password: 'Пароль',
        walletName: 'Имя кошелька',
        seedPhrase: 'Seed Phrase :',
        walletId: 'ИД кошелька',
        transferKey: 'Transfer Key'
      },
      selectParameterWindow: {
        date: 'Дата',
        from: 'От',
        to: 'Кому',
        asset: 'Актив',
        transaction: 'Транзакция',
        invoices: 'Инвойсы',
        completed: 'Завершено',
        partiallyPaid: 'Частично оплачено',
        inMempool: 'В пуле',
        hasBlock: 'Имеет блок',
        unpaid: 'Неоплачено',
        cancelled: 'Отменено',
        incoming: 'Входящий',
        outgoing: 'Исходящий'
      },
      historyWindow: {
        completed: 'Завершено',
        inProcess: 'В процессе',
        unpaid: 'Неоплачено',
        transfer: 'Перевести',
        networkFee: 'Сетевая комиссия:',
        from: 'От:',
        to: 'Кому:',
        invoice: 'Инвойс',
        address: 'Адрес:',
        foreignId: 'Иностранный ID:',
        fiat: 'Фиат:',
        percent: 'Процент:',
        rate: 'Ставка:',
        cancel: 'Отмена',
        inMempool: 'В пуле',
        partiallyPaid: 'Частично оплачено',
        hasBlock: 'Имеет блок',
        cancelled: 'Отменено',
        orderId: 'Идентификатор заказа:',
        title: 'Название:',
        description: 'Описание:',
        report: 'Скачать отчет',
        toOrder: 'Перейти на страницу ордера',
        invoiceId: 'Идентификатор счета',
        invoiceUUID: 'Идентификатор счета',
        active: 'Активный',
        next: 'смотреть еще...'
      },
      coinInfo: {
        name: 'Имя:',
        symbol: 'Символ:',
        type: 'Тип:',
        blockchain: 'Блокчейн:',
        contractAddress: 'Адрес контракта:',
        rate: 'Ставка:',
        marketCap: 'Капитализация рынка:',
        volume: 'Объем (24 часа) :',
        circulation: 'Оборотное предложение:',
        total: 'Общий объем эмиссии:'
      },
      windowText: {
        newCode: 'На вашу электронную почту отправлен новый код',
        walletCreated: 'Кошелек успешно создан',
        userNotFound: 'Пользователь или имя пользователя не найдены',
        walletImported: 'Кошелек успешно импортирован',
        assetAdded: 'Актив успешно добавлен',
        assetAddedToAddress: 'Актив успешно добавлен к новому адресу',
        error: 'Неизвестная ошибка',
        accountBlocked: 'Этот аккаунт временно заблокирован.',
        insufficientFunds: 'Недостаточно средств',
        totalAmmount: 'Общая сумма:',
        payOut: 'Вывод средств:',
        networkFee: 'Сетевая комиссия:',
        addressUnfrozen: 'Адрес был успешно разморожен.',
        timeUp: 'Время вышло. Сетевая комиссия может измениться.',
        enterInvoice: 'Введите идентификатор инвойса',
        changePassword: 'Изменить пароль',
        enterPassword: 'Введите новый пароль',
        changeEmail: 'Изменить электронную почту',
        enterEmail: 'Введите новый адрес электронной почты',
        enterCode: 'Введите код, отправленный вам на электронную почту',
        changeWalletName: 'Изменить имя кошелька',
        enterWalletName: 'Введите новое имя кошелька',
        maxAddresses: 'У вас максимальное количество адресов.',
        hideAddresses: 'Скрыть адреса, чтобы добавить новые.',
        logined: 'Вы успешно вошли в систему',
        registered: 'Вы успешно зарегистрированы',
        activated: 'Учетная запись успешно активирована',
        tooManyRequests: 'Слишком много запросов',
        expired: 'Истекло время активации'
      }
    }
  }
];
