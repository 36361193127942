import { Box, Stack, Typography } from '@mui/material';
import { Address } from '../models/address';
import { cryptoGrey, cryptoPrimary } from '../theme/colors';
import { useContext } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import AddressText from './AddressText';
import api from '../api/api';

interface Props {
  address: Address;
  handleUnfreeze: (address: Address) => void;
}

export default function HiddenAddressItem({ address, handleUnfreeze }: Props) {
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];

  const handleUnfreezeClick = () => {
    handleUnfreeze(address);
  };

  return (
    <Box
      component={'div'}
      sx={{
        height: '85px',
        mx: '2px',
        my: '1px'
      }}
    >
      <Stack
        sx={{
          height: '100%',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          backgroundColor: cryptoGrey[900],
          borderRadius: '13px',
          py: '10px',
          px: '15px'
        }}
      >
        <Stack
          direction={'row'}
          sx={{
            justifyContent: 'space-between',
            color: cryptoGrey[600],
            fontSize: '0.875rem'
          }}
        >
          <Typography
            sx={{
              lineHeight: 1
            }}
          >
            {address.blockchain}
          </Typography>
          <Typography sx={{ lineHeight: 1 }}>{address.hd_path}</Typography>
        </Stack>
        <Stack
          onClick={handleUnfreezeClick}
          direction={'row'}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            color: cryptoPrimary[200],
            cursor: 'pointer'
          }}
        >
          <Typography>Unfreeze the address</Typography>
        </Stack>
        <AddressText
          address={address.address}
          fontHeight="1rem"
          lineHeight="1rem"
          stringLen={320}
          color="white"
        />
      </Stack>
    </Box>
  );
}
