import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import HeaderRight from '../components/HeaderRight';
import {
  listStyle,
  paperStyle,
  positiveButtonStyle,
  secondaryButtonStyle
} from '../theme/styles';
import { cryptoGrey, cryptoPrimary, cryptoSecondary } from '../theme/colors';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { Account } from '../models/account';
import blueArrowIcon from '../assets/bluearrow.svg';
import bluePlusIcon from '../assets/blueplus.svg';
import blueLoadIcon from '../assets/bluedownload.svg';
import blueUnLoadIcon from '../assets/blueunload.svg';
import loadIconDisabled from '../assets/icon_receive_disabled.svg';
import unloadIconDisabled from '../assets/icon_send_disabled.svg';
import AssetItem from '../components/AssetItem';
import { WalletInfo } from '../models/wallet';
import { UserContext } from '../context/User';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';
import TransferItem from '../components/TransferItem';
import { Transfer } from '../models/transfer';
import QRCodeDialog from '../components/QRCodeDialog';
import { Address } from '../models/address';
import { Asset, BlockchainType, PaymentData } from '../models/paymentData';
import AssetItemEVM from '../components/AssetItemEVM';
import TokenItem from '../components/TokenItem';
import AddressItem from '../components/AddressItem';
import { EventMessage } from '../models/eventMessage';
import EventDialog from '../components/EventDialog';
import AssetItemUTXO from '../components/AssetItemUTXO';
import HiddenAddressItem from '../components/HiddenAddressItem';
import { DeleteMessage } from '../models/deleteMessage';
import DeleteDialog from '../components/DeleteDialog';
import AddTokenItem from '../components/AddTokenItem';
import { PaymentDataContext } from '../context/PaymentData';
import PaginationType from '../models/pagination';
import Pagination from '../components/Pagination';
import { PAGESIZE } from '../content/constants';

enum Action {
  Start,
  EVMAddressSelected,
  EVMTokensSelected,
  EVMTokenSelected,
  EVMHiddenAddresses,
  EVMAddToken,
  UTXOAddressesSelected,
  UTXOAddressSelected,
  UTXOHiddenAddresses
}

const itemStyle = {
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: cryptoPrimary[50],
  color: 'black'
} as const;

interface Props {
  setActive: any;
  account: Account;
  wallet: WalletInfo;
  setAccount: React.Dispatch<React.SetStateAction<Account | null>>;
  setPayoutAddress: React.Dispatch<React.SetStateAction<Address | null>>;
  handlePayoutClick: () => void;
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultPagination: PaginationType = {
  size: PAGESIZE
};

export default function AssetInfo({
  setActive,
  account,
  wallet,
  setAccount,
  setPayoutAddress,
  handlePayoutClick,
  setUpdated
}: Props) {
  const userCtx = useContext(UserContext);
  const paymentDataCtx = useContext(PaymentDataContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [transfers, setTransfers] = useState<Transfer[]>([]);
  const [transfersForAddress, setTransfersForAddress] = useState<Transfer[]>(
    []
  );
  const [lastSymbol, setLastSymbol] = useState<string>('');
  const [address, setAddress] = useState<Address | null>(null);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [asset, setAsset] = useState<Asset | null>(null);
  const [addressEVM, setAddressEVM] = useState<Address | null>(null);
  const [title, setTitle] = useState<string>(
    language.translation.topWindow.asset
  );
  const [rightTitle, setRightTitle] = useState<string>(
    language.translation.topWindow.hideAddress
  );
  let navigate = useNavigate();
  const [lastAction, setLastAction] = useState<Action>(Action.Start);
  const [token, setToken] = useState<Account | null>(null);
  const [availableTokens, setAvailableTokens] = useState<Asset[]>([]);
  const [contract, setContract] = useState<Boolean>(
    account.type === 'CONTRACT'
  );
  const [coinClicked, setCoinClicked] = useState<Boolean>(false);
  const [eventMessage, setEventMessage] = useState<EventMessage | null>(null);
  const [addressAdded, setAddressAdded] = useState<Boolean>(false);
  const [hiddenAddresses, setHiddenAddresses] = useState<Address[]>([]);
  const [shouldClose, setShouldClose] = useState(false);
  const [confirm, setConfirm] = useState<DeleteMessage | null>(null);
  const [tokenAdded, setTokenAdded] = useState(false);
  const [pagination, setPagination] =
    useState<PaginationType>(defaultPagination);
  const historyListRef = useRef<HTMLElement>(null);
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    if (shouldClose && !eventMessage) {
      setActive(false);
    }
  }, [shouldClose, eventMessage]);

  useEffect(() => {
    if (account && account.symbol !== asset?.symbol) {
      handleNewAccount();
      if (paymentDataCtx.paymentData?.walletList) {
        const currentAsset = paymentDataCtx.paymentData.walletList.find(
          (a) => a.symbol === account.symbol
        );
        if (currentAsset) {
          setAsset(currentAsset);
        }
      }
    }
  }, [account, paymentDataCtx.paymentData]);

  useEffect(() => {
    if (account && account.symbol !== lastSymbol) {
      const params = new URLSearchParams();
      params.append('blockchain', account.blockchain);
      if (pagination.page) {
        params.append('page', pagination.page.toString());
      }
      if (pagination.size) {
        params.append('size', pagination.size.toString());
      }
      if (account.type === 'CONTRACT') {
        params.append('contract', account.contract_address);
        setContract(true);
      } else {
        setContract(false);
      }
      api.history
        .list(wallet.id, params)
        .then((response) => {
          //console.log(response);
          if (response) {
            setTransfers(response);
            setScrollTop(true);
            setPagination({
              ...pagination,
              from_hash:
                pagination.size && response.length < pagination.size
                  ? undefined
                  : response[response.length - 1].hash
            });
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    }
  }, [account]);

  useEffect(() => {
    if (scrollTop && historyListRef.current) {
      historyListRef.current.scrollTo({ top: 0 });
      setScrollTop(false);
    }
  }, [scrollTop]);

  const handleNextHistoryPage = () => {
    if (account && pagination.from_hash) {
      const params = new URLSearchParams();
      params.append('blockchain', account.blockchain);
      params.append('from_hash', pagination.from_hash);
      if (pagination.page) {
        params.append('page', pagination.page.toString());
      }
      if (pagination.size) {
        params.append('size', pagination.size.toString());
      }
      if (account.type === 'CONTRACT') {
        params.append('contract', account.contract_address);
        setContract(true);
      } else {
        setContract(false);
      }
      api.history
        .list(wallet.id, params)
        .then((response) => {
          //console.log(response);
          if (response) {
            let newItems = [...response];
            if (!pagination.page) {
              newItems.shift();
            }
            setTransfers([...transfers, ...newItems]);
            setPagination({
              ...pagination,
              page: pagination.page ? pagination.page + 1 : 1,
              from_hash:
                pagination.size && response.length < pagination.size
                  ? undefined
                  : pagination.from_hash
            });
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    }
  };

  //for blockchain_type: EVM or account type: CONTRACT
  useEffect(() => {
    if (account && (account.symbol !== lastSymbol || addressAdded)) {
      // if (
      //   account.type === 'CONTRACT' ||
      //   asset?.blockchain_type === BlockchainType.EVM
      // ) {
      const params = new URLSearchParams();
      params.append('blockchain', account.blockchain);
      if (account.type === 'CONTRACT') {
        params.append('contract', account.contract_address);
      }
      api.address
        .list(wallet.id, params)
        .then((response) => {
          //console.log(response);
          if (response) {
            setAddresses(response.addresses);
            setLastSymbol(account.symbol);
            setAddressAdded(false);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
      // }
    }
  }, [asset, account, addressAdded]);

  useEffect(() => {
    if (addressEVM) {
      const params = new URLSearchParams();
      if (token) {
        params.append('contract', token.contract_address);
      }
      params.append('blockchain', account.blockchain);
      if (pagination.page) {
        params.append('page', pagination.page.toString());
      }
      if (pagination.size) {
        params.append('size', pagination.size.toString());
      }
      api.history
        .listForAddress(wallet.id, addressEVM.address, params)
        .then((response) => {
          //console.log(response);
          if (response) {
            setTransfersForAddress(response.txs);
            setScrollTop(true);
            setPagination({
              ...pagination,
              from_hash:
                pagination.size && response.txs.length < pagination.size
                  ? undefined
                  : response.txs[response.txs.length - 1].hash
            });
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    }
  }, [addressEVM, token]);

  const handleNextAddressHistoryPage = () => {
    if (addressEVM && pagination.from_hash) {
      const params = new URLSearchParams();
      if (token) {
        params.append('contract', token.contract_address);
      }
      params.append('blockchain', account.blockchain);
      params.append('from_hash', pagination.from_hash);
      if (pagination.page) {
        params.append('page', pagination.page.toString());
      }
      if (pagination.size) {
        params.append('size', pagination.size.toString());
      }
      api.history
        .listForAddress(wallet.id, addressEVM.address, params)
        .then((response) => {
          //console.log(response);
          if (response) {
            let newItems = [...response.txs];
            if (!pagination.page) {
              newItems.shift();
            }
            setTransfersForAddress([...transfersForAddress, ...newItems]);
            setPagination({
              ...pagination,
              page: pagination.page ? pagination.page + 1 : 1,
              from_hash:
                pagination.size && response.txs.length < pagination.size
                  ? undefined
                  : pagination.from_hash
            });
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    }
  };

  useEffect(() => {
    if (addressEVM && lastAction === Action.EVMAddToken) {
      api.general
        .paymentData()
        .then((response) => {
          //console.log(response);
          if (response?.walletList) {
            const filtered = response.walletList.filter(
              (a) =>
                a.blockchain === account.blockchain &&
                a.type_currency === 'CONTRACT' &&
                !addressEVM.accounts.some((e) => e.symbol === a.symbol)
            );
            if (filtered) {
              setAvailableTokens(filtered);
            }
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    }
  }, [lastAction, addressEVM]);

  useEffect(() => {
    if (tokenAdded) {
      handleEVMAddressDeselect();
      setAddressAdded(true);
      setTokenAdded(false);
    }
  }, [tokenAdded]);

  //for blockchain_type: UTXO
  const handleReceiveClick = () => {
    if (asset && asset.blockchain_type === BlockchainType.UTXO) {
      const params = new URLSearchParams();
      params.append('blockchain', account.blockchain);
      api.address
        .list(wallet.id, params)
        .then((response) => {
          //console.log(response);
          if (response) {
            setAddress(response.addresses[0]);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    } else if (addressEVM) {
      setAddress(addressEVM);
    }
  };

  //reset to start
  const handleNewAccount = useCallback(() => {
    setAddresses([]);
    setPagination(defaultPagination);
    if (coinClicked) {
      setTitle(language.translation.topWindow.account);
      setRightTitle(language.translation.topWindow.hideAccount);
      setLastAction(Action.EVMAddressSelected);
      setCoinClicked(false);
    } else {
      if (account.type === 'CONTRACT') {
        setTitle(language.translation.topWindow.tokens);
      } else {
        setTitle(language.translation.topWindow.asset);
      }
      setLastAction(Action.Start);
      setAddressEVM(null);
    }
  }, [account]);

  const handleEVMAddressSelect = (address: Address) => {
    setAddressEVM(address);
    setTitle(language.translation.topWindow.account);
    setRightTitle(language.translation.topWindow.hideAccount);
    setLastAction(Action.EVMAddressSelected);
  };

  const handleEVMAddressDeselect = () => {
    setAddressEVM(null);
    setTitle(language.translation.topWindow.asset);
    setRightTitle(language.translation.topWindow.hideAddress);
    setLastAction(Action.Start);
  };

  const handleEVMTokensSelect = () => {
    setLastAction(Action.EVMTokensSelected);
  };

  const handleEVMTokensDeselect = () => {
    setLastAction(Action.EVMAddressSelected);
  };

  const handleEVMTokenDeselect = () => {
    setToken(null);
    if (contract) {
      setAddressEVM(null);
      setTitle(language.translation.topWindow.tokens);
      setLastAction(Action.Start);
    } else {
      setTitle(language.translation.topWindow.account);
      setRightTitle(language.translation.topWindow.hideAccount);
      setLastAction(Action.EVMTokensSelected);
    }
  };

  const handleSelectCoin = () => {
    setToken(null);
    setCoinClicked(true);
  };

  const handleBackClick = (param: Boolean) => {
    if (lastAction === Action.EVMAddressSelected) {
      handleEVMAddressDeselect();
    } else if (lastAction === Action.EVMTokensSelected) {
      handleEVMTokensDeselect();
    } else if (
      lastAction === Action.EVMTokenSelected ||
      lastAction === Action.EVMAddToken
    ) {
      handleEVMTokenDeselect();
    } else if (
      lastAction === Action.UTXOAddressesSelected ||
      lastAction === Action.EVMHiddenAddresses
    ) {
      setLastAction(Action.Start);
      setTitle(language.translation.topWindow.asset);
    } else if (
      lastAction === Action.UTXOAddressSelected ||
      lastAction === Action.UTXOHiddenAddresses
    ) {
      handleUTXOAddressDeselect();
    } else {
      setActive(param);
    }
  };

  const handleTokenClick = () => {
    setTitle(language.translation.topWindow.token);
    setRightTitle(language.translation.topWindow.hideToken);
    setLastAction(Action.EVMTokenSelected);
  };

  const handleAddTokenClick = () => {
    setTitle(language.translation.buttons.addToken);
    setLastAction(Action.EVMAddToken);
  };

  const handleHideAccountClick = () => {
    if (
      lastAction === Action.UTXOAddressSelected ||
      lastAction === Action.EVMAddressSelected ||
      lastAction === Action.EVMTokensSelected
    ) {
      setConfirm({
        title: language.translation.hideAddress.hide,
        text: language.translation.hideAddress.confirm,
        button: language.translation.mainButton.hide
      });
    } else if (lastAction === Action.EVMTokenSelected) {
      setConfirm({
        title: language.translation.hideToken.hide,
        text: language.translation.hideToken.confirm,
        button: language.translation.mainButton.hide
      });
    }
  };

  const handleDelete = () => {
    if (addressEVM) {
      api.address
        .deleteAddress(wallet.id, addressEVM.address, {
          contract_address: token ? token.contract_address : ''
        })
        .then((response) => {
          //console.log(response);
          if (response) {
            setShouldClose(true);
            setUpdated(false);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          } else if (er.response.data) {
            setEventMessage({
              status: er.response.status,
              message: er.response.data.message
                ? er.response.data.message
                : er.message,
              positive: false
            });
          } else {
            setEventMessage({
              status: er.response.status ? er.response.status : 0,
              message: er.message,
              positive: false
            });
          }
        });
    }
  };

  const handleTransferClick = () => {
    if (addressEVM) {
      setPayoutAddress(addressEVM);
    } else {
      setPayoutAddress(null);
    }
    handlePayoutClick();
  };

  const handleAddressesClick = () => {
    setLastAction(Action.UTXOAddressesSelected);
    setTitle(language.translation.topWindow.addresses);
  };

  const handleUTXOAddressSelect = (address: Address) => {
    setAddressEVM(address);
    setTitle(language.translation.topWindow.account);
    setRightTitle(language.translation.topWindow.hideAddress);
    setLastAction(Action.UTXOAddressSelected);
  };

  const handleUTXOAddressDeselect = () => {
    setAddressEVM(null);
    setTitle(language.translation.topWindow.addresses);
    setRightTitle(language.translation.topWindow.hideAddress);
    setLastAction(Action.UTXOAddressesSelected);
  };

  const handleAddAddressClick = () => {
    if (asset) {
      api.address
        .create(wallet.id, {
          blockchain: account.blockchain,
          standard: asset.hdPathStandards[0]
        })
        .then((response) => {
          //console.log(response);
          if (response.data) {
            userCtx.setUpdated(false);
            setEventMessage({
              status: response.status,
              message: language.translation.windowText.assetAdded,
              positive: true
            });
            setAddressAdded(true);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          } else if (er.response.data) {
            setEventMessage({
              status: er.response.status,
              message: er.response.data.message
                ? er.response.data.message
                : er.message,
              positive: false
            });
          } else {
            setEventMessage({
              status: er.response.status ? er.response.status : 0,
              message: er.message,
              positive: false
            });
          }
        });
    }
  };

  const handleShowHiddenClick = () => {
    const params = new URLSearchParams();
    params.append('blockchain', account.blockchain);
    params.append('active', 'false');
    if (account.type === 'CONTRACT') {
      params.append('contract', account.contract_address);
    }
    api.address
      .list(wallet.id, params)
      .then((response) => {
        //console.log(response);
        if (response) {
          setHiddenAddresses(response.addresses);
        }
      })
      .catch((er) => {
        //console.log(er);
        if (er.response.status === 401) {
          userCtx.setUser(null);
          navigate('../../login');
        }
      });
    setTitle(language.translation.topWindow.hiddenAddresses);
    if (asset && asset.blockchain_type === BlockchainType.UTXO) {
      setLastAction(Action.UTXOHiddenAddresses);
    } else {
      setLastAction(Action.EVMHiddenAddresses);
    }
  };

  const handleUnfreeze = (address: Address) => {
    api.address
      .createForAddress(wallet.id, address.address, {
        contract_address: ''
      })
      .then((response) => {
        //console.log(response);
        if (response.data) {
          setEventMessage({
            status: response.status,
            message: language.translation.windowText.addressUnfrozen,
            positive: true
          });
          setShouldClose(true);
          setUpdated(false);
        }
      })
      .catch((er) => {
        //console.log(er);
        if (er.response.status === 401) {
          userCtx.setUser(null);
          navigate('../../login');
        } else if (er.response.data) {
          setEventMessage({
            status: er.response.status,
            message: er.response.data.message
              ? er.response.data.message
              : er.message,
            positive: false
          });
        } else {
          setEventMessage({
            status: er.response.status ? er.response.status : 0,
            message: er.message,
            positive: false
          });
        }
      });
  };

  const handleAddToken = (token: Asset) => {
    if (addressEVM) {
      api.address
        .createForAddress(wallet.id, addressEVM.address, {
          contract_address: token.contract_address
        })
        .then((response) => {
          //console.log(response);
          if (response.data) {
            setEventMessage({
              status: response.status,
              message: language.translation.windowText.assetAdded,
              positive: true
            });
            setTokenAdded(true);
            setAddressAdded(true);
            setUpdated(false);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            navigate('../../login');
          } else if (er.response.data) {
            setEventMessage({
              status: er.response.status,
              message: er.response.data.message
                ? er.response.data.message
                : er.message,
              positive: false
            });
          } else {
            setEventMessage({
              status: er.response.status ? er.response.status : 0,
              message: er.message,
              positive: false
            });
          }
        });
    }
  };

  return (
    <Paper
      elevation={0}
      square
      sx={[
        paperStyle,
        {
          marginLeft: { xs: 'auto', sm: 0 },
          height: '100%',
          position: 'relative'
        }
      ]}
    >
      <HeaderRight
        title={title}
        rightTitle={rightTitle}
        setActive={handleBackClick}
        handleHideAccountClick={
          addressEVM && lastAction !== Action.EVMAddToken
            ? handleHideAccountClick
            : undefined
        }
      />
      <Box
        ref={historyListRef}
        component="div"
        sx={[
          listStyle,
          {
            backgroundColor: cryptoSecondary[50],
            height: '100%',
            overflowY: 'scroll'
          }
        ]}
      >
        {/* EVM Token part: */}
        {token && addressEVM && (
          <TokenItem
            account={token}
            address={addressEVM}
            setAccount={contract ? setAccount : undefined}
            handleTokenDeselect={
              contract ? handleSelectCoin : handleEVMTokenDeselect
            }
          />
        )}
        {/* Contract part: */}
        {contract &&
          !token &&
          addresses.length > 0 &&
          addresses.map((a) => (
            <TokenItem
              key={a.address}
              account={a.accounts[1]}
              address={a}
              setAddress={setAddressEVM}
              setToken={setToken}
              setAccount={setAccount}
              handleTokenSelect={handleTokenClick}
              handleTokenDeselect={handleSelectCoin}
            />
          ))}
        {/* UTXO part: */}
        {!contract &&
          asset &&
          asset.blockchain_type === BlockchainType.UTXO && (
            <>
              {!addressEVM && lastAction !== Action.UTXOHiddenAddresses && (
                <AssetItem account={account} />
              )}
              {addressEVM && (
                <AssetItemUTXO account={account} address={addressEVM} />
              )}
              {lastAction === Action.Start && (
                <Box
                  component={'div'}
                  sx={{
                    height: '60px',
                    minHeight: '60px',
                    padding: '1px',
                    paddingTop: 0,
                    backgroundColor: cryptoSecondary[50]
                  }}
                >
                  <Stack direction={'row'} sx={itemStyle}>
                    <Typography sx={{ minWidth: '100px', px: '15px' }}>
                      {language.translation.buttons.addresses}
                    </Typography>
                    <Tooltip title={language.translation.buttons.addresses}>
                      <IconButton
                        disableRipple
                        onClick={handleAddressesClick}
                        sx={{
                          p: '20px',
                          borderRadius: 0,
                          '&:hover': {
                            backgroundColor: cryptoGrey[100]
                          }
                        }}
                      >
                        <img
                          style={{
                            height: 16
                          }}
                          src={blueArrowIcon}
                          alt={language.translation.topWindow.addresses}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>
              )}
              {lastAction === Action.UTXOAddressesSelected && (
                <>
                  <Box
                    component={'div'}
                    sx={{
                      height: '60px',
                      minHeight: '60px',
                      padding: '1px',
                      paddingTop: 0,
                      backgroundColor: cryptoSecondary[50]
                    }}
                  >
                    <Stack direction={'row'} sx={itemStyle}>
                      <Typography
                        onClick={handleShowHiddenClick}
                        sx={{
                          minWidth: '100px',
                          px: '15px',
                          color: cryptoPrimary[200],
                          cursor: 'pointer'
                        }}
                      >
                        {language.translation.buttons.showHidden}
                      </Typography>
                      <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                        <Typography sx={{ minWidth: '100px' }}>
                          {language.translation.buttons.addAddress}
                        </Typography>
                        <Tooltip
                          title={language.translation.buttons.addAddress}
                        >
                          <IconButton
                            disableRipple
                            onClick={handleAddAddressClick}
                            sx={{
                              p: '20px',
                              pl: '15px',
                              borderRadius: 0,
                              '&:hover': {
                                backgroundColor: cryptoGrey[100]
                              }
                            }}
                          >
                            <img
                              style={{
                                height: 16
                              }}
                              src={bluePlusIcon}
                              alt={language.translation.buttons.addAddress}
                            />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Box>
                  {addresses.map((a) => (
                    <AddressItem
                      key={a.address}
                      address={a}
                      setAddress={handleUTXOAddressSelect}
                    />
                  ))}
                </>
              )}
            </>
          )}
        {/* Hidden Addresses part: */}
        {(lastAction === Action.UTXOHiddenAddresses ||
          lastAction === Action.EVMHiddenAddresses) &&
          hiddenAddresses.length > 0 &&
          hiddenAddresses.map((a) => (
            <HiddenAddressItem
              key={a.address}
              address={a}
              handleUnfreeze={handleUnfreeze}
            />
          ))}
        {/* EVM part: */}
        {!contract &&
          asset &&
          asset.blockchain_type === BlockchainType.EVM &&
          lastAction !== Action.EVMHiddenAddresses &&
          !addressEVM && (
            <Box
              component={'div'}
              sx={{
                height: '60px',
                minHeight: '60px',
                padding: '1px',
                paddingTop: 0,
                backgroundColor: cryptoSecondary[50]
              }}
            >
              <Stack direction={'row'} sx={itemStyle}>
                <Typography
                  onClick={handleShowHiddenClick}
                  sx={{
                    minWidth: '100px',
                    px: '20px',
                    color: cryptoPrimary[200],
                    cursor: 'pointer'
                  }}
                >
                  {language.translation.buttons.showHidden}
                </Typography>
                <Stack
                  direction={'row'}
                  sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
                >
                  <Typography sx={{ minWidth: '100px', px: '15px' }}>
                    {language.translation.buttons.addAddress}
                  </Typography>
                  <Tooltip title={language.translation.buttons.addAddress}>
                    <IconButton
                      disableRipple
                      onClick={handleAddAddressClick}
                      sx={{
                        p: '20px',
                        pl: '15px',
                        borderRadius: 0,
                        '&:hover': {
                          backgroundColor: cryptoGrey[100]
                        }
                      }}
                    >
                      <img
                        style={{
                          height: 16
                        }}
                        src={bluePlusIcon}
                        alt={language.translation.buttons.addAddress}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Box>
          )}
        {!contract &&
          !addressEVM &&
          asset &&
          asset.blockchain_type === BlockchainType.EVM &&
          lastAction !== Action.EVMHiddenAddresses &&
          addresses.length > 0 &&
          addresses.map((a) => (
            <AssetItemEVM
              key={a.address}
              account={a.accounts[0]}
              address={a}
              setAddress={handleEVMAddressSelect}
              setToken={setToken}
              handleTokenSelect={handleTokenClick}
              handleTokensSelect={handleEVMTokensSelect}
            />
          ))}
        {!contract &&
          addressEVM &&
          !token &&
          asset &&
          asset.blockchain_type === BlockchainType.EVM &&
          lastAction !== Action.EVMAddToken && (
            <AssetItemEVM
              account={addressEVM.accounts[0]}
              address={addressEVM}
              handleAddTokenClick={
                lastAction === Action.EVMTokensSelected
                  ? handleAddTokenClick
                  : undefined
              }
              setToken={setToken}
              handleTokenSelect={handleTokenClick}
              handleTokensSelect={handleEVMTokensSelect}
              handleTokensDeselect={handleEVMTokensDeselect}
            />
          )}
        {/* Add Token part: */}
        {lastAction === Action.EVMAddToken &&
          availableTokens.length > 0 &&
          availableTokens.map((a) => (
            <AddTokenItem
              key={a.symbol}
              asset={a}
              handleAddToken={handleAddToken}
            />
          ))}
        {/* Receive/Pay part: */}
        {((asset &&
          asset.blockchain_type === BlockchainType.UTXO &&
          lastAction !== Action.UTXOAddressesSelected &&
          lastAction !== Action.UTXOHiddenAddresses) ||
          (addressEVM && lastAction !== Action.EVMAddToken)) && (
          <Box
            component={'div'}
            sx={{
              height: '80px',
              minHeight: '80px',
              padding: '1px',
              paddingTop: 0,
              backgroundColor: cryptoSecondary[50]
            }}
          >
            <Stack
              direction={'row'}
              sx={[itemStyle, { px: '15px', py: '20px', gap: '30px' }]}
            >
              <Tooltip title={language.translation.receiveWindow.receive}>
                <Button
                  disableRipple
                  disabled={wallet.type === 'INVOICE' ? true : false}
                  startIcon={
                    <img
                      src={
                        wallet.type === 'INVOICE'
                          ? loadIconDisabled
                          : blueLoadIcon
                      }
                      alt={language.translation.receiveWindow.receive}
                      style={{ width: '20px', height: '24px' }}
                    />
                  }
                  sx={[positiveButtonStyle, { mt: 0, width: '100%' }]}
                  onClick={handleReceiveClick}
                ></Button>
              </Tooltip>
              <Tooltip title={language.translation.mainButton.payOut}>
                <Button
                  disableRipple
                  startIcon={
                    <img
                      src={blueUnLoadIcon}
                      alt={language.translation.transferWindow.transfer}
                      style={{ width: '20px', height: '24px' }}
                    />
                  }
                  sx={[{ mt: 0, width: '100%' }, secondaryButtonStyle]}
                  onClick={handleTransferClick}
                ></Button>
              </Tooltip>
            </Stack>
          </Box>
        )}
        {/* End of Receive/Pay part */}
        {!contract &&
          addressEVM &&
          lastAction === Action.EVMTokensSelected &&
          addressEVM.accounts
            .slice(1)
            .map((a) => (
              <AssetItem
                key={a.symbol}
                account={a}
                setAccount={setToken}
                handleAssetInfoClick={handleTokenClick}
              />
            ))}

        {!addressEVM &&
          lastAction !== Action.UTXOAddressesSelected &&
          lastAction !== Action.UTXOHiddenAddresses &&
          lastAction !== Action.EVMHiddenAddresses && (
            <>
              {transfers.map((t, i) => (
                <TransferItem
                  key={t.hash + i}
                  transfer={t}
                  symbol={account.symbol}
                />
              ))}
              {pagination.from_hash && (
                <Pagination handleNextPage={handleNextHistoryPage} />
              )}
            </>
          )}
        {addressEVM &&
          lastAction !== Action.UTXOAddressesSelected &&
          lastAction !== Action.UTXOHiddenAddresses &&
          lastAction !== Action.EVMAddToken && (
            <>
              {transfersForAddress.map((t, i) => (
                <TransferItem
                  key={t.hash}
                  transfer={t}
                  symbol={token ? token.symbol : account.symbol}
                />
              ))}
              {pagination.from_hash && (
                <Pagination handleNextPage={handleNextAddressHistoryPage} />
              )}
            </>
          )}
      </Box>
      {address && (
        <QRCodeDialog
          isOpen={address !== null}
          setOpen={setAddress}
          address={address}
        />
      )}
      {eventMessage && (
        <EventDialog
          isOpen={Boolean(eventMessage)}
          setOpen={setEventMessage}
          positive={eventMessage.positive}
          message={eventMessage.message}
        />
      )}
      {confirm && (
        <DeleteDialog
          isOpen={Boolean(confirm)}
          setOpen={setConfirm}
          deleteMessage={confirm}
          handleDelete={handleDelete}
        />
      )}
    </Paper>
  );
}
