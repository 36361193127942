import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { appBackgroundStyle, listStyle, paperStyle } from '../theme/styles';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import Header from '../components/Header';
import { cryptoAlpha, cryptoPrimary, cryptoSecondary } from '../theme/colors';
import Footer from '../components/Footer';
import blueArrowIcon from '../assets/bluearrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { WalletInfo } from '../models/wallet';
import { UserContext } from '../context/User';
import api from '../api/api';
import OptionItem from '../components/OptionItem';
import InvoiceHistory from './InvoiceHistory';
import HistoryOptions from './HistoryOptions';
import TransactionHistory from './TransactionHistory';
import { TransferFilterParams } from '../models/transfer';
import { InvoiceFilterParams } from '../models/invoice';
import ChooseInvoiceID from './ChooseInvoiceID';
import { CurrentWalletContext } from '../context/Wallet';

enum Command {
  InvoicesHistory,
  TransactionHistory,
  ChooseUUID,
  ChooseId,
  SelectedHistory,
  CreditsHistory
}

const itemStyle = {
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  px: '15px',
  backgroundColor: cryptoPrimary[50],
  color: 'black'
} as const;

export default function Invoices() {
  const userCtx = useContext(UserContext);
  const currentWalletCtx = useContext(CurrentWalletContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const { id } = useParams<{ id: string }>();
  const [wallet, setWallet] = useState<WalletInfo | null>(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [displayFirst, setDisplayFirst] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [command, setCommand] = useState<Command | null>(null);
  const [transferFilter, setTransferFilter] = useState<TransferFilterParams>(
    {}
  );
  const [invoiceFilter, setInvoiceFilter] = useState<InvoiceFilterParams>({});
  const [invoiceId, setInvoiceId] = useState<string | null>(null);
  let navigate = useNavigate();
  const [newHistory, setNewHistory] = useState(false);

  useEffect(() => {
    if (id) {
      api.wallet
        .details(id)
        .then((response) => {
          //console.log(response);
          if (response) {
            setWallet(response);
            currentWalletCtx.setCurrentWallet(response);
            currentWalletCtx.setCurrentWalletUpdated(true);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (err.response.status === 401) {
            userCtx.setUser(null);
            navigate('../login');
          }
        });
    }
  }, [id]);

  useEffect(() => {
    if (!openSecond || (openSecond && matches)) {
      setDisplayFirst(true);
    } else {
      setDisplayFirst(false);
    }
  }, [openSecond, matches]);

  const handleClickLogo = () => {};

  const handleWalletListClick = () => {
    currentWalletCtx.setCurrentWallet(null);
    currentWalletCtx.setCurrentWalletUpdated(false);
    navigate('../../walletlist');
  };

  const handleNewInvoiceHistoryClick = () => {
    setInvoiceId(null);
    setOpenSecond(true);
    setCommand(Command.InvoicesHistory);
    setInvoiceFilter({});
    setNewHistory(true);
  };

  const handleInvoiceHistoryClick = () => {
    setOpenSecond(true);
    setCommand(Command.InvoicesHistory);
    setNewHistory(true);
  };

  const handleCloseHistory = () => {
    setInvoiceId(null);
    setOpenSecond(false);
    setCommand(null);
    setTransferFilter({});
    setInvoiceFilter({});
  };

  const handleNewTransactionHistoryClick = () => {
    setInvoiceId(null);
    setOpenSecond(true);
    setCommand(Command.TransactionHistory);
    setTransferFilter({});
    setNewHistory(true);
  };

  const handleTransactionHistoryClick = () => {
    setOpenSecond(true);
    setCommand(Command.TransactionHistory);
    setNewHistory(true);
  };

  const handleChooseUUIDClick = () => {
    setInvoiceId(null);
    setOpenSecond(true);
    setCommand(Command.ChooseUUID);
    setInvoiceFilter({});
  };

  const handleChooseIdClick = () => {
    setInvoiceId(null);
    setOpenSecond(true);
    setCommand(Command.ChooseId);
    setInvoiceFilter({});
  };

  const handleSelectedHistoryClick = () => {
    setInvoiceId(null);
    setOpenSecond(true);
    setCommand(Command.SelectedHistory);
    setInvoiceFilter({});
    setTransferFilter({});
  };

  const handleCreditsHistoryClick = () => {
    setInvoiceId(null);
    setOpenSecond(true);
    setCommand(Command.CreditsHistory);
    setInvoiceFilter({});
    setTransferFilter({});
    setNewHistory(true);
  };

  return (
    <Box component="div" sx={[appBackgroundStyle, { maxHeight: '100dvh' }]}>
      <Grid
        container
        columnSpacing={'4px'}
        sx={{ justifyContent: 'center', maxHeight: '100dvh' }}
      >
        {displayFirst && (
          <Grid item xs={12} sm={6} sx={{ maxHeight: '100dvh' }}>
            <Paper
              elevation={0}
              square
              sx={[paperStyle, { marginRight: { xs: 'auto', sm: 0 } }]}
            >
              <Header
                handleClickLogo={handleClickLogo}
                handleWalletListClick={handleWalletListClick}
              />
              <Box component="div" sx={listStyle}>
                <OptionItem
                  text={language.translation.buttons.invoicesHistory}
                  onClickHadler={handleNewInvoiceHistoryClick}
                />
                <OptionItem
                  text={language.translation.buttons.transactionHistory}
                  onClickHadler={handleNewTransactionHistoryClick}
                />
                <OptionItem
                  text={language.translation.buttons.chooseUuid}
                  onClickHadler={handleChooseUUIDClick}
                />
                <OptionItem
                  text={language.translation.buttons.chooseId}
                  onClickHadler={handleChooseIdClick}
                />
                <OptionItem
                  text={language.translation.buttons.selectedHistory}
                  onClickHadler={handleSelectedHistoryClick}
                />
                <OptionItem
                  text={language.translation.buttons.creditsHistory}
                  onClickHadler={handleCreditsHistoryClick}
                />
              </Box>
              <Footer
                invoices={
                  wallet?.type === 'INVOICE' ||
                  currentWalletCtx.currentWallet?.type === 'INVOICE'
                    ? true
                    : false
                }
                invoicesDisabled
                wallet
              />
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} sm={6} sx={{ height: '100dvh' }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              maxWidth: '704px',
              backgroundColor: cryptoAlpha[900]
            }}
          >
            {command === Command.InvoicesHistory && id && (
              <InvoiceHistory
                setActive={handleCloseHistory}
                title={language.translation.topWindow.invoiceHistory}
                walletId={id}
                filter={invoiceFilter}
                invoiceId={invoiceId}
                tariffPay={false}
                newHistory={newHistory}
                setNewHistory={setNewHistory}
              />
            )}
            {command === Command.CreditsHistory && id && (
              <InvoiceHistory
                setActive={handleCloseHistory}
                title={language.translation.buttons.creditsHistory}
                walletId={id}
                filter={invoiceFilter}
                invoiceId={invoiceId}
                tariffPay={true}
                newHistory={newHistory}
                setNewHistory={setNewHistory}
              />
            )}
            {command === Command.TransactionHistory && id && (
              <TransactionHistory
                setActive={handleCloseHistory}
                title={language.translation.topWindow.transactionHistory}
                walletId={id}
                filter={transferFilter}
                newHistory={newHistory}
                setNewHistory={setNewHistory}
              />
            )}
            {command === Command.ChooseId && id && (
              <ChooseInvoiceID
                setActive={handleCloseHistory}
                openInvoices={handleInvoiceHistoryClick}
                invoiceFilter={invoiceFilter}
                setInvoiceFilter={setInvoiceFilter}
              />
            )}
            {command === Command.ChooseUUID && id && (
              <ChooseInvoiceID
                setActive={handleCloseHistory}
                openInvoices={handleInvoiceHistoryClick}
                invoiceFilter={invoiceFilter}
                setInvoiceFilter={setInvoiceFilter}
                setInvoiceId={setInvoiceId}
              />
            )}
            {command === Command.SelectedHistory && id && (
              <HistoryOptions
                setActive={handleCloseHistory}
                openInvoices={handleInvoiceHistoryClick}
                openTransactions={handleTransactionHistoryClick}
                transferFilter={transferFilter}
                setTransferFilter={setTransferFilter}
                invoiceFilter={invoiceFilter}
                setInvoiceFilter={setInvoiceFilter}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
