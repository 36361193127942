import { createContext, useState } from 'react';
import { PaymentData, PaymentDataContextType } from '../models/paymentData';

const paymentDataContextDefault: PaymentDataContextType = {
  paymentData: null,
  setPaymentData: () => {},
  paymentDataUpdated: false,
  setPaymentDataUpdated: () => {}
};

export const PaymentDataContext = createContext<PaymentDataContextType>(
  paymentDataContextDefault
);

interface Props {
  children: React.ReactNode;
}

export default function PaymentDataProvider({ children }: Props) {
  const [paymentData, setPaymentData] = useState<PaymentData | null>(null);
  const [paymentDataUpdated, setPaymentDataUpdated] = useState(false);

  return (
    <PaymentDataContext.Provider
      value={{
        paymentData,
        setPaymentData,
        paymentDataUpdated,
        setPaymentDataUpdated
      }}
    >
      {children}
    </PaymentDataContext.Provider>
  );
}
