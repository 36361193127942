import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { listStyle, paperStyle, positiveButtonStyle } from '../theme/styles';
import HeaderRight from '../components/HeaderRight';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { RadioUnchecked } from '../components/RadioUnchecked';
import { RadioChecked } from '../components/RadioChecked';
import { SwitchOn } from '../components/icons/switchon';
import { SwitchOff } from '../components/icons/switchoff';
import { cryptoGrey, cryptoPrimary, cryptoSecondary } from '../theme/colors';
import { DatePicker } from '@mui/x-date-pickers';
import { BlueSelect } from '../components/icons/blueselect';
import api from '../api/api';
import { Asset } from '../models/paymentData';
import { UserContext } from '../context/User';
import { useNavigate } from 'react-router-dom';
import findImage from '../content/images';
import { InvoiceFilterParams, InvoiceStatus } from '../models/invoice';
import { TransferFilterParams, TransferType } from '../models/transfer';
import { formatISO } from 'date-fns';
import { PaymentDataContext } from '../context/PaymentData';

interface Props {
  setActive: () => void;
  openInvoices: () => void;
  openTransactions: () => void;
  transferFilter: TransferFilterParams;
  setTransferFilter: React.Dispatch<React.SetStateAction<TransferFilterParams>>;
  invoiceFilter: InvoiceFilterParams;
  setInvoiceFilter: React.Dispatch<React.SetStateAction<InvoiceFilterParams>>;
}

enum HistoryType {
  TRANSACTION = 'Transaction',
  INVOICES = 'Invoices'
}

enum TransactionDirection {
  All = 'All',
  IN = 'Incoming',
  OUT = 'Outgoing'
}

const radioIconStyle = {
  height: '30px',
  width: '30px'
} as const;

const radioLabelStyle = {
  color: 'black',
  fontWeight: 500,
  margin: 0,
  gap: '1rem'
} as const;

const labelTextStyle = {
  color: 'black',
  fontWeight: 500
};

const DateInputStyle = {
  width: '100%',
  backgroundColor: cryptoSecondary[50],
  border: 'none',
  borderRadius: '10px',
  height: '40px',
  '& .MuiFilledInput-root': {
    backgroundColor: 'inherit',
    borderRadius: '10px',
    boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.25)',
    '&:after, &:before': {
      display: 'none'
    }
  },
  '& .MuiFilledInput-input': {
    width: '100%',
    color: 'black',
    fontSize: '1rem'
  },
  '& .MuiIconButton-root': {
    borderRadius: 0
  }
} as const;

const SelectInputStyle = {
  width: '100%',
  backgroundColor: cryptoSecondary[50],
  border: 'none',
  borderRadius: '10px',
  height: '40px',
  boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.25)',
  '&:after, &:before': {
    display: 'none'
  },
  '&:hover, & .Mui-focused': {
    backgroundColor: cryptoGrey[100]
  },
  '& .MuiFilledInput-input': {
    py: 0,
    width: '100%',
    color: 'black',
    fontSize: '1rem',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  '& .MuiIconButton-root': {
    borderRadius: 0
  }
} as const;

const switchInputStyle = {
  width: '33px',
  height: '20px',
  p: 0,
  '& .MuiSwitch-switchBase': {
    p: 0,
    '&.Mui-checked': {
      transform: 'translateX(13px)',
      color: 'white',
      border: 1,
      borderColor: cryptoPrimary[200],
      '& + .MuiSwitch-track': {
        backgroundColor: cryptoPrimary[200],
        opacity: 1,
        border: 0,
        borderRadius: '10px'
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: cryptoGrey[50],
      opacity: 1,
      border: 0,
      borderRadius: '10px'
    }
  },
  '& .MuiSwitch-track': {
    backgroundColor: cryptoGrey[700],
    opacity: 1,
    border: 0,
    borderRadius: '10px'
  }
} as const;

const invoiceSwitchLabelStyle = {
  m: 0,
  justifyContent: 'flex-start',
  gap: '15px'
} as const;

const menuItemStyle = {
  height: '55px',
  p: '10px',
  fontSize: '1.25rem',
  fontWeight: 500,
  backgroundColor: cryptoPrimary[50],
  justifyContent: 'space-between'
} as const;

export default function HistoryOptions({
  setActive,
  openInvoices,
  openTransactions,
  transferFilter,
  setTransferFilter,
  invoiceFilter,
  setInvoiceFilter
}: Props) {
  const userCtx = useContext(UserContext);
  const paymentDataCtx = useContext(PaymentDataContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [radioValue, setRadioValue] = useState<HistoryType>(
    HistoryType.INVOICES
  );
  const [fromValue, setFromValue] = useState<Date | null>(null);
  const [toValue, setToValue] = useState<Date | null>(null);
  const [asset, setAsset] = useState('All');
  const [assets, setAssets] = useState<Asset[]>([]);
  const [transactionDirection, setTransactionDirection] =
    useState<TransactionDirection>(TransactionDirection.All);
  const [invoiceStatus, setInvoiceStatus] = useState<InvoiceStatus | 'All'>(
    'All'
  );
  const [invoiceActive, setInvoiceActive] = useState<boolean>(
    invoiceFilter.active ? true : false
  );
  let navigate = useNavigate();

  useEffect(() => {
    if (!paymentDataCtx.paymentDataUpdated) {
      api.general
        .paymentData()
        .then((response) => {
          //console.log(response);
          if (response?.walletList) {
            setAssets(response.walletList);
          }
        })
        .catch((er) => {
          //console.log(er);
          if (er.response.status === 401) {
            userCtx.setUser(null);
            navigate('../../login');
          }
        });
    } else if (paymentDataCtx.paymentData?.walletList) {
      setAssets(paymentDataCtx.paymentData.walletList);
    }
  }, [paymentDataCtx.paymentData, paymentDataCtx.paymentDataUpdated]);

  const handleFromDateChanged = (newValue: Date | null) => {
    setFromValue(newValue);
    setInvoiceFilter({
      ...invoiceFilter,
      from: newValue ? formatISO(newValue) : undefined
    });
  };

  const handleToDateChanged = (newValue: Date | null) => {
    setToValue(newValue);
    setInvoiceFilter({
      ...invoiceFilter,
      to: newValue ? formatISO(newValue) : undefined
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    setRadioValue(
      val === HistoryType.INVOICES
        ? HistoryType.INVOICES
        : HistoryType.TRANSACTION
    );
  };

  const handleAssetChange = (event: SelectChangeEvent) => {
    setAsset(event.target.value);
    const newAsset = assets.find((a) => a.symbol === event.target.value);
    if (newAsset) {
      setInvoiceFilter({
        ...invoiceFilter,
        blockchain: newAsset.blockchain
      });
      setTransferFilter({
        ...transferFilter,
        blockchain: newAsset.blockchain
      });
    } else {
      setInvoiceFilter({
        ...invoiceFilter,
        blockchain: undefined
      });
      setTransferFilter({
        ...transferFilter,
        blockchain: undefined
      });
    }
  };

  const handleTransactionDirectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.name === TransactionDirection.IN && event.target.checked) {
      setTransactionDirection(TransactionDirection.IN);
      setTransferFilter({
        ...transferFilter,
        type: TransferType.IN
      });
    } else if (
      event.target.name === TransactionDirection.OUT &&
      event.target.checked
    ) {
      setTransactionDirection(TransactionDirection.OUT);
      setTransferFilter({
        ...transferFilter,
        type: TransferType.OUT
      });
    } else {
      setTransactionDirection(TransactionDirection.All);
      setTransferFilter({
        ...transferFilter,
        type: undefined
      });
    }
  };

  const handleInvoiceActiveChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setInvoiceActive(true);
      setInvoiceFilter({
        ...invoiceFilter,
        active: true
      });
    } else {
      setInvoiceActive(false);
      setInvoiceFilter({
        ...invoiceFilter,
        active: undefined
      });
    }
  };

  const handleInvoiceStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.name === InvoiceStatus.PAID && event.target.checked) {
      setInvoiceStatus(InvoiceStatus.PAID);
      setInvoiceFilter({
        ...invoiceFilter,
        status: InvoiceStatus.PAID
      });
    } else if (
      event.target.name === InvoiceStatus.PARTIALLY_PAID &&
      event.target.checked
    ) {
      setInvoiceStatus(InvoiceStatus.PARTIALLY_PAID);
      setInvoiceFilter({
        ...invoiceFilter,
        status: InvoiceStatus.PARTIALLY_PAID
      });
    } else if (
      event.target.name === InvoiceStatus.IN_MEMPOOL &&
      event.target.checked
    ) {
      setInvoiceStatus(InvoiceStatus.IN_MEMPOOL);
      setInvoiceFilter({
        ...invoiceFilter,
        status: InvoiceStatus.IN_MEMPOOL
      });
    } else if (
      event.target.name === InvoiceStatus.IN_BLOCK &&
      event.target.checked
    ) {
      setInvoiceStatus(InvoiceStatus.IN_BLOCK);
      setInvoiceFilter({
        ...invoiceFilter,
        status: InvoiceStatus.IN_BLOCK
      });
    } else if (
      event.target.name === InvoiceStatus.NOT_PAID &&
      event.target.checked
    ) {
      setInvoiceStatus(InvoiceStatus.NOT_PAID);
      setInvoiceFilter({
        ...invoiceFilter,
        status: InvoiceStatus.NOT_PAID
      });
      // } else if (event.target.name === 'Cancelled' && event.target.checked) {
      //   setInvoiceStatus('Cancelled');
      //   setInvoiceFilter({
      //     ...invoiceFilter,
      //     status: 'Cancelled'
      //   });
    } else {
      setInvoiceStatus('All');
      setInvoiceFilter({
        ...invoiceFilter,
        status: undefined
      });
    }
  };

  return (
    <Paper
      elevation={0}
      square
      sx={[
        paperStyle,
        { marginLeft: { xs: 'auto', sm: 0 }, minWidth: '300px' }
      ]}
    >
      <HeaderRight
        title={language.translation.topWindow.selectHistory}
        setActive={setActive}
      />
      <Box component="div" sx={[listStyle, { padding: '30px' }]}>
        <Stack direction={'row'} alignItems={'center'} sx={{ height: '40px' }}>
          <Typography sx={labelTextStyle}>
            {language.translation.selectParameterWindow.date}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} sx={{ mb: '10px' }}>
          <Typography sx={[labelTextStyle, { width: '70px' }]}>
            {language.translation.selectParameterWindow.from}
          </Typography>
          <DatePicker
            value={fromValue}
            format={'dd.MM.yyyy'}
            disableFuture
            maxDate={toValue ? toValue : undefined}
            onChange={handleFromDateChanged}
            slotProps={{
              textField: { variant: 'filled', sx: DateInputStyle },
              openPickerButton: { disableRipple: true }
            }}
            slots={{
              openPickerIcon: BlueSelect
            }}
          />
        </Stack>
        <Stack direction={'row'} alignItems={'center'} sx={{ mb: '20px' }}>
          <Typography sx={[labelTextStyle, { width: '70px' }]}>
            {language.translation.selectParameterWindow.to}
          </Typography>
          <DatePicker
            value={toValue}
            format={'dd.MM.yyyy'}
            disableFuture
            minDate={fromValue ? fromValue : undefined}
            onChange={handleToDateChanged}
            slotProps={{
              textField: { variant: 'filled', sx: DateInputStyle },
              openPickerButton: { disableRipple: true }
            }}
            slots={{
              openPickerIcon: BlueSelect
            }}
          />
        </Stack>
        <Stack direction={'row'} alignItems={'center'} sx={{ mb: '20px' }}>
          <Typography sx={[labelTextStyle, { width: '70px' }]}>
            {language.translation.selectParameterWindow.asset}
          </Typography>
          <Select
            id="asset-select-filled"
            value={asset}
            onChange={handleAssetChange}
            variant="filled"
            IconComponent={BlueSelect}
            sx={SelectInputStyle}
            MenuProps={{
              sx: {
                '& .MuiMenu-paper': { backgroundColor: cryptoSecondary[50] },
                '& .MuiMenu-list': {
                  p: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px'
                }
              }
            }}
          >
            <MenuItem
              id="all"
              value="All"
              selected={asset === 'All'}
              sx={menuItemStyle}
            >
              <Typography>All</Typography>
            </MenuItem>
            {assets.map((a) => {
              const icon = paymentDataCtx.paymentData?.walletList.find(
                (asset) => asset.symbol === a.symbol
              )?.icon;
              return (
                <MenuItem
                  key={a.symbol}
                  id={a.symbol}
                  selected={a.symbol === asset}
                  value={a.symbol}
                  sx={menuItemStyle}
                >
                  <Stack direction={'row'} alignItems={'center'}>
                    <img
                      style={{
                        width: 35,
                        height: 35
                      }}
                      src={icon ? icon : findImage(a.symbol)}
                      alt={a.name}
                    />
                    <Typography
                      component={'h5'}
                      sx={{ fontSize: '1.25rem', fontWeight: 500, pl: '7px' }}
                    >
                      {a.symbol}
                    </Typography>
                  </Stack>
                  {a.type_currency === 'CONTRACT' && (
                    <Typography
                      component={'h5'}
                      sx={{ fontSize: '1rem', color: cryptoGrey[50] }}
                    >
                      {a.blockchain}
                    </Typography>
                  )}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
        <FormControl>
          <RadioGroup
            row
            aria-label="history-type-radio-buttons-group"
            aria-labelledby="history-type-radio-buttons-group"
            name="history-type-radio-buttons-group"
            value={radioValue}
            onChange={handleRadioChange}
            sx={{ justifyContent: 'space-between', py: '15px' }}
          >
            <FormControlLabel
              value={HistoryType.TRANSACTION}
              labelPlacement="start"
              control={
                <Radio
                  disableRipple
                  icon={<RadioUnchecked sx={radioIconStyle} />}
                  checkedIcon={<RadioChecked sx={radioIconStyle} />}
                  sx={{ p: 0 }}
                />
              }
              label={language.translation.selectParameterWindow.transaction}
              sx={radioLabelStyle}
            />
            <FormControlLabel
              value={HistoryType.INVOICES}
              labelPlacement="start"
              control={
                <Radio
                  disableRipple
                  icon={<RadioUnchecked sx={radioIconStyle} />}
                  checkedIcon={<RadioChecked sx={radioIconStyle} />}
                  sx={{ p: 0 }}
                />
              }
              label={language.translation.selectParameterWindow.invoices}
              sx={radioLabelStyle}
            />
          </RadioGroup>
        </FormControl>
        <Divider sx={{ borderColor: cryptoPrimary[900] }} />
        <Stack
          direction={'row'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          sx={{ mt: '20px' }}
        >
          <FormGroup sx={{ gap: '20px' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={transactionDirection === TransactionDirection.IN}
                  onChange={handleTransactionDirectionChange}
                  name={TransactionDirection.IN}
                  sx={switchInputStyle}
                  disabled={radioValue === HistoryType.INVOICES}
                />
              }
              label={language.translation.selectParameterWindow.incoming}
              labelPlacement="start"
              sx={{ m: 0, justifyContent: 'space-between', width: '133px' }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={transactionDirection === TransactionDirection.OUT}
                  onChange={handleTransactionDirectionChange}
                  name={TransactionDirection.OUT}
                  sx={switchInputStyle}
                  disabled={radioValue === HistoryType.INVOICES}
                />
              }
              label={language.translation.selectParameterWindow.outgoing}
              labelPlacement="start"
              sx={{ m: 0, justifyContent: 'space-between', width: '133px' }}
            />
          </FormGroup>
          <FormGroup sx={{ gap: '20px' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={invoiceActive}
                  onChange={handleInvoiceActiveChange}
                  name="invoiceActive"
                  sx={switchInputStyle}
                  disabled={radioValue === HistoryType.TRANSACTION}
                />
              }
              label={language.translation.historyWindow.active}
              labelPlacement="start"
              sx={invoiceSwitchLabelStyle}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={invoiceStatus === InvoiceStatus.PAID}
                  onChange={handleInvoiceStatusChange}
                  name={InvoiceStatus.PAID}
                  sx={switchInputStyle}
                  disabled={radioValue === HistoryType.TRANSACTION}
                />
              }
              label={language.translation.selectParameterWindow.completed}
              labelPlacement="start"
              sx={invoiceSwitchLabelStyle}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={invoiceStatus === InvoiceStatus.PARTIALLY_PAID}
                  onChange={handleInvoiceStatusChange}
                  name={InvoiceStatus.PARTIALLY_PAID}
                  sx={switchInputStyle}
                  disabled={radioValue === HistoryType.TRANSACTION}
                />
              }
              label={language.translation.selectParameterWindow.partiallyPaid}
              labelPlacement="start"
              sx={invoiceSwitchLabelStyle}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={invoiceStatus === InvoiceStatus.IN_MEMPOOL}
                  onChange={handleInvoiceStatusChange}
                  name={InvoiceStatus.IN_MEMPOOL}
                  sx={switchInputStyle}
                  disabled={radioValue === HistoryType.TRANSACTION}
                />
              }
              label={language.translation.selectParameterWindow.inMempool}
              labelPlacement="start"
              sx={invoiceSwitchLabelStyle}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={invoiceStatus === InvoiceStatus.IN_BLOCK}
                  onChange={handleInvoiceStatusChange}
                  name={InvoiceStatus.IN_BLOCK}
                  sx={switchInputStyle}
                  disabled={radioValue === HistoryType.TRANSACTION}
                />
              }
              label={language.translation.selectParameterWindow.hasBlock}
              labelPlacement="start"
              sx={invoiceSwitchLabelStyle}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={invoiceStatus === InvoiceStatus.NOT_PAID}
                  onChange={handleInvoiceStatusChange}
                  name={InvoiceStatus.NOT_PAID}
                  sx={switchInputStyle}
                  disabled={radioValue === HistoryType.TRANSACTION}
                />
              }
              label={language.translation.selectParameterWindow.unpaid}
              labelPlacement="start"
              sx={invoiceSwitchLabelStyle}
            />
            {/* <FormControlLabel
              control={
                <Switch
                  checked={invoiceStatus === 'Cancelled'}
                  onChange={handleInvoiceStatusChange}
                  name={'Cancelled'}
                  sx={switchInputStyle}
                  disabled={radioValue === HistoryType.TRANSACTION}
                />
              }
              label={language.translation.selectParameterWindow.cancelled}
              labelPlacement="start"
              sx={invoiceSwitchLabelStyle}
            /> */}
          </FormGroup>
        </Stack>
        <Button
          variant="contained"
          disableRipple
          sx={[positiveButtonStyle, { width: '100%', my: '30px' }]}
          size="large"
          onClick={
            radioValue === HistoryType.INVOICES
              ? openInvoices
              : openTransactions
          }
        >
          {language.translation.mainButton.history}
        </Button>
      </Box>
    </Paper>
  );
}
