import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import Logo from '../components/Logo';
import {
  cryptoPositive,
  cryptoPrimary,
  cryptoSecondary
} from '../theme/colors';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import '@fontsource/roboto-condensed';
import Language from '../components/Language';
import {
  containerStyle,
  errorStyle,
  pageStyle,
  positiveButtonStyle,
  secondaryButtonStyle,
  welcomeBackgroundStyle
} from '../theme/styles';
import EventDialog from '../components/EventDialog';
import api from '../api/api';
import { UserContext } from '../context/User';
import { EventMessage } from '../models/eventMessage';
import { LoginResponse } from '../models/user';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';

enum Mode {
  AUTHORIZATION,
  REGISTRATION,
  FORGOT
}

const titleStyle = {
  fontSize: '1.5rem',
  fontWeight: 700,
  color: cryptoSecondary[600],
  my: '3rem',
  textTransform: 'uppercase'
};

const formStyle = {
  maxWidth: 'sm',
  margin: '0 auto',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem'
} as const;

export default function Login() {
  const userCtx = useContext(UserContext);
  const user = userCtx.user;
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string[]>([]);
  const [mode, setMode] = useState<Mode>(Mode.AUTHORIZATION);
  const [eventMessage, setEventMessage] = useState<EventMessage | null>(null);
  const [registered, setRegistered] = useState(false);
  const [validationSchema, setValidationSchema] = useState<any>(null);
  let navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    }; // cleanup toggles value, if unmounted
  }, []);

  useEffect(() => {
    const newValidationSchema = yup.object({
      email: yup
        .string()
        .email(language.translation.enterWindow.validEmail)
        .required(language.translation.enterWindow.emailRequired),
      password: yup
        .string()
        .min(6, language.translation.enterWindow.minimumChar)
        .required(language.translation.enterWindow.passwordRequired)
    });
    setValidationSchema(newValidationSchema);
  }, [language]);

  useEffect(() => {
    if (isMounted && user && !eventMessage) {
      if (user.defaultWalletId) {
        navigate(`../wallet/${user.defaultWalletId}`);
      } else {
        navigate('../walletlist');
      }
    }
  }, [isMounted, user, eventMessage]);

  useEffect(() => {
    if (registered && !eventMessage) {
      navigate('../activation');
    }
  }, [registered, eventMessage, navigate]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { email, password } = values;
      switch (mode) {
        case Mode.AUTHORIZATION:
          api.account
            .login({ username: email, password })
            .then((result) => {
              if (result.data) {
                const loginResponse = result.data as LoginResponse;
                // setEventMessage({
                //   status: result.status,
                //   message: language.translation.windowText.logined,
                //   positive: true
                // });
                userCtx.setToken(loginResponse.token);
              }
            })
            .catch((er) => {
              if (er.response.status === 404) {
                setEventMessage({
                  status: er.response.status,
                  message: language.translation.windowText.userNotFound,
                  positive: false
                });
              } else if (er.response.data && er.response.data) {
                setEventMessage({
                  status: er.response.status,
                  message: er.response.data,
                  positive: false
                });
              } else {
                setEventMessage({
                  status: er.response.status ? er.response.status : 0,
                  message: er.message,
                  positive: false
                });
              }
            });
          break;
        case Mode.REGISTRATION:
        case Mode.FORGOT:
          {
            const registrationData = {
              email,
              password,
              language: language.id.toUpperCase()
            };
            api.account
              .register(registrationData)
              .then((result) => {
                if (result) {
                  setEventMessage({
                    status: result.status,
                    message: language.translation.windowText.registered,
                    positive: true
                  });
                  setRegistered(true);
                  userCtx.setRegistrationRequest(registrationData);
                }
              })
              .catch((er) => {
                if (er.response.status === 429) {
                  setEventMessage({
                    status: er.response.status,
                    message: language.translation.windowText.tooManyRequests,
                    positive: false
                  });
                } else if (er.response.data && er.response.data.message) {
                  setEventMessage({
                    status: er.response.status,
                    message: er.response.data.message,
                    positive: false
                  });
                } else {
                  setEventMessage({
                    status: er.response.status ? er.response.status : 0,
                    message: er.message,
                    positive: false
                  });
                }
              });
          }
          break;
      }
    }
  });

  useEffect(() => {
    if (Boolean(formik.errors.email) || Boolean(formik.errors.password)) {
      const errors = [];
      if (formik.errors.email) {
        errors.push(formik.errors.email);
      }
      if (formik.errors.password) {
        errors.push(formik.errors.password);
      }
      setError(errors);
    } else {
      setError([]);
    }
  }, [formik.errors]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeMode = () => {
    mode === Mode.AUTHORIZATION
      ? setMode(Mode.REGISTRATION)
      : setMode(Mode.AUTHORIZATION);
  };

  const handleClickForgot = () => {
    if (mode !== Mode.FORGOT) {
      setMode(Mode.FORGOT);
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Box component="div" sx={welcomeBackgroundStyle}>
      <Box
        component="div"
        sx={[pageStyle, { height: '100%', minHeight: '100dvh' }]}
      >
        <Language />
        <Box component="div" sx={containerStyle}>
          <Logo size={120} cursorStyle="auto" />
          <Typography sx={titleStyle}>
            {mode === Mode.AUTHORIZATION
              ? language.translation.enterWindow.authorization
              : mode === Mode.REGISTRATION
              ? language.translation.enterWindow.registration
              : language.translation.enterWindow.forgot}
          </Typography>
          <Box
            maxWidth="sm"
            component="form"
            sx={formStyle}
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <TextField
              autoFocus
              fullWidth
              InputProps={{ disableUnderline: true }}
              variant="filled"
              id="email"
              name="email"
              placeholder={language.translation.enterWindow.email}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            <TextField
              fullWidth
              variant="filled"
              id="password"
              name="password"
              placeholder={language.translation.enterWindow.password}
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {mode !== Mode.FORGOT && (
                      <Typography
                        variant="h6"
                        onClick={handleClickForgot}
                        sx={{
                          fontFamily: 'Roboto Condensed',
                          fontWeight: 400,
                          cursor: 'pointer',
                          color: cryptoPrimary[600],
                          p: '8px'
                        }}
                      >
                        {language.translation.enterWindow.forgot}
                      </Typography>
                    )}
                    <IconButton
                      disableRipple
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Box component={'div'} sx={errorStyle}>
              {(formik.touched.email && Boolean(formik.errors.email)) ||
              (formik.touched.password && Boolean(formik.errors.password))
                ? error.map((e) => (
                    <p key={e} style={{ margin: 0 }}>
                      {e}
                    </p>
                  ))
                : ' '}
            </Box>
            <Button
              variant="contained"
              disableRipple
              sx={positiveButtonStyle}
              size="large"
              type="submit"
            >
              {mode === Mode.AUTHORIZATION
                ? language.translation.mainButton.signIn
                : mode === Mode.REGISTRATION
                ? language.translation.mainButton.registration
                : language.translation.mainButton.change}
            </Button>
            <Button
              variant="contained"
              disableRipple
              sx={[secondaryButtonStyle, { mt: '1.5rem' }]}
              size="large"
              type="button"
              onClick={handleChangeMode}
            >
              {mode === Mode.AUTHORIZATION
                ? language.translation.mainButton.registration
                : language.translation.mainButton.signIn}
            </Button>
          </Box>
        </Box>
        <Typography
          component={Link}
          to={'/'}
          sx={{ color: cryptoPrimary[200], mb: '20px' }}
        >
          apiforcrypto.com
        </Typography>
      </Box>
      {eventMessage && (
        <EventDialog
          isOpen={Boolean(eventMessage)}
          setOpen={setEventMessage}
          positive={eventMessage.positive}
          message={eventMessage.message}
        />
      )}
    </Box>
  );
}
