import './App.css';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import Wellcome from './pages/Wellcome';
import theme from './theme/normal';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import UserProvider from './context/User';
import Activation from './pages/Activation';
import WalletList from './pages/WalletList';
import LanguageProvider from './context/Language';
import Wallet from './pages/Wallet';
import Invoices from './pages/Invoices';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Settings from './pages/Settings';
import PaymentDataProvider from './context/PaymentData';
import CurrentWalletProvider from './context/Wallet';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserProvider>
        <LanguageProvider>
          <PaymentDataProvider>
            <CurrentWalletProvider>
              <BrowserRouter>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Routes>
                    <Route path="/" element={<Wellcome />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/activation" element={<Activation />} />
                    <Route path="/walletlist" element={<WalletList />} />
                    <Route path="/wallet/:id" element={<Wallet />} />
                    <Route path="/invoices/:id" element={<Invoices />} />
                    <Route path="/settings" element={<Settings />} />
                  </Routes>
                </LocalizationProvider>
              </BrowserRouter>
            </CurrentWalletProvider>
          </PaymentDataProvider>
        </LanguageProvider>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
