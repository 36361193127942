interface Color {
  900: string;
  800: string;
  700: string;
  600: string;
  500: string;
  400: string;
  300: string;
  200: string;
  100: string;
  50: string;
}

export const cryptoPrimary: Color = {
  900: '#14386E',
  800: '#114179',
  700: '#1E5BA4',
  600: '#297BC7',
  500: '#377CE6',
  400: '#967C59',
  300: '#329CFD',
  200: '#329DFF',
  100: '#32C5FF',
  50: '#D4E1E7'
};

export const cryptoSecondary: Color = {
  900: '#F6943A',
  800: '#F7953A',
  700: '#FFDB5B',
  600: '#FFEDAD',
  500: '#B6B9B6',
  400: '#C9C9C9',
  300: '#D9D9D9',
  200: '#DADADA',
  100: '#FFBF1C',
  50: '#C6D6DC'
};

export const cryptoPositive: Color = {
  900: '#3BD138',
  800: '#3CD239',
  700: '#6DFF73',
  600: '#AEFFB1',
  500: '#FFD900',
  400: '#66FF66',
  300: '',
  200: '',
  100: '',
  50: '#FF3333'
};

export const cryptoGrey: Color = {
  900: '#355572',
  800: '#14386E',
  700: '#464B4D',
  600: '#1B2B39',
  500: '#737373',
  400: '',
  300: '',
  200: '#B0BCC1',
  100: '#C6D6DC',
  50: '#949DA2'
};

export const cryptoAlpha: Color = {
  900: '#329DFF40',
  800: '#41414133',
  700: 'rgba(255,51,51,0.2)',
  600: 'rgba(0,0,0,0.5)',
  500: '',
  400: '',
  300: '',
  200: '',
  100: '',
  50: ''
};
