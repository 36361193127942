import { Box, Typography } from '@mui/material';
import { cryptoPrimary } from '../theme/colors';
import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  address: string;
  fontHeight: string;
  lineHeight: string;
  stringLen: number;
  color: string;
}

export default function AddressText({
  address,
  fontHeight,
  lineHeight,
  stringLen,
  color
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const componentRef = useRef<HTMLSpanElement | null>(null);
  const [startWidth, setStartWidth] = useState<number>(stringLen);
  const [wraped, setWraped] = useState(false);

  const handleResize = useCallback(() => {
    if (containerRef?.current && componentRef?.current) {
      if (containerRef.current.offsetWidth > componentRef.current.offsetWidth) {
        setWraped(false);
      } else if (componentRef.current.offsetWidth > startWidth) {
        //console.log(`set width: ${componentRef.current.offsetWidth}`);
        setStartWidth(Math.floor(componentRef.current.offsetWidth));
        setWraped(false);
      } else if (Math.floor(componentRef.current.offsetWidth) < startWidth) {
        //console.log(`current width: ${componentRef.current.offsetWidth}`);
        setWraped(true);
      } else {
        setWraped(false);
      }
    } else {
      //console.log(`set width: ${0}`);
      setStartWidth(0);
      setWraped(false);
    }
  }, [containerRef, componentRef, startWidth]);

  useEffect(() => {
    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('load', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, [componentRef, handleResize]);

  return (
    <Box
      component="div"
      ref={containerRef}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}
    >
      <Typography
        ref={componentRef}
        noWrap
        sx={{
          color: color,
          fontSize: fontHeight,
          lineHeight: lineHeight,
          position: 'relative',
          pr: wraped ? `calc(2.15*${fontHeight})` : '0.2rem',
          '&:after': wraped ? {
            content: `"${address.slice(-4)}"`,
            position: 'absolute',
            right: 0,
            bottom: 0
          } : {}
        }}
      >
        {wraped ? address.slice(0, -4): address}
      </Typography>
      {/* {wraped && (
        <Typography
          sx={{
            color: color,
            ml: '-0.25rem',
            fontSize: fontHeight,
            lineHeight: lineHeight
          }}
        >
          {address.slice(-4)}
        </Typography>
      )} */}
    </Box>
  );
}
