import { Box, Link, Stack, Typography } from '@mui/material';
import { cryptoGrey, cryptoPositive, cryptoPrimary } from '../theme/colors';
import { Transfer } from '../models/transfer';
import { addMinutes, format, parseISO } from 'date-fns';
import { useContext } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import outIcon from '../assets/icon_sent.svg';

interface Props {
  transfer: Transfer;
  symbol: string;
}

const transferTextStyle = {
  fontSize: '0.875rem',
  lineHeight: '1.25rem'
};

export default function TransferItem({ transfer, symbol }: Props) {
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const dateString = format(
    addMinutes(parseISO(transfer.date), -new Date().getTimezoneOffset()),
    'MMM dd yyyy HH:mm'
  );
  let numberFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 20
  });
  const amoundString = `${
    transfer.type === 'out' ? '-' : ''
  }${numberFormat.format(transfer.decimal_value)} ${transfer.symbol}`;
  const feeString = `${numberFormat.format(transfer.decimal_fees)} ${
    transfer.symbol
  }`;

  return (
    <Box
      component={'div'}
      sx={{
        height: '165px',
        padding: '1px',
        paddingTop: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.05)'
      }}
    >
      <Stack
        sx={{
          height: '100%',
          py: '10px',
          px: '15px',
          backgroundColor: cryptoGrey[900],
          color: 'white',
          gap: '5px'
        }}
      >
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
          <Typography sx={transferTextStyle}>{dateString}</Typography>
          <Typography
            sx={[
              transferTextStyle,
              {
                color:
                  transfer.confirmations > 0 ? 'white' : cryptoPositive[500]
              }
            ]}
          >
            {transfer.confirmations > 0
              ? language.translation.historyWindow.completed
              : language.translation.historyWindow.inProcess}
          </Typography>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
          <img
            src={outIcon}
            alt={transfer.type}
            style={{
              width: '20px',
              transform: transfer.type === 'out' ? 'rotate(180deg)' : 'none'
            }}
          />
          <Typography sx={[transferTextStyle, { px: '5px' }]}>
            {transfer.operation_type}
          </Typography>
          <Typography
            sx={[
              transferTextStyle,
              {
                px: '5px',
                color: transfer.type === 'out' ? 'white' : cryptoPositive[400]
              }
            ]}
          >
            {amoundString}
          </Typography>
        </Stack>
        {transfer.fees > 0 && (
          <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
            <Typography sx={transferTextStyle}>
              {language.translation.historyWindow.networkFee}
            </Typography>
            <Typography sx={[transferTextStyle, { px: '5px' }]}>
              {feeString}
            </Typography>
          </Stack>
        )}
        <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
          <Typography sx={transferTextStyle}>
            {language.translation.historyWindow.from}
          </Typography>
          <Typography sx={[transferTextStyle, { px: '5px' }]}>
            {transfer.inputs[0]}
          </Typography>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
          <Typography sx={transferTextStyle}>
            {language.translation.historyWindow.to}
          </Typography>
          <Typography sx={[transferTextStyle, { px: '5px' }]}>
            {transfer.outputs[0]}
          </Typography>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'flex-start' }}>
          <Typography sx={transferTextStyle}>Hash:</Typography>
          <Link
            noWrap
            href={`https://blockchair.com/search?q=${transfer.hash}`}
            target="_blank"
            rel="noreferrer"
            sx={[transferTextStyle, { px: '5px', color: cryptoPrimary[200] }]}
          >
            {transfer.hash}
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
}
