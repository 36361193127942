import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  SvgIcon,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { listStyle, paperStyle, positiveButtonStyle } from '../theme/styles';
import HeaderRight from '../components/HeaderRight';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { RadioChecked } from '../components/RadioChecked';
import { RadioUnchecked } from '../components/RadioUnchecked';
import { cryptoSecondary } from '../theme/colors';
import api from '../api/api';
import { EventMessage } from '../models/eventMessage';
import EventDialog from '../components/EventDialog';
import { CreateWalletResponse } from '../models/wallet';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/User';

interface Props {
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  importWallet: boolean;
}

enum Type {
  PRIVATE = 'PRIVATE',
  INVOICE = 'INVOICE'
}

const validationSchema = yup.object({
  name: yup.string(),
  seed: yup.string()
});

const radioIconStyle = {
  height: '30px',
  width: '30px'
} as const;

const formStyle = {
  padding: '8px 30px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '7px'
} as const;

const WalletInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  backgroundColor: cryptoSecondary[50],
  boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.25)',
  border: 'none',
  height: '45px',
  '& .MuiFilledInput-input': {
    color: 'black'
  }
}));

const SeedInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  backgroundColor: cryptoSecondary[50],
  border: 'none',
  height: '185px',
  fontSize: '1rem',
  alignItems: 'flex-start',
  marginBottom: '1.5rem',
  '& .MuiFilledInput-input': {
    color: 'black',
    lineHeight: '28px',
    padding: 0,
    width: '100%'
  }
}));

export default function CreateWallet({ setActive, importWallet }: Props) {
  const userCtx = useContext(UserContext);
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [value, setValue] = useState<Type | null>(null);
  const [description, setDescription] = useState<string[]>(
    language.translation.createWalletWindow.privateWalletDescription
  );
  const [eventMessage, setEventMessage] = useState<EventMessage | null>(null);
  const [mnemonic, setMnemonic] = useState<string | undefined>(undefined);
  const [id, setId] = useState<string>('');
  const [loggedOut, setLoggedOut] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (value === Type.PRIVATE) {
      setDescription(
        language.translation.createWalletWindow.privateWalletDescription
      );
    } else if (value === Type.INVOICE) {
      setDescription(
        language.translation.createWalletWindow.forInvoicesDescription
      );
    } else {
      setDescription([]);
    }
  }, [value]);

  useEffect(() => {
    if (id !== '' && !eventMessage) {
      navigate(`../wallet/${id}`);
    }
  }, [id, eventMessage, navigate]);

  useEffect(() => {
    if (loggedOut && !eventMessage) {
      navigate('../login');
    }
  }, [loggedOut, eventMessage, navigate]);

  const formik = useFormik({
    initialValues: {
      name: '',
      seed: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { name, seed } = values;
      if (value) {
        api.wallet
          .create({ name, type: value, mnemonic: seed })
          .then((result) => {
            if (result.data) {
              const walletResponse = result.data as CreateWalletResponse;
              if (!importWallet) {
                setMnemonic(walletResponse.mnemonic);
              } else {
                setMnemonic(undefined);
              }
              setId(walletResponse.id);
              userCtx.setUpdated(false);
              setEventMessage({
                status: result.status,
                message: importWallet
                  ? language.translation.windowText.walletImported
                  : language.translation.windowText.walletCreated,
                positive: true
              });
            }
          })
          .catch((er) => {
            if (er.response.status === 401) {
              setLoggedOut(true);
              userCtx.setUser(null);
            } else if (er.response.data) {
              setEventMessage({
                status: er.response.status,
                message: er.response.data.message
                  ? er.response.data.message
                  : er.message,
                positive: false
              });
            } else {
              setEventMessage({
                status: er.response.status ? er.response.status : 0,
                message: er.message,
                positive: false
              });
            }
          });
      }
    }
  });

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    setValue(val === Type.PRIVATE ? Type.PRIVATE : Type.INVOICE);
  };

  return (
    <Paper
      elevation={0}
      square
      sx={[paperStyle, { marginLeft: { xs: 'auto', sm: 0 } }]}
    >
      <HeaderRight
        title={language.translation.topWindow.createWallet}
        setActive={setActive}
      />
      <Box component="div" sx={listStyle}>
        <FormControl>
          <RadioGroup
            row
            aria-label="wallet-type-radio-buttons-group"
            aria-labelledby="wallet-type-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleRadioChange}
            sx={{ justifyContent: 'space-around', padding: '11px' }}
          >
            <FormControlLabel
              value={Type.PRIVATE}
              labelPlacement="top"
              control={
                <Radio
                  disableRipple
                  icon={<RadioUnchecked sx={radioIconStyle} />}
                  checkedIcon={<RadioChecked sx={radioIconStyle} />}
                  sx={{ padding: '12px' }}
                />
              }
              label={language.translation.createWalletWindow.privateWallet}
            />
            <FormControlLabel
              value={Type.INVOICE}
              labelPlacement="top"
              control={
                <Radio
                  disableRipple
                  icon={<RadioUnchecked sx={radioIconStyle} />}
                  checkedIcon={<RadioChecked sx={radioIconStyle} />}
                  sx={{ padding: '12px' }}
                />
              }
              label={language.translation.createWalletWindow.forInvoices}
            />
          </RadioGroup>
        </FormControl>
        <Box
          maxWidth="sm"
          component="form"
          sx={formStyle}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <WalletInput
            autoFocus
            fullWidth
            InputProps={{ disableUnderline: true }}
            variant="filled"
            id="name"
            name="name"
            placeholder={language.translation.settingsWindow.walletName}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
          />
          {importWallet ? (
            <>
              <InputLabel
                htmlFor="seed"
                sx={{ alignSelf: 'flex-start', color: 'black' }}
              >
                {language.translation.settingsWindow.seedPhrase}
              </InputLabel>
              <SeedInput
                fullWidth
                InputProps={{ disableUnderline: true }}
                variant="filled"
                multiline
                rows={6}
                id="seed"
                name="seed"
                placeholder={language.translation.settingsWindow.seedPhrase}
                value={formik.values.seed}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.seed && Boolean(formik.errors.seed)}
              />
            </>
          ) : (
            <Box component={'div'} sx={{ height: '40px' }}></Box>
          )}
          <Button
            variant="contained"
            disableRipple
            disabled={!value}
            sx={[positiveButtonStyle, { width: '100%' }]}
            size="large"
            type="submit"
          >
            {language.translation.mainButton.confirm}
          </Button>
          {description.map((d) => (
            <Typography
              key={d}
              sx={{
                color: 'black',
                lineHeight: '28px',
                marginTop: '1.5rem',
                alignSelf: 'flex-start'
              }}
            >
              {d}
            </Typography>
          ))}
        </Box>
      </Box>
      {eventMessage && (
        <EventDialog
          isOpen={Boolean(eventMessage)}
          setOpen={setEventMessage}
          positive={eventMessage.positive}
          message={eventMessage.message}
          mnemonic={mnemonic}
        />
      )}
    </Paper>
  );
}
