import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { appBackgroundStyle, listStyle, paperStyle } from '../theme/styles';
import { cryptoAlpha, cryptoPrimary, cryptoSecondary } from '../theme/colors';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WalletItem from '../components/WalletItem';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/User';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import CreateWallet from './CreateWallet';
import bluePlusIcon from '../assets/blueplus.svg';
import blueLoadIcon from '../assets/blueload.svg';
import { BluePlus } from '../components/icons/blueplus';
import { BlueLoad } from '../components/icons/blueload';
import { useNavigate } from 'react-router-dom';

const itemStyle = {
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  pl: '15px',
  backgroundColor: cryptoPrimary[50],
  color: 'black'
} as const;

export default function WalletList() {
  const userCtx = useContext(UserContext);
  const user = userCtx.user;
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const [createWallet, setCreateWallet] = useState(false);
  const [displayFirst, setDisplayFirst] = useState(false);
  const [importWallet, setImportWallet] = useState(false);
  let navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleCreateWalletClick = () => {
    setImportWallet(false);
    setCreateWallet(true);
  };

  const handleImportWalletClick = () => {
    setImportWallet(true);
    setCreateWallet(true);
  };

  const handleClickLogo = () => {
    userCtx.setUpdated(false);
  };

  useEffect(() => {
    if (!user) {
      navigate('../login');
    }
  });

  useEffect(() => {
    if (!createWallet || (createWallet && matches)) {
      setDisplayFirst(true);
    } else {
      setDisplayFirst(false);
    }
  }, [createWallet, matches]);

  return (
    <Box component="div" sx={[appBackgroundStyle, { maxHeight: '100dvh' }]}>
      <Grid
        container
        columnSpacing={'4px'}
        sx={{ justifyContent: 'center', maxHeight: '100dvh' }}
      >
        {displayFirst && (
          <Grid item xs={12} sm={6} sx={{ maxHeight: '100dvh' }}>
            <Paper
              elevation={0}
              square
              sx={[paperStyle, { marginRight: { xs: 'auto', sm: 0 } }]}
            >
              <Header handleClickLogo={handleClickLogo} />
              <Box component="div" sx={listStyle}>
                {user &&
                  user.wallets.map((w) => (
                    <WalletItem
                      key={w.id}
                      id={w.id}
                      name={w.name}
                      balance={w.balance}
                      fiat_currency={w.fiat_currency}
                      selected={w.id === user.defaultWalletId}
                    />
                  ))}
                <Box
                  component={'div'}
                  sx={{
                    height: '60px',
                    minHeight: '60px',
                    padding: '1px',
                    paddingTop: 0,
                    backgroundColor: cryptoSecondary[50]
                  }}
                >
                  <Stack direction={'row'} sx={itemStyle}>
                    <Typography sx={{ minWidth: '100px' }}>
                      {language.translation.buttons.createWallet}
                    </Typography>
                    <Tooltip title={language.translation.buttons.createWallet}>
                      <IconButton
                        disableRipple
                        onClick={handleCreateWalletClick}
                        sx={{
                          height: '100%',
                          width: '54px',
                          pr: '19px',
                          borderRadius: 0,
                          justifyContent: 'flex-end'
                        }}
                      >
                        <img
                          src={bluePlusIcon}
                          alt={language.translation.buttons.createWallet}
                          style={{ width: '16px' }}
                        />
                        {/* <BluePlus sx={{ width: '18px', height: '18px' }} /> */}
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>
                <Box
                  component={'div'}
                  sx={{
                    height: '60px',
                    minHeight: '60px',
                    padding: '1px',
                    paddingTop: 0,
                    backgroundColor: cryptoSecondary[50]
                  }}
                >
                  <Stack direction={'row'} sx={itemStyle}>
                    <Typography sx={{ minWidth: '100px' }}>
                      {language.translation.buttons.importWallet}
                    </Typography>
                    <Tooltip title={language.translation.buttons.importWallet}>
                      <IconButton
                        disableRipple
                        onClick={handleImportWalletClick}
                        sx={{
                          height: '100%',
                          width: '50px',
                          pr: '18px',
                          borderRadius: 0,
                          justifyContent: 'flex-end'
                        }}
                      >
                        <img
                          src={blueLoadIcon}
                          alt={language.translation.buttons.importWallet}
                          style={{ width: '18px' }}
                        />
                        {/* <BlueLoad sx={{ width: '20px' }} /> */}
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>
              </Box>
              <Footer wallet />
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} sm={6} sx={{ height: '100dvh' }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              maxWidth: '704px',
              backgroundColor: cryptoAlpha[900]
            }}
          >
            {createWallet && (
              <CreateWallet
                setActive={setCreateWallet}
                importWallet={importWallet}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
