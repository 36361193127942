import { createTheme } from '@mui/material/styles';
import { cryptoGrey, cryptoPrimary, cryptoSecondary } from './colors';
import '@fontsource/roboto';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 754,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: cryptoPrimary[600]
    },
    secondary: {
      main: cryptoSecondary[800]
    },
    background: {
      default: cryptoPrimary[50]
    }
  },
  typography: {
    fontFamily: 'Roboto'
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {

        },
        input: {
          width: '335px',
          paddingTop: '8px'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          height: '100%',
          color: '#FFF',
          backgroundColor: 'transparent',
          '&:after, &:before': {
            borderBottom: 'none',
            backgroundColor: cryptoPrimary[800]
          }
        },
        input: {
          color: '#FFF',
          fontSize: '1.25rem',
          paddingTop: '12px',
          paddingBottom: '12px',
          '& .Mui-disabled': {
            WebkitTextFillColor: `${cryptoGrey[800]} !important`
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          autoComplete: 'off',
          width: '335px',
          height: '64px',
          borderRadius: '10px',
          backgroundColor: cryptoPrimary[800],
          border: `2px solid ${cryptoPrimary[600]}`
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#FFF'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: cryptoPrimary[200]
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1.25rem',
          fontWeight: 700,
          height: '50px',
          width: '200px',
          borderRadius: '13px',
          padding: '8px'
        },
        startIcon: {
          margin: 0
        }
      }
    }
  }
});

export default theme;
