import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography
} from '@mui/material';
import { cryptoPrimary } from '../theme/colors';
import { useContext } from 'react';
import { LanguageContext } from '../context/Language';
import { languages } from '../content/languages';
import { DeleteMessage } from '../models/deleteMessage';
import { positiveButtonStyle, secondaryButtonStyle } from '../theme/styles';
import deleteIcon from '../assets/delete.svg';

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<DeleteMessage | null>>;
  deleteMessage: DeleteMessage;
  handleDelete: () => void;
}

export default function DeleteDialog({
  isOpen,
  setOpen,
  deleteMessage,
  handleDelete
}: Props) {
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];
  const handleBack = () => {
    setOpen(null);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleBack}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      PaperProps={{
        style: {
          alignItems: 'center',
          backgroundColor: cryptoPrimary[50],
          borderRadius: '10px',
          minHeight: '230px',
          width: '320px'
        }
      }}
    >
      <DialogContent
        sx={{
          textAlign: 'center',
          p: '30px',
          paddingBottom: '6px',
          color: 'black'
        }}
      >
        <DialogContentText
          id="delete-dialog-description"
          sx={{ color: 'black', fontWeight: 500 }}
        >
          {deleteMessage.title}
        </DialogContentText>
        <IconButton disableRipple sx={{ p: '10px', borderRadius: 0 }}>
          <img
            style={{
              width: 51,
              height: 51
            }}
            src={deleteIcon}
            alt={'Delete'}
          />
        </IconButton>
        <Typography sx={{ fontWeight: 500 }}>{deleteMessage.text}</Typography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          p: '1.5rem',
          flexDirection: 'column',
          gap: '20px'
        }}
      >
        <Button
          sx={[secondaryButtonStyle, { color: 'white' }]}
          onClick={handleBack}
          autoFocus
        >
          {language.translation.mainButton.back}
        </Button>
        <Button
          sx={[positiveButtonStyle, { color: 'white' }]}
          onClick={handleDelete}
          autoFocus
        >
          {deleteMessage.button}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
