import { Box, Button, Paper, TextField, Typography, styled } from "@mui/material";
import { InvoiceFilterParams } from "../models/invoice";
import { cryptoSecondary } from "../theme/colors";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { errorStyle, listStyle, paperStyle, positiveButtonStyle } from "../theme/styles";
import HeaderRight from "../components/HeaderRight";
import { useContext } from "react";
import { LanguageContext } from "../context/Language";
import { languages } from "../content/languages";

interface Props {
    setActive: () => void;
    openInvoices: () => void;
    invoiceFilter: InvoiceFilterParams;
    setInvoiceFilter: React.Dispatch<React.SetStateAction<InvoiceFilterParams>>;
    setInvoiceId?: React.Dispatch<React.SetStateAction<string | null>>;
  }

  const validationSchema = yup.object({
    id_value: yup.string().required('Id is required')
  });

  const IDInput = styled(TextField)(({ theme }) => ({
    width: '100%',
    backgroundColor: cryptoSecondary[50],
    boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.25)',
    border: 'none',
    height: '40px',
    '& .MuiFilledInput-input': {
      color: 'black',
      fontSize: '1rem'
    }
  }));

  const formStyle = {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '15px'
  } as const;

export default function ChooseInvoiceID({setActive, openInvoices, invoiceFilter, setInvoiceFilter, setInvoiceId}:Props) {
  const languageCtx = useContext(LanguageContext);
  const language = languageCtx.language ? languageCtx.language : languages[0];

    const formik = useFormik({
        initialValues: {
            id_value: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          const { id_value } = values;
          if (id_value){
            if (setInvoiceId) {
                setInvoiceId(id_value);
                openInvoices();
            }else {
                setInvoiceFilter({
                    ...invoiceFilter,
                    order_id: id_value
                  });
                openInvoices();
            }
          }
        }
      });

    return(
        <Paper
      elevation={0}
      square
      sx={[paperStyle, { marginLeft: { xs: 'auto', sm: 0 } }]}
    >
      <HeaderRight
        title={setInvoiceId ? 
            language.translation.buttons.chooseUuid : 
            language.translation.buttons.chooseId
        }
        setActive={setActive}
      />
      <Box component="div" sx={listStyle}>
        <Box
          maxWidth="sm"
          component="form"
          sx={formStyle}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
            <Typography sx={{fontWeight: 500, alignSelf: 'flex-start'}}>
                {setInvoiceId ? language.translation.historyWindow.invoiceUUID : language.translation.historyWindow.invoiceId}
            </Typography>
          <IDInput
            autoFocus
            fullWidth
            InputProps={{ disableUnderline: true }}
            variant="filled"
            id="id_value"
            name="id_value"
            value={formik.values.id_value}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.id_value && Boolean(formik.errors.id_value)}
          />
          <Box component={'div'} sx={[errorStyle, {minHeight: '35px'}]}>
              {(formik.touched.id_value && Boolean(formik.errors.id_value)) 
                ? <p style={{ margin: 0 }}>
                      {formik.errors.id_value}
                    </p>
                : ' '}
            </Box>
          <Button
            variant="contained"
            disableRipple
            sx={[positiveButtonStyle, { width: '100%' }]}
            size="large"
            type="submit"
          >
            {language.translation.mainButton.history}
          </Button>
        </Box>
      </Box>
    </Paper>
    );
}